import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import me from './_generated/api/me';

export default (service: Servicelayer) => ({
  ...me(service),

  async getUserId(): Promise<number> {
    if (!Vue._.isSet(Vue.$auth.user?.id)) {
      await Vue.$auth.getUser();
    }
    return Vue.$auth.user!.id!;
  },

  getAgogisUserId(): number | undefined {
    const agogisUserIdClaim = Vue.$auth.getClaim('ag-AgogisUserId');
    if (agogisUserIdClaim) {
      return Number.parseInt(agogisUserIdClaim, 10);
    }
    return undefined;
  },

  getAmIPlanningAdmin(): boolean {
    return Vue.$auth.getClaim('ag-CockpitRole')?.toLowerCase()?.includes('einsatzplanung') ?? false;
  },

  getAmILeadLecturer(): boolean {
    return Vue.$auth.getClaim('ag-CockpitRole')?.toLowerCase()?.includes('klassenleitung') ?? false;
  },

  getAmIFachdozierender(): boolean {
    return Vue.$auth.getClaim('ag-CockpitRole')?.toLowerCase()?.includes('fachdozierende') ?? false;
  },
});
