// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LecturerWorkloadSummaryModel from '../../model/lecturer-workload-summary-model';
import LecturerWorkloadSummaryDTO from '../dto/lecturer-workload-summary-dto';
import dtoSchema from '../schemas/lecturer-workload-summary-schema';
import AgogisUserModel from '../../model/agogis-user-model';
import LectureWorkloadSummaryStatusItemModel from '../../model/lecture-workload-summary-status-item-model';

export default abstract class LecturerWorkloadSummaryModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LecturerWorkloadSummaryDTO>) {
    super();
    if (data) {
      this.classYear = data.classYear;
      this.totalDays = data.totalDays;
      this.totalDaysTT = data.totalDaysTT;
      this.lecturer = data.lecturer ? AgogisUserModel.toModel(data.lecturer) : undefined;
      this.summaryItemsByStatus = data.summaryItemsByStatus?.map((i) => LectureWorkloadSummaryStatusItemModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int32}
  */
  classYear?: number;
  /**
  * @type {double}
  */
  totalDays?: number;
  /**
  * @type {double}
  */
  totalDaysTT?: number;
  /**
  */
  lecturer?: AgogisUserModel;
  /**
  */
  summaryItemsByStatus?: LectureWorkloadSummaryStatusItemModel[];

  static toModel(dto: DeepPartial<LecturerWorkloadSummaryDTO>): LecturerWorkloadSummaryModel;
  static toModel(dto: DeepPartial<LecturerWorkloadSummaryDTO> | undefined | null): LecturerWorkloadSummaryModel | undefined;
  static toModel(dto: DeepPartial<LecturerWorkloadSummaryDTO> | undefined | null): LecturerWorkloadSummaryModel | undefined {
    return dto ? new LecturerWorkloadSummaryModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LecturerWorkloadSummaryModel> | LecturerWorkloadSummaryModel): LecturerWorkloadSummaryDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      classYear: unwrapped.classYear,
      totalDays: unwrapped.totalDays,
      totalDaysTT: unwrapped.totalDaysTT,
      lecturer: unwrapped.lecturer,
      summaryItemsByStatus: unwrapped.summaryItemsByStatus,
    } as LecturerWorkloadSummaryDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LecturerWorkloadSummaryModel, can not map to LecturerWorkloadSummaryDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
