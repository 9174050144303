import LectureLessonStatusResourceModel from '../../generated/types/model/resource/LectureLessonStatusResourceModel';

// eslint-disable-next-line no-shadow
export enum LectureLessonStatus {
  provisional = 1,
  confirmed = 2,
  declined = 3,
  open = 4,
}

export default class LectureLessonStatusResourceViewModel extends LectureLessonStatusResourceModel {
  get toccoId() {
    return this.dto.toccoId as LectureLessonStatus;
  }
}
