import _ from '@glittr/frontend-core/src/utils';
import { DayPilot } from 'daypilot-pro-vue';
import ISchedulerList from '../interfaces/ISchedulerList';
import ISchedulerListEntry from '../interfaces/ISchedulerListEntry';
import Int64LookupResourceViewModel from '../resource/Int64LookupResourceViewModel';
import SchedulerFilterViewModel from '../resource/SchedulerFilterViewModel';

type ConstructorType = abstract new (...args: any[]) => ISchedulerList;
type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};
export default function BaseSchedulerListMixin<TBase extends ConstructorType>(Base: TBase) {
  abstract class BaseSchedulerListClass extends Base {
    get allGroupsForSelect() {
      return this.allGroups.map((group) => ({
        caption: group?.caption,
        id: group?.id,
        details: group?.details,
      }));
    }

    getFilteredItems(filter?: SchedulerFilterViewModel) {
      const search = filter?.local?.search?.toString().toLowerCase();
      const groupId = filter?.local?.groupId;
      const eventsToFilterEmptyRows = filter?.events ?? [];
      const shouldFilterEmptyRows = filter?.local?.hideEmptyRows;
      const searchTokens = search?.split(/\s/g) ?? [];

      let filteredItems = _.deepClone(this.items);

      if (groupId) {
        filteredItems = filteredItems.filter((i) => i.group?.id === groupId);
      }

      if ((search?.length ?? 0) > 1) {
        filteredItems = filteredItems.filter((i) => {
          let hasMatch = false;
          searchTokens.forEach((searchToken: string) => {
            if ((searchToken?.length ?? 0) > 1) {
              const captionMatch = !!i.caption?.toLowerCase().includes(searchToken);
              const detailsMatch = !!(i.details as string[])?.join()?.toLowerCase().includes(searchToken);
              const idMatch = !!i.id?.toString().toLowerCase().includes(searchToken);
              hasMatch = hasMatch || captionMatch || detailsMatch || idMatch;
            }
          });
          return hasMatch;
        });
      }

      if (shouldFilterEmptyRows) {
        filteredItems = filteredItems.filter((item) => {
          let isInGroup = false;
          eventsToFilterEmptyRows.some((event) => {
            isInGroup = event.parentResourceId === item?.id;
            return isInGroup;
          });
          return isInGroup;
        });
      }

      filteredItems.forEach((i) => {
        i.details = (i.details as string[])?.join('\n');
      });

      return filteredItems;
    }

    getGroupsForDayPilot(filter?: SchedulerFilterViewModel) {
      const mappedGroups = [] as DayPilot.ResourceData[];
      // It should not be neccessary to filter twice like this
      // although it is convenient, maybe make it more functional?
      const filteredGroups = this.getFilteredGroups(filter);
      const filteredItems = this.getFilteredItems(filter);
      filteredGroups.forEach((group) => {
        const rowHeader = {} as DayPilot.ResourceData;
        let children = [] as DayPilot.ResourceData[];
        const itemsInGroup = filteredItems.filter((r) => r.group?.id === group?.id);
        children = children.concat(itemsInGroup as NoUndefinedField<ISchedulerListEntry>[]);
        rowHeader.children = children;

        rowHeader.id = group?.id!;
        rowHeader.caption = group?.caption!;
        rowHeader.expanded = true;
        rowHeader.cssClass = 'rowheader-group';
        rowHeader.height = '100px';
        mappedGroups.push(rowHeader);
      });
      return mappedGroups;
    }

    getFilteredGroups(filter?: SchedulerFilterViewModel) {
      const filteredItems = this.getFilteredItems(filter);

      const set = new Set();
      // Needs a copy of the array since we will be doing an in-place sort
      const filteredGroups = [...filteredItems]
        .sort((a, b) => a.sortOrder! - b.sortOrder!)
        .map((i) => i.group)
        .filter((i) => i !== null && i !== undefined) // remove undefineds/nulls
        .filter((i) => !set.has(i?.id) && set.add(i?.id)); // remove duplicates
      const hasUngrouped = filteredItems.some((i) => !(i.group?.id ?? false));
      if (hasUngrouped && !filter?.local?.groupId) {
        const ungroupedLookup = new Int64LookupResourceViewModel().fromModel({
          caption: '-',
          id: undefined,
        });
        filteredGroups.push(ungroupedLookup);
      }

      return filteredGroups;
    }

    get allGroups() {
      return this.getFilteredGroups();
    }
  }
  return BaseSchedulerListClass;
}
