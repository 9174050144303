<template>
  <div class="agogis-assign-to-lesson-form">
    <h5>{{ value.selectedLessons.length > 1 ? $t('page.lesson.planningMultipleSection.name') : $t('page.lesson.planningSingleSection.name') }}</h5>
    <hr aria-orientation="horizontal" class="mb-2 p-divider border-color-app-1">
    <agogis-assign-to-lesson-form-admin
      v-if="value.isPlanningAdmin"
      :value="value"
      @submit="onSubmit"
      @cancel="onCancel"
    ></agogis-assign-to-lesson-form-admin>
    <agogis-assign-to-lesson-form-reduced
      v-else
      :value="value"
      @submit="onSubmit"
      @newComment="onNewComment"
      @cancel="onCancel"
    ></agogis-assign-to-lesson-form-reduced>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import AgogisAssignToLessonFormReduced from './agogis-assign-to-lesson-form-reduced.vue';
  import AgogisAssignToLessonFormAdmin from './agogis-assign-to-lesson-form-admin.vue';
  import LecturerBookingSummaryView from './lecturer-booking-summary-view';

  export default Vue.extend({
    auth: true,
    name: 'AgogisAssignToLessonForm',
    components: {
      AgogisAssignToLessonFormAdmin,
      AgogisAssignToLessonFormReduced,
    },
    props: {
      value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
    },
    data: () => ({
    }),
    methods: {
      onSubmit() {
        this.$emit('submitted');
      },
      onNewComment() {
        this.$emit('commented');
      },
      onCancel() {
        this.$emit('cancel');
      },
    },
  });
</script>
