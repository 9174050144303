// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SchedulerModel from '../../model/scheduler-model';
import SchedulerDTO from '../dto/scheduler-dto';
import dtoSchema from '../schemas/scheduler-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int32LookupModel from '../../model/int32-lookup-model';

export default abstract class SchedulerModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SchedulerDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.type = data.type ? Int32LookupModel.toModel(data.type) : undefined;
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.sort = data.sort ?? undefined;
      this.startDate = data.startDate ?? undefined;
      this.endDate = data.endDate ?? undefined;
      this.tags = data.tags ?? undefined;
      this.parentResourceId = data.parentResourceId ?? undefined;
      this.parentResourceType = data.parentResourceType ? Int32LookupModel.toModel(data.parentResourceType) : undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  type?: Int32LookupModel;
  /**
  */
  caption?: string;
  /**
  */
  details?: string;
  /**
  */
  sort?: number[];
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  */
  tags?: string[];
  /**
  * @type {int64}
  */
  parentResourceId?: number;
  /**
  */
  parentResourceType?: Int32LookupModel;

  static toModel(dto: DeepPartial<SchedulerDTO>): SchedulerModel;
  static toModel(dto: DeepPartial<SchedulerDTO> | undefined | null): SchedulerModel | undefined;
  static toModel(dto: DeepPartial<SchedulerDTO> | undefined | null): SchedulerModel | undefined {
    return dto ? new SchedulerModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SchedulerModel> | SchedulerModel): SchedulerDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      type: unwrapped.type,
      caption: unwrapped.caption,
      details: unwrapped.details,
      sort: unwrapped.sort,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      tags: unwrapped.tags,
      parentResourceId: unwrapped.parentResourceId,
      parentResourceType: unwrapped.parentResourceType,
    } as SchedulerDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SchedulerModel, can not map to SchedulerDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
