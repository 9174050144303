// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import ModulePlanningLessonSchema from './module-planning-lesson-schema';
import ModulePlanningClassSchema from './module-planning-class-schema';
import ModulePlanningBookingSlotSchema from './module-planning-booking-slot-schema';

const schema = {
  $id: '/module-planning-overview-schema',
  type: 'object',
  required: ['id', 'moduleTotalDuration', 'moduleLessonsCount', 'classId', 'classStartYear'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    moduleName: {
      type: 'string',
      nullable: true,
    },
    moduleDescription: {
      type: 'string',
      nullable: true,
    },
    moduleStartDate: {
      type: 'string',
      format: 'date-time',
    },
    moduleEndDate: {
      type: 'string',
      format: 'date-time',
    },
    moduleTotalDuration: {
      type: 'number',
    },
    moduleLessonsCount: {
      type: 'integer',
    },
    classId: {
      type: 'integer',
    },
    className: {
      type: 'string',
      nullable: true,
    },
    classStartYear: {
      type: 'integer',
    },
    classIsPrototype: {
      type: 'boolean',
    },
    countOfRequiredLecturers: {
      type: 'integer',
      nullable: true,
    },
    countOfRequestedClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfProvisionalClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfReservedClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfConfirmedClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfRefusedClasses: {
      type: 'integer',
      nullable: true,
    },
    countOfOpenClasses: {
      type: 'integer',
      nullable: true,
    },
    lessons: {
      type: 'array',
      nullable: true,
      items: { ...ModulePlanningLessonSchema },
    },
    classes: {
      type: 'array',
      nullable: true,
      items: { ...ModulePlanningClassSchema },
    },
    bookings: {
      type: 'array',
      nullable: true,
      items: { ...ModulePlanningBookingSlotSchema },
    },
  },
} as IJSONSchema;

export default schema;
