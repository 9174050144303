import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Int64LookupModel from '@glittr/frontend-core/src/services/v2/model/int64-lookup-model';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import IpTableColumn from '@glittr/frontend-core/src/components/p-table/IpTableColumn';
import GetTypeLookupRequestModel from '@/src/services/v2/model/get-type-lookup-request-model';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import CockpitNotificationModel from '@/src/services/v2/model/cockpit-notification-model';
import SetStatesRequestModel from '@/src/services/v2/model/set-states-request-model';
import GetLecturersLookupRequestModel from '../../../../services/v2/model/get-lecturers-lookup-request-model';
import GetCreatorsLookupRequestModel from '../../../../services/v2/model/get-creators-lookup-request-model';
import GetStatesLookupRequestModel from '../../../../services/v2/model/get-states-lookup-request-model';
import CockpitNotificationDifferenceModel from '../../../../services/v2/model/cockpit-notification-difference-model';
import GetDifferenceRequestModel from '../../../../services/v2/model/get-difference-request-model';
import UpdateNotificationsRequestModel from '../../../../services/v2/model/update-notifications-request-model';
import CockpitNotificationStateLookupModel from '../../../../services/v2/model/cockpit-notification-state-lookup-model';
import GetTemplateLookupRequestModel from '../../../../services/v2/model/get-template-lookup-request-model';

export default class NotificationListPage extends PageBase {
  async initialize(): Promise<void> {
    this.lecturers = (await Vue.$service.v2.api.cockpitNotifications.getLecturersLookup({} as GetLecturersLookupRequestModel)).items.map((x) => x.data);
    this.creators = (await Vue.$service.v2.api.cockpitNotifications.getCreatorsLookup({} as GetCreatorsLookupRequestModel)).items.map((x) => x.data);
    this.states = (await Vue.$service.v2.api.cockpitNotifications.getStatesLookup({} as GetStatesLookupRequestModel)).items.map((x) => { return { ...x.data, caption: Vue.$tEnum('page.notification.notificationstateEnum', +x.data.id!) } as CockpitNotificationStateLookupModel; });
    this.templates = (await Vue.$service.v2.api.cockpitNotifications.getTemplateLookup({} as GetTemplateLookupRequestModel)).items.map((x) => x.data);
    await this.updateLastState();
    this.types = (await Vue.$service.v2.api.cockpitNotifications.getTypeLookup({} as GetTypeLookupRequestModel)).items.map((x) => x.data);
    this.notificationDatasource.paging.pageSize = 50;
    this.notificationDatasource.filter.creatorId = undefined;
    this.notificationDatasource.filter.recipientId = undefined;
    this.notificationDatasource.filter.statesIds = [];
    this.notificationDatasource.filter.templateIds = [];
    this.notificationDatasource.filter.creatorId = undefined;
    this.notificationDatasource.filter.from = `${Vue.$date.now().year()}-01-01`;
    this.notificationDatasource.filter.until = `${Vue.$date.now().year()}-12-31`;
    await this.onFilterChange();
    this.isLoading = false;
  }

  isLoading = true;

  notificationDatasource = new DataSource({
    selectCommand: Vue.$service.v2.api.cockpitNotifications.getCockpitNotificationsList,
    noRefreshOnFilterChange: true,
  });

  columns = [

    {
      value: Vue.$t('page.notification.notificationdate.title'),
      field: 'created',
      format: (value: string) => Vue.$format.localDateAndTime(value),
    },
    { value: Vue.$t('page.notification.notificationrecipient.title'), field: 'recipient' },
    {
      value: Vue.$t('page.notification.notificationmailTemplateId.title'),
      field: 'mailTemplateId',
      format: (value: string) => {
        const id = (this.types.filter((x) => x.caption === value)[0]?.id);
        return this.templates.filter((x) => x.id === id)[0]?.caption;
      },
    },
    { value: Vue.$t('page.notification.notificationsubject.title'), field: 'subject' },
    { value: Vue.$t('page.notification.notificationstate.title'), field: 'state', format: (value: number) => Vue.$tEnum('page.notification.notificationstateEnum', value) },
    { value: Vue.$t('page.notification.notificationinformation.title'), field: 'information' },
  ] as IpTableColumn[];

  isUpdatingNotifications: boolean = false;

  lastState: CockpitNotificationDifferenceModel | undefined = undefined;

  lecturers: Int64LookupModel[] = [];

  creators: Int64LookupModel[] = [];

  states: CockpitNotificationStateLookupModel[] = [];

  templates: Int64LookupModel[] = [];

  types: Int64LookupModel[] = [];

  async onUpdateNotifications() {
    this.isLoading = true;
    this.isUpdatingNotifications = true;
    await Vue.$service.v2.api.cockpitNotifications.updateNotifications({} as UpdateNotificationsRequestModel);
    await this.updateLastState();
    await this.notificationDatasource.select();
    this.isUpdatingNotifications = false;
    this.isLoading = false;
  }

  async updateLastState() {
    this.lastState = (await Vue.$service.v2.api.cockpitNotifications.getDifference({} as GetDifferenceRequestModel)).data;
  }

  async setState(newState:0 | 1 | 2 | 3 | 4 | undefined, items: DataRecord<CockpitNotificationModel>[]) {
    await Vue.$service.v2.api.cockpitNotifications.setStates(new SetStatesRequestModel({ state: newState, ids: items.map((x) => x.data!.id!) }));
    await this.onUpdateNotifications();
  }

  // eslint-disable-next-line func-names
  isDataRecord = function (item :CockpitNotificationModel):boolean {
    return item.mailTemplateId !== 'System';
  };

  // eslint-disable-next-line func-names
  canNotSelect = function (item :CockpitNotificationModel):boolean {
  /*
  Open = 0,
Send = 1,
DoNotSend = 2,
Error=3,
Transmitted = 4
 */
    return item.mailTemplateId === 'System' || item.state === 4;
  };

  async onFilterChange() {
    this.isLoading = true;
    // Update data for modules and lessons
    await this.updateLastState();
    await this.notificationDatasource.select();
    this.isLoading = false;
  }
}
