<template>
  <div class="agogis-widget-course-card item" :data-id="value.id">
    <div class="image">
      <div v-if="!value.imageUrl" class="backgroundimage" :style="{'background-image':`url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)`}"></div>
      <div v-if="value.imageUrl" class="backgroundimage" :style="{'background-image':`url(`+ value.imageUrl +`)`}"></div>
    </div>
    <div class="text_wrapper">
      <div class="row">
        <div class="col-12">
          <div class="titel">
            {{ value.name }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8 col-lg-9">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="datum">
                {{ dateSectionText }}
              </div>
              <div class="preis">
                Kosten: {{ value.priceString }}
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="ort">
                {{ value.city }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 statuswrapper">
              <div class="status">
                <div :class="'circle '+ value.registrationIndicator+' '+ value.registrationStatus"></div>
                <div class="availability">
                  {{ value.availabilityString }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-3">
          <div class="detaillinkwrapper">
            <a v-if="value.detailUrl" class="detail" :href="value.detailUrl">Detail</a>
          </div>
          <a
            v-if="hasAppointments"
            class="termine toggle"
            @click="toggleAppointments"
            @keyup.enter="toggleAppointments"
            @keyup.space="toggleAppointments"
          >Termine</a>
          <a v-if="value.isBookingActionAvailable" class="ghostbutton anmelden" :href="value.registrationUrl" target="_blank">anmelden</a>
        </div>
        <div v-if="isAppointmentsVisible && hasAppointments" class="terminliste col-12">
          <div class="row">
            <div class="col-8 col-lg-9">
              Kursdaten
            </div>
            <div class="col-4 col-lg-3">
              <a
                class="termine toggle"
                @click="toggleAppointments"
                @keyup.enter="toggleAppointments"
                @keyup.space="toggleAppointments"
              >Schliessen</a>
            </div>
          </div>
          <ul>
            <li v-for="t in value.occurences" :key="t.id">
              {{ t.terminString }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import WidgetClassListResourceViewModel from '../services/v1/viewModel/resource/WidgetClassListResourceViewModel';

  export default Vue.extend({
    name: 'AgogisWidgetCourseCard',
    props: {
      value: { type: Object as PropType<WidgetClassListResourceViewModel>, default: undefined },
    },
    data: () => ({
      isAppointmentsVisible: false,
    }),
    computed: {
      hasAppointments(): boolean {
        return this.eventTypeConfig?.hasAppointments ?? true;
      },
      eventTypeConfig(): NonNullable<typeof Vue.$config.values['widget-course-event-type-config']>[string] | undefined {
        const eventTypeConfig = this.$config.values['widget-course-event-type-config'];
        if (!this._.isObject(eventTypeConfig)) {
          this.$log.error('Config entry \'widget-course-event-type-config\' wrong type');
          return undefined;
        }
        return eventTypeConfig[this.value.eventType?.toString()!];
      },
      dateSectionText(): string {
        const startDate = this.$format.localDate(this.value.startDate!);
        const endDate = this.$format.localDate(this.value.endDate!);
        const dateString = `${startDate} - ${endDate}`;

        const configText = this.eventTypeConfig?.dateText ?? dateString;
        const displayText = this.$tAlt(configText, configText);
        return displayText;
      },
    },
    methods: {
      toggleAppointments() {
        this.isAppointmentsVisible = !this.isAppointmentsVisible;
      },
    },
  });
</script>
