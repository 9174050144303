import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import LectureLessonStatusApi from '../generated/api/LectureLessonStatus';

export default (service: Servicelayer) => ({
  ...LectureLessonStatusApi(service),

  // Add custom calls here

});
