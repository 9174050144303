import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import LectureLessonModel from '../../../../../services/v2/model/lecture-lesson-model';
import GetReferentsByLessonIdRequestModel from '../../../../../services/v2/model/get-referents-by-lesson-id-request-model';
import GetLessonByIdRequestModel from '../../../../../services/v2/model/get-lesson-by-id-request-model';

export default class LessonDetailPage extends PageBase {
  private lessonId: number = 0;
  lesson: LectureLessonModel = new LectureLessonModel();
  lessonReferentsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.lessons.getReferentsByLessonId,
  });
  filter: {
    lectureLessonId: number,
    lecturerId?: number,
    searchCriteria?: string
  } = {
      lectureLessonId: this.lessonId,
    };
  filterChangeEvent = new DataEvent<[typeof this.filter]>();
  lessonHistoryDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.agogisCockpit.getLectureLessonHistoryPagedList,
  });
  errorMessage?: string = undefined;

  async initialize(): Promise<void> {
    // get the lessonid
    this.getLessonId();
    this.lessonReferentsDataSource.filter.id = this.lessonId;
    Vue.set(this.filter, 'lectureLessonId', this.lessonId);

    // Load data
    this.filterChangeEvent.invoke(this.filter);
    this.lessonHistoryDataSource.filter.lectureLessonId = this.filter.lectureLessonId;
    this.lessonHistoryDataSource.filter.lecturerId = this.filter.lecturerId;
    this.lessonHistoryDataSource.filter.searchCriteria = this.filter.searchCriteria;
    await this.lessonHistoryDataSource.select();
    await this.loadLesson(this.lessonId);
  }

  // Page
  get pageTitle() : string {
    return `${this.lesson.lectureModuleName}: ${Vue.$format.localDate(this.lesson.startTime!)} ${this.lesson.lessonName} (${this.lesson.lectureClassName})`;
  }

  onFilterChange() {
    Vue.$debounce(async () => {
      this.lessonHistoryDataSource.filter.lectureLessonId = this.filter.lectureLessonId;
      this.lessonHistoryDataSource.filter.lecturerId = this.filter.lecturerId;
      this.lessonHistoryDataSource.filter.searchCriteria = this.filter.searchCriteria;
    }, 500, this)();

    this.filterChangeEvent.invoke(this.filter);
  }

  // Lesson
  getLessonId() {
    const { id } = Vue.$router.currentRoute.params;
    this.lessonId = Number.parseInt(id, 10);
  }
  async loadLesson(id: number) {
    try {
      const response = await Vue.$service.v2.api.lessons.getLessonById(new GetLessonByIdRequestModel({ id }));
      this.lesson = response.data;
    } catch (error: any) {
      this.errorMessage = error;
    }
  }
  // Referents
  get lessonReferentsTableColumns() {
    return [
      {
        value: Vue.$t('page.lesson.table.teacherName'),
        field: 'lecturerFullName',
      },
      {
        value: Vue.$t('page.lesson.table.roleName'),
        field: 'lecturerRoleName',
      },
      {
        value: Vue.$t('page.lesson.table.remark'),
        field: 'lecturerComment',
      },
      {
        value: Vue.$t('page.lesson.table.adminRemark'),
        field: 'lecturerAdminComment',
      },
      {
        value: Vue.$t('page.lesson.table.status'),
        field: 'lecturerStatusName',
      },
    ];
  }

  async loadAllReferents(id: number) {
    try {
      const resp = await Vue.$service.v2.api.lessons.getReferentsByLessonId(new GetReferentsByLessonIdRequestModel({ id }));
      return resp.items;
    } catch (error: any) {
      this.errorMessage = error;
    }
    return [];
  }

  // history
  get lessonHistoryTableColumns() {
    return [
      {
        value: Vue.$t('page.lessonHistory.table.date'),
        field: 'created',
        format: (value: string) => Vue.$format.localDateAndTime(value),
      },
      {
        value: Vue.$t('page.lessonHistory.table.teacher'),
        field: 'lecturerName',
      },
      {
        value: Vue.$t('page.lessonHistory.table.remark'),
        field: 'comment',
      },
      {
        value: Vue.$t('page.lessonHistory.table.adminRemark'),
        field: 'adminComment',
      },
      {
        value: Vue.$t('page.lessonHistory.table.statusOld'),
        field: 'statusOld',
      },
      {
        value: Vue.$t('page.lessonHistory.table.statusNew'),
        field: 'statusNew',
      },
      {
        value: Vue.$t('page.lessonHistory.table.modifier'),
        field: 'creatorName',
      },
    ];
  }
}
