<template>
  <div class="agogis-toggle-selection p-form-element">
    <p-row :column="column" no-gutters>
      <p-col xs12 :md6="!column">
        <p-button
          class="pl-0"
          :disabled="disabled || falseDisabled"
          stretch
          :secondary="selected !== 0"
          @click="onSelect(0)"
        >
          <p-radio-field v-model="selected" tabindex="-1" color="info" :true-value="0" :name="'toggle-selection' + _uid"></p-radio-field>{{ $tAlt(falseLabel, falseLabel) }}
        </p-button>
      </p-col>
      <p-col xs12 :md6="!column">
        <p-button
          class="pl-0"
          :disabled="disabled || trueDisabled"
          stretch
          :secondary="selected !== 1"
          @click="onSelect(1)"
        >
          <p-radio-field v-model="selected" tabindex="-1" color="info" :true-value="1" :name="'toggle-selection' + _uid"></p-radio-field>{{ $tAlt(trueLabel, trueLabel) }}
        </p-button>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'AgogisToggleSelection',
    props: {
      value: { default: undefined, type: undefined },
      trueLabel: { default: 'core.app.yes', type: undefined },
      falseLabel: { default: 'core.app.no', type: undefined },
      trueValue: { default: undefined, type: undefined },
      falseValue: { default: undefined, type: undefined },
      trueColor: { default: 'positive', type: String },
      falseColor: { default: 'negative', type: String },
      trueDisabled: { default: false, type: Boolean },
      falseDisabled: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean },
      column: { default: true, type: Boolean },
    },
    data: () => ({
      selected: -1,
    }),
    watch: {
      value: {
        immediate: true,
        handler() {
          if (this.value === (this.trueValue ?? true)) {
            this.selected = 1;
          } else if (this.value === (this.falseValue ?? true)) {
            this.selected = 0;
          }
        },
      },
    },
    methods: {
      onSelect(selected: number) {
        this.selected = selected;
        if (this.selected === 0) {
          this.$emit('input', this.falseValue ?? false);
        } else {
          this.$emit('input', this.trueValue ?? true);
        }
      },
    },
  });
</script>

<style lang="scss">
  .#{$namespace-root} {
    .agogis-toggle-selection{
      .p-radio-field input[type=radio]
      {
        &:checked{
          + .p-radio-field-label-wrapper label {
          &:before {
            border-color: #111;
          }
          &:after {
            background-color: #111;
          }
        }
        }
        + .p-radio-field-label-wrapper label {
          &:before {
            height: 14px;
            width: 14px;
            border-color: get-color(app-3);
          }
          &:after {
            height: 7px;
            width: 7px;
            left: 3.5px;
          }
        }
      }
    }
  }
</style>
