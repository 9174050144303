/* eslint-disable no-bitwise */
import Vue from 'vue';
import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import LectureLessonListResourceModel from '../../generated/types/model/resource/LectureLessonListResourceModel';
import LectureLessonBookingActionResourceViewModel from './LectureLessonBookingActionResourceViewModel';
import LectureLessonBookingActionResourceDTO from '../../generated/types/dto/resource/LectureLessonBookingActionResourceDTO';

export default class LectureLessonListResourceViewModel extends LectureLessonListResourceModel {
  onSelectionChanged = new DataEvent<[this]>();

  get isSelected() { return (this.dto as any).isSelected; }
  set isSelected(value: boolean) {
    if ((this.dto as any).isSelected !== value) {
      (this.dto as any).isSelected = value;
      this.onSelectionChanged.invoke(this);
    }
  }
  get id() { return (this.dto as any).id; }
  set id(value) { (this.dto as any).id = value; }
  items: DataRecord[] = [];

  get lecturerStatusCaption() {
    return this.lecturerStatus?.name ?? '-';
  }

  get bookingAction() { return this.dto.bookingAction as (LectureLessonBookingActionResourceViewModel & LectureLessonBookingActionResourceDTO) | undefined; }
  set bookingAction(value: (LectureLessonBookingActionResourceViewModel & LectureLessonBookingActionResourceDTO) | undefined) { this.dto.bookingAction = value as any; }

  isBookingActionPossible(action: LectureLessonBookingActionResourceViewModel) {
    // Bit mask shenanigans
    return ((this.bookingAction ?? 0) & action) === action;
  }

  get canBeAssigned() {
    return this.isBookingActionPossible(LectureLessonBookingActionResourceViewModel.Assign);
    // return this.isNotYetAssigned || canConfirm || this.lecturerId !== Vue.$service.api.me.getAgogisUserId();
  }

  get canBeConfirmed() {
    return this.isBookingActionPossible(LectureLessonBookingActionResourceViewModel.Confirm);
    // return this.isNotYetAssigned || canConfirm || this.lecturerId !== Vue.$service.api.me.getAgogisUserId();
  }

  get canBeDeclined() {
    return this.isBookingActionPossible(LectureLessonBookingActionResourceViewModel.Decline);
    // return canDecline && this.lecturerId === Vue.$service.api.me.getAgogisUserId();
  }

  get hasNoBookingAction() {
    return (this.bookingAction ?? 0) === LectureLessonBookingActionResourceViewModel.None;
  }

  get lecturerFullName() {
    if (!this.lecturerFirstName && !this.lecturerLastName) {
      return undefined;
    } if (!this.lecturerFirstName) {
      return this.lecturerLastName;
    } if (!this.lecturerLastName) {
      return this.lecturerFirstName;
    }
    return `${this.lecturerLastName} ${this.lecturerFirstName}`;
  }

  get timeOfDay() {
    const dayOfWeek = Vue.$date(this.startTime).format('dd');
    const startDate = Vue.$date(this.startTime);
    const endDate = Vue.$date(this.endTime);
    if (endDate.diff(startDate, 'days') === 0) {
      if (endDate.hour() > 12 && startDate.hour() < 12) {
        return `${dayOfWeek}/${Vue.$t('page.lesson.label.timeOfDay.fullDay')}`;
      }
      return `${dayOfWeek}/${startDate.hour() > 12 ? Vue.$t('page.lesson.label.timeOfDay.afternoon') : Vue.$t('page.lesson.label.timeOfDay.morning')}`;
    }
    return `${dayOfWeek}/${Vue.$t('page.lesson.label.timeOfDay.multipleDays')}`;
  }
}
