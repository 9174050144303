// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCockpitNotificationsListRequestModel from '../../model/get-cockpit-notifications-list-request-model';
import SetStatesRequestModel from '../../model/set-states-request-model';
import UpdateNotificationsRequestModel from '../../model/update-notifications-request-model';
import GetDifferenceRequestModel from '../../model/get-difference-request-model';
import GetLecturersLookupRequestModel from '../../model/get-lecturers-lookup-request-model';
import GetCreatorsLookupRequestModel from '../../model/get-creators-lookup-request-model';
import GetStatesLookupRequestModel from '../../model/get-states-lookup-request-model';
import GetTemplateLookupRequestModel from '../../model/get-template-lookup-request-model';
import GetTypeLookupRequestModel from '../../model/get-type-lookup-request-model';
import CockpitNotificationPagingResultModel from '../../model/cockpit-notification-paging-result-model';
import CockpitNotificationDifferenceModel from '../../model/cockpit-notification-difference-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';
import CockpitNotificationStateLookupListResultModel from '../../model/cockpit-notification-state-lookup-list-result-model';
import Int32LookupListResultModel from '../../model/int32-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCockpitNotificationsList(request: GetCockpitNotificationsListRequestModel, config?: RequestConfig) {
    const requestDTO = GetCockpitNotificationsListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-cockpit-notifications-list'] ?? 'CockpitNotifications';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        from: requestDTO.from,
        until: requestDTO.until,
        recipientId: requestDTO.recipientId,
        creatorId: requestDTO.creatorId,
        statesIds: requestDTO.statesIds,
        templateIds: requestDTO.templateIds,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CockpitNotificationPagingResultModel.toModel(dto));
  },
  /**  */
  async setStates(request: SetStatesRequestModel, config?: RequestConfig) {
    const requestDTO = SetStatesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-states'] ?? 'CockpitNotifications';
    const response = await service.post<any>(endpointPath, {
      body: {
        state: requestDTO.state,
        ids: requestDTO.ids,
        information: requestDTO.information,
      },
      ...config,
    });
  },
  /**  */
  async updateNotifications(request: UpdateNotificationsRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-notifications'] ?? 'CockpitNotifications';
    const response = await service.patch<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
  /**  */
  async getDifference(request: GetDifferenceRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-difference'] ?? 'CockpitNotifications/difference';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CockpitNotificationDifferenceModel.toModel(dto));
  },
  /**  */
  async getLecturersLookup(request: GetLecturersLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lecturers-lookup'] ?? 'CockpitNotifications/Lecturer/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getCreatorsLookup(request: GetCreatorsLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-creators-lookup'] ?? 'CockpitNotifications/Creator/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getStatesLookup(request: GetStatesLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-states-lookup'] ?? 'CockpitNotifications/State/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CockpitNotificationStateLookupListResultModel.toModel(dto));
  },
  /**  */
  async getTemplateLookup(request: GetTemplateLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-template-lookup'] ?? 'CockpitNotifications/Template/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupListResultModel.toModel(dto));
  },
  /**  */
  async getTypeLookup(request: GetTypeLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-type-lookup'] ?? 'CockpitNotifications/Type/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupListResultModel.toModel(dto));
  },
});
