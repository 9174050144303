// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import WidgetClassContentItemViewModel from '../../../../viewModel/resource/WidgetClassContentItemViewModel';
import WidgetClassFaqResourceViewModel from '../../../../viewModel/resource/WidgetClassFaqResourceViewModel';
import WidgetClassFaqResourceDTO from '../../dto/resource/WidgetClassFaqResourceDTO';

export default abstract class WidgetClassFaqResourceModel extends BaseModel<WidgetClassFaqResourceDTO> {
  /**
  * @type {int32}
  */
  get order() { return this.dto.order; }
  set order(value) { this.dto.order = value; }
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get content() { return this.getArrayModels(() => this.dto.content, WidgetClassContentItemViewModel)!; }
  set content(value) { this.setArrayModels(() => this.dto.content, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.order);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.order = value;
    this.dto.title = qs.title;
  }
}
