import Vue from 'vue';
import EventOccurenceResourceModel from '../../generated/types/model/resource/EventOccurenceResourceModel';

export default class EventOccurenceResourceViewModel extends EventOccurenceResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  // 21.03.2022 09:15 - 16:45
  get terminString():string {
    let output = '';
    if (this.startDate) {
      output = Vue.$format.localDateAndTime(this.startDate)!;
    }
    if (this.endDate) {
      output += ` - ${Vue.$format.localTime(this.endDate)!}`;
    }
    return output;
  }

  // 09:15 - 16:45
  get timeString():string {
    let output = '';
    if (this.startDate) {
      output = Vue.$format.localTime(this.startDate)!;
    }
    if (this.endDate) {
      output += ` - ${Vue.$format.localTime(this.endDate)!}`;
    }
    return output;
  }
}
