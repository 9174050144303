// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetWidgetClassNativeLanguageLookupRequestModel from '../../model/get-widget-class-native-language-lookup-request-model';
import GetWidgetClassNativeLanguageLookupRequestDTO from '../dto/get-widget-class-native-language-lookup-request-dto';
import dtoSchema from '../schemas/get-widget-class-native-language-lookup-request-schema';

export default abstract class GetWidgetClassNativeLanguageLookupRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetWidgetClassNativeLanguageLookupRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.id = data.id;
      this.key = data.key;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {int32}
  */
  id?: number;
  /**
  */
  key?: string;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetWidgetClassNativeLanguageLookupRequestDTO>): GetWidgetClassNativeLanguageLookupRequestModel;
  static toModel(dto: DeepPartial<GetWidgetClassNativeLanguageLookupRequestDTO> | undefined | null): GetWidgetClassNativeLanguageLookupRequestModel | undefined;
  static toModel(dto: DeepPartial<GetWidgetClassNativeLanguageLookupRequestDTO> | undefined | null): GetWidgetClassNativeLanguageLookupRequestModel | undefined {
    return dto ? new GetWidgetClassNativeLanguageLookupRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetWidgetClassNativeLanguageLookupRequestModel> | GetWidgetClassNativeLanguageLookupRequestModel): GetWidgetClassNativeLanguageLookupRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      id: unwrapped.id,
      key: unwrapped.key,
      searchCriteria: unwrapped.searchCriteria,
    } as GetWidgetClassNativeLanguageLookupRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetWidgetClassNativeLanguageLookupRequestModel, can not map to GetWidgetClassNativeLanguageLookupRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
