// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormContactResourceViewModel from '../../../../viewModel/resource/DynamicFormContactResourceViewModel';
import DynamicFormFieldPayloadResourceViewModel from '../../../../viewModel/resource/DynamicFormFieldPayloadResourceViewModel';
import DynamicFormPayloadResourceViewModel from '../../../../viewModel/resource/DynamicFormPayloadResourceViewModel';
import DynamicFormPayloadResourceDTO from '../../dto/resource/DynamicFormPayloadResourceDTO';

export default abstract class DynamicFormPayloadResourceModel extends BaseModel<DynamicFormPayloadResourceDTO> {
  /**
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get definitionId() { return this.dto.definitionId; }
  set definitionId(value) { this.dto.definitionId = value; }
  /**
  */
  get contactId() { return this.dto.contactId; }
  set contactId(value) { this.dto.contactId = value; }
  /**
  */
  get contact() { return this.getObjectModel(() => this.dto.contact, DynamicFormContactResourceViewModel)!; }
  set contact(value) { this.setObjectModel(() => this.dto.contact, value); }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  * @type {int64}
  */
  get businessUnit() { return this.dto.businessUnit; }
  set businessUnit(value) { this.dto.businessUnit = value; }
  /**
  */
  get fields() { return this.getArrayModels(() => this.dto.fields, DynamicFormFieldPayloadResourceViewModel)!; }
  set fields(value) { this.setArrayModels(() => this.dto.fields, value); }
  /**
  */
  get transactionId() { return this.dto.transactionId; }
  set transactionId(value) { this.dto.transactionId = value; }
  /**
  * @type {int32}
  */
  get contactFormType() { return this.dto.contactFormType; }
  set contactFormType(value) { this.dto.contactFormType = value; }
  /**
  * @type {int32}
  */
  get addressOwnerNumber() { return this.dto.addressOwnerNumber; }
  set addressOwnerNumber(value) { this.dto.addressOwnerNumber = value; }
  /**
  */
  get subject() { return this.dto.subject; }
  set subject(value) { this.dto.subject = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.definitionId = qs.definitionid;
    this.dto.contactId = qs.contactid;
    this.dto.languageIso2 = qs.languageiso2;
    value = Number.parseFloat(qs.businessunit);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.businessUnit = value;
    this.dto.transactionId = qs.transactionid;
    value = Number.parseFloat(qs.contactformtype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contactFormType = value;
    value = Number.parseFloat(qs.addressownernumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.addressOwnerNumber = value;
    this.dto.subject = qs.subject;
  }
}
