<template>
  <p-scroll-y-reverse-transition hide-on-leave>
    <p-container v-if="mounted" class="login-page">
      <p-row fill-height>
        <p-col xs12 sm7 md8 class="login-panel">
          <p-row column fill-height>
            <p-col shrink>
              <h3>{{ $t("core.page.login.page.title") }}</h3>
            </p-col>
            <p-col shrink>
              <p-form :model="login" no-summary>
                <slot name="append-input-fields"></slot>
                <p-text-field
                  v-model="login.userName"
                  autocomplete="username"
                  name="username"
                  autofocus
                  :label="$t('core.page.login.label.usernameOrEmail')"
                  @keyup.enter="focusOnPassword"
                />
                <p-text-field
                  ref="passwordField"
                  v-model="login.password"
                  type="password"
                  name="password"
                  :label="$t('core.page.login.label.password')"
                  @keyup.enter="doLogin"
                />
                <slot name="append-input-fields"></slot>
              </p-form>
            </p-col>
            <p-col grow>
              <p-row no-gutters>
                <p-col xs12 sm6>
                  <p-button
                    class="mt-4"
                    stretch
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="doLogin"
                  >
                    {{ $t('core.page.login.button.login') }}
                  </p-button>
                </p-col>
                <p-col xs12 sm6 justify-end>
                  <p-button tertiary link class="mt-5" @click="gotoForgotPassword()">
                    {{ $t('core.page.login.button.forgotPassword') }}
                  </p-button>
                </p-col>
              </p-row>
              <p-row v-if="isADLoginCofigured">
                <p-col>
                  <p-divider horicontal class="hidden-xs-only" />
                  <p-button
                    class="mt-4"
                    stretch
                    secondary
                    :disabled="isLoading"
                    @click="doLoginMicrosoft"
                  >
                    agogis-Login
                  </p-button>
                </p-col>
              </p-row>
            </p-col>
            <p-col v-if="_.component.hasSlot(this, 'append-form')" shrink>
              <slot name="append-form"></slot>
            </p-col>
            <p-col v-if="requestError" shrink>
              <p-request-alert v-model="requestError" class="mt-3"></p-request-alert>
            </p-col>
          </p-row>
        </p-col>
        <p-col xs12 sm1 justify-center class="login-page-divider">
          <p-divider vertical class="hidden-xs-only" />
          <p-divider class="hidden-sm-and-up"></p-divider>
        </p-col>
        <p-col xs12 sm4 md3 class="goto-registration-panel">
          <p-row column fill-height>
            <p-col grow>
              <h3>
                {{ $t('core.page.login.label.needToRegister') }}
              </h3>
            </p-col>
            <p-col shrink>
              <p-button stretch secondary class="mb-5" @click="gotoRegister()">
                {{ $t('core.page.login.button.register') }}
              </p-button>
            </p-col>
          </p-row>
        </p-col>
      </p-row>
    </p-container>
  </p-scroll-y-reverse-transition>
</template>

<script lang="ts">
  import Vue from 'vue';
  import * as msal from '@azure/msal-browser';
  import TokenRequestViewModel from '../../../services/v1/viewModel/resource/TokenRequestViewModel';

  export default Vue.extend({

    auth: false,
    layout: ['layout-exterior', '_exterior'],
    data: () => ({
      mounted: false,
      login: new TokenRequestViewModel(),
      isLoading: false,
      requestError: undefined,
      isADLoginCofigured: false,
    }),
    computed: {
      redirectPath(): string {
        return this.$route.query.redirect as string;
      },
    },
    mounted() {
      this.mounted = true;
      this.loadQRData();
      const config = this.$config.values['identy-provider-settings'].Microsoft;
      this.isADLoginCofigured = config.clientId.length > 0 && config.authority.length > 0;
    },
    methods: {
      loadQRData() {
        Vue.$msalInstance = null;
        const query = this.$router.currentRoute?.query ?? {};
        // eslint-disable-next-line no-unused-vars
        const { username, email, registrationKey } = query as Record<string, string>;
        this.login.userName = username;
      },
      focusOnPassword() {
        const { passwordField } = this.$refs;
        const inputField = (passwordField as Vue).$refs.input;
        (inputField as HTMLElement).focus();
      },
      gotoForgotPassword() {
        Vue.$msalInstance = null;
        Vue.$router.push({ path: '/_exterior/reset-password', query: this.$route.query });
      },
      gotoRegister() {
        Vue.$msalInstance = null;
        Vue.$router.push({ path: '/_exterior/register', query: this.$route.query });
      },
      async redirectToTarget() {
        if (Vue.$auth.user?.statusId === 0) {
          this.$router.push({ path: '/_exterior/verify-user/', query: this.$route.query });
          return;
        }
        Vue.$routerUtils.returnToRedirectOrigin('/');
      },
      async doLoginMicrosoft() {
        this.isLoading = true;
        try {
          const config = this.$config.values['identy-provider-settings'].Microsoft;
          const msalConfig: msal.Configuration = {
            auth: {
              clientId: config.clientId,
              authority: config.authority,
              redirectUri: `${window.location.origin}`,
            },
            cache: {
              cacheLocation: 'localStorage',
              storeAuthStateInCookie: true,
              cacheMigrationEnabled: true,
              temporaryCacheLocation: 'localStorage',

            },
          };
          if (!Vue.$msalInstance) {
            Vue.$msalInstance = new msal.PublicClientApplication(msalConfig);
          }
          let account :msal.AccountInfo | null = null;
          try {
            Vue.$msalInstance!.setActiveAccount(null);
            const authResult :msal.AuthenticationResult = await Vue.$msalInstance!.loginPopup({ scopes: config.scopes, prompt: 'select_account' });
            Vue.$msalInstance!.setActiveAccount(authResult.account);
            account = authResult.account;
          } catch (error: any) {
            this.requestError = error;
            account = null;
            this.isLoading = false;
          }
          if (account) {
            // msalConfig.auth
            try {
              this.isLoading = false;
              await Vue.$auth.login({
                account: account || undefined,
                scopes: config.scopes,
                authority: msalConfig.auth.authority,
                redirectUri: msalConfig.auth.redirectUri,
              });
              this.redirectToTarget();
            } catch (error: any) {
              this.requestError = error;
              account = null;
            } finally {
              this.isLoading = false;
            }
          }
        } catch (error: any) {
          this.requestError = error;
        } finally {
          this.isLoading = false;
        }
      },
      async doLogin() {
        Vue.$msalInstance = null;
        Vue.$localStorage.remove('auth.token');
        if (!this.login.validate()) {
          return;
        }
        try {
          this.requestError = undefined;
          this.isLoading = true;

          await Vue.$auth.login(this.login);
          this.redirectToTarget();
        } catch (error: any) {
          this.requestError = error;
        } finally {
          this.isLoading = false;
        }
      },
    },
  });
</script>
