<template>
  <p-container fluid class="notification-container">
    <!-- Title -->
    <h1>{{ $t('page.notification.name') }}</h1>
    <!-- Filter -->
    <p-row class="notification-filter">
      <p-col grow md3 lg2>
        <p-text-field
          v-model="vm.notificationDatasource.filter.searchCriteria"
          :is-loading="vm.isLoading"
          clearable
          :label="$t('core.app.search')"
          context-icon="search"
          @clear="vm.notificationDatasource.filter.searchCriteria = ''"
          @input="vm.onFilterChange()"
        ></p-text-field>
      </p-col>
      <p-col sm4 md2 lg2>
        <p-date-field
          v-model="vm.notificationDatasource.filter.from"
          :label="$t('page.notification.filter.from')"
          required
          @input="vm.onFilterChange()"
        ></p-date-field>
      </p-col>
      <p-col sm4 md2 lg2>
        <p-date-field
          v-model="vm.notificationDatasource.filter.until"
          :label="$t('page.notification.filter.until')"
          required
          @input="vm.onFilterChange()"
        ></p-date-field>
      </p-col>
      <p-col sm4 md2 lg2>
        <p-select-multiple
          v-model="vm.notificationDatasource.filter.templateIds"
          :is-loading="vm.isLoading || vm.notificationDatasource.isLoading"
          :items="vm.templates"
          clearable
          can-search
          :label="$t('page.notification.filter.template')"
          @input="vm.onFilterChange()"
        >
        </p-select-multiple>
      </p-col>
      <p-col sm4 md3 lg2>
        <agogis-lecturer-lookup
          v-model="vm.notificationDatasource.filter.recipientId"
          :is-loading="vm.isLoading"
          :label="$t('page.notification.filter.lecturer')"
          context-icon="search"
          clearable
          @input="vm.onFilterChange()"
        ></agogis-lecturer-lookup>
      </p-col>
      <p-col sm4 md3 lg2>
        <p-select
          v-model="vm.notificationDatasource.filter.creatorId"
          :is-loading="vm.isLoading || vm.notificationDatasource.isLoading"
          :items="vm.creators"
          clearable
          can-search
          :label="$t('page.notification.filter.creator')"
          @input="vm.onFilterChange()"
        >
        </p-select>
      </p-col>
      <p-col sm4 md3 lg2>
        <p-select-multiple
          v-model="vm.notificationDatasource.filter.statesIds"
          :is-loading="vm.isLoading || vm.notificationDatasource.isLoading"
          :items="vm.states"
          clearable
          can-search
          :label="$t('page.notification.filter.states')"
          @input="vm.onFilterChange()"
        >
        </p-select-multiple>
      </p-col>
    </p-row>
    <p-row v-if="vm.lastState && vm.lastState.count > 0" class="data-state" no-gutters>
      <p-col>
        <p-alert value="true" type="info">
          <p-row>
            <p-col grow>
              {{ $t('page.notification.datastate.info',
                    vm.lastState.count,
                    $format.localDateAndTime(vm.lastState.lastCreation)) }}
            </p-col>
            <p-col justify-end>
              <p-button color="info" :disabled="vm.isUpdatingNotifications" @click="vm.onUpdateNotifications()">
                {{
                  $t('page.notification.datastate.getchanges') }}
              </p-button>
            </p-col>
          </p-row>
        </p-alert>
      </p-col>
    </p-row>
    <p-row class="notification-datasource-container" no-gutters>
      <p-col>
        <p-progress-linear
          v-if="vm.isLoading || vm.notificationDatasource.isLoading"
          class="my-4"
          indeterminate
        ></p-progress-linear>
        <p-row v-else-if="vm.notificationDatasource.data">
          <p-col class="my-0">
            <p-row>
              <p-col shrink>
                <p-button @click="vm.onFilterChange()">
                  <p-icon>refresh</p-icon>
                </p-button>
              </p-col>
              <p-col shrink>
                <p-button :disabled="!vm.notificationDatasource.data.items.some(x => x.isSelected)" @click="vm.setState(1, vm.notificationDatasource.data.items.filter(x => x.isSelected))">
                  {{ $tEnum('page.notification.notificationstateEnum', 1) }}
                </p-button>
              </p-col>
              <p-col shrink>
                <p-button :disabled="!vm.notificationDatasource.data.items.some(x => x.isSelected)" @click="vm.setState(2, vm.notificationDatasource.data.items.filter(x => x.isSelected))">
                  {{ $tEnum('page.notification.notificationstateEnum', 2) }}
                </p-button>
              </p-col>
            </p-row>
            <p-row>
              <p-col>
                <agogis-table v-model="vm.notificationDatasource.data.items" :columns="vm.columns" :disabled-function="vm.canNotSelect" :is-data-record-function="vm.isDataRecord">
                  <!-- <template #specialRow="slotProps">
                    <tr>
                      <td :colspan="1 + vm.columns.length" class="notification-list-td">
                        <div class="notification-list-hr color-surface-2">
                          <span class="calculates-text ">
                            {{ $t('page.notification.creation.info', $format.localDateAndTime(slotProps.item.data.created)) }} --><!--Padding is optional--><!--
                          </span>
                        </div>
                      </td>
                    </tr>
                  </template> -->
                </agogis-table>
                <p-pagination
                  v-model="vm.notificationDatasource.paging"
                  @input="vm.onFilterChange()"
                ></p-pagination>
              </p-col>
            </p-row>
          </p-col>
        </p-row>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import AgogisTable from '@/src/components/agogis-table.vue';
  import NotificationListPage from './index.vue.model';

  export default Vue.extend({
    auth: true,
    components: { AgogisTable },
    data: () => ({
      vm: new NotificationListPage(),
    }),
    async mounted() {
      this.vm.initialize();
    },
  });
</script>
