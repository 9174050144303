<template>
  <p-container>
    <p-row spacious>
      <p-col xs12 md6>
        <p-card class="fill-height">
          <h3>{{ $t('core.page.profile.page.title') }}</h3>
          <p-container fluid>
            <p-row column>
              <p-text-field v-model="user.userName" :loading="isLoading" :label="$t('core.page.profile.label.userName')" readonly></p-text-field>

              <p-text-field v-model="user.email" :loading="isLoading" :label="$t('core.page.profile.label.eMail')" readonly></p-text-field>

              <p-text-field v-model="user.userLanguage" :loading="isLoading" :label="$t('core.page.profile.label.userLanguage')" readonly></p-text-field>

              <p-text-field v-model="user.userCulture" :loading="isLoading" :label="$t('core.page.profile.label.userCulture')" readonly></p-text-field>
              <p-col v-if="user.statusId === 0" justify-end>
                <p-button @click="$router.push('/_exterior/verify-user')">
                  {{ $t("page.profile.button.doVerifyUser") }}
                </p-button>
              </p-col>
              <p-col justify-end>
                <p-button @click="doLogoff">
                  {{ $t('core.page.profile.button.logoff') }}
                  <p-icon right>
                    exit_to_app
                  </p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-container>
        </p-card>
      </p-col>
      <p-col v-if="canChangePassword" xs12 md6>
        <p-card class="fill-height">
          <h3>{{ $t("core.page.profile.label.resetPassword") }}</h3>
          <p-container fluid>
            <p-row column>
              <!-- <p-alert v-model="resetPasswordValidationMessage" :dismissable="false" type="error">
                {{ resetPasswordValidationMessage }}
              </p-alert> -->
              <p-text-field
                v-model="resetPassword.oldPassword"
                name="oldPassword"
                type="password"
                :label="$t('core.page.profile.label.oldPassword')"
              />
              <p-text-field
                v-model="resetPassword.newPassword"
                name="newPassword"
                type="password"
                :errors="newPasswordError"
                :label="$t('core.page.profile.label.newPassword')"
              />
              <p-text-field
                v-model="resetPassword.repeatNewPassword"
                name="newPasswordRepeat"
                type="password"
                :errors="repeatPasswordError"
                :label="$t('core.page.profile.label.newPasswordRepeat')"
              />
              <p-col justify-end>
                <p-button @click="doResetPassword">
                  {{ $t('core.page.profile.button.doResetPassword') }}
                </p-button>
              </p-col>
              <p-request-alert v-model="requestError"></p-request-alert>
            </p-row>
          </p-container>
        </p-card>
      </p-col>
      <p-col xs12 md6>
        <p-card class="fill-height">
          <h3>{{ $t('page.profile.roles.title') }}</h3>
          <p-container fluid>
            <p-row dense>
              <p-table v-model="roles" :loading="isLoading" :columns="columns" dense></p-table>
            </p-row>
          </p-container>
        </p-card>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import UserSetPasswordRequestViewModel from '@glittr/frontend-core/src/services/v1/viewModel/resource/UserSetPasswordRequestViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    data: () => ({
      resetPassword: new UserSetPasswordRequestViewModel(),
      requestError: undefined as any,
      newPasswordError: undefined as undefined | string,
      repeatPasswordError: undefined as undefined | string,
      roles: [] as any,
      columns: [] as {}[],
      user: {} as any,
      isLoading: false,
      canChangePassword: false,
    }),
    async mounted() {
      this.isLoading = true;
      await this.$auth.refresh();
      this.canChangePassword = !Vue.$msalInstance;
      this.$set(this.$store.values, 'title', 'USER PROFILE');
      this.columns = [
        { value: this.$t('page.profile.roles.table.role'), field: 'label' },
        { value: this.$t('page.profile.roles.table.designation'), field: 'roleName' },
      ];
      this.user = await this.$auth.getUser();
      await this.getRoles();
      this.isLoading = false;
    },
    methods: {
      async getRoles() {
        const { claims } = this.$auth;
        const prefix = this.$config.values['cockpit-prefix'];

        Object.keys(claims).forEach((claimKey : string) => {
          if (this._.isSet(claimKey) && (claimKey.startsWith(prefix) || claimKey === 'role')) {
            const labelName = claimKey.replace(prefix, '');
            const role = { label: this.$tAlt(`page.profile.roles.label.${labelName[0].toLowerCase() + labelName.slice(1)}`, `${labelName[0].toLowerCase() + labelName.slice(1)}`), roleName: claims[claimKey] };
            this.roles.push(role);
          }
        });
      },
      async doLogoff() {
        try {
          await this.$auth.logout();
          Vue.$router.push('/_exterior/login');
        } catch (error) {
          this.requestError = `${error}`;
        }
      },
      async doResetPassword() {
        try {
          this.newPasswordError = undefined;
          this.repeatPasswordError = undefined;
          this.resetPassword.email = this.user.email!;
          // TODO: - can be validated through control
          if (!this.resetPassword.newPassword || this.resetPassword.newPassword.length < this.$config.values['auth-min-password-length']) {
            this.newPasswordError = this.$t('core.validation.minLength', this.$config.values['auth-min-password-length']);
            return;
          }
          if (this.resetPassword.newPassword !== this.resetPassword.repeatNewPassword) {
            this.repeatPasswordError = this.$t('core.validation.sameAs');
            return;
          }

          await this.$service.api.core.me.setPassword(this.resetPassword)
            .then(() => {
              this.doLogoff();
            });
        } catch (error) {
          this.requestError = error;
        }
      },
    },
  });
</script>
