<template>
  <p-container fluid fill-height class="page-agenda">
    <p-card color="surface-1" flat class="py-1 mb-2">
      <p-row dense align-center>
        <p-button dense tertiary @click="navigateInTime(-1)">
          <p-icon>chevron_left</p-icon>
        </p-button>
        <p-date-field
          :key="timeScale + timeRangeStart + timeRangeEnd"
          v-model="timeRangeStart"
          class="ix-7"
          label="Start"
          :unit="timeScale === 'Month' ? 'month' : 'date'"
          :display-formatter="dateDisplayFormatter"
          solo
        ></p-date-field>
        <p-button dense tertiary @click="navigateInTime(1)">
          <p-icon>chevron_right</p-icon>
        </p-button>
        <div class="iy-1">
          <p-divider class="mx-1" vertical></p-divider>
        </div>
        <p-button
          :secondary="timeScale !== 'Day'"
          :title="$t('page.scheduler.filter.dayView')"
          @click="changeTimeScale('Day')"
        >
          <p-icon>calendar_view_day</p-icon>
        </p-button>
        <p-button
          :secondary="timeScale !== 'Week'"
          :title="$t('page.scheduler.filter.weekView')"
          @click="changeTimeScale('Week')"
        >
          <p-icon>calendar_view_week</p-icon>
        </p-button>
        <div class="iy-1">
          <p-divider class="mx-1" vertical></p-divider>
        </div>
        <p-text-field
          v-model="search"
          :disabled="isLoading"
          clearable
          :label="$t('app.search')"
          solo
          class="ix-7"
          context-icon="search"
        ></p-text-field>
        <div class="iy-1">
          <p-divider vertical class="mx-1"></p-divider>
        </div>
        <p-select
          :key="groupFilterItems.length"
          v-model="groupId"
          :items="groupFilterItems"
          :label="$t('page.scheduler.group.label')"
          solo
          :class="{ 'ix-4': !groupId }"
          context-icon="filter_list"
          clearable
          :disabled="isLoading"
        ></p-select>
        <p-menu v-model="extendedFilterVisible">
          <template #activator>
            <p-button :dense="$media.isDesktop" tertiary>
              <p-icon>more_vert</p-icon>
            </p-button>
          </template>
          <p-card>
            <p-row column>
              <p-checkbox
                v-model="hideEmptyRows"
                :disabled="isLoading"
                :label="$t('page.scheduler.filter.hideEmptyRows')"
              ></p-checkbox>
              <p-divider></p-divider>
              <p-toggle v-model="showLessons" color="#f8de68" :label="$t('page.scheduler.lessons.title')"></p-toggle>
              <p-toggle v-model="showRooms" color="#a8a5ee" :label="$t('page.scheduler.rooms.title')"></p-toggle>
              <p-toggle
                v-model="showReferents"
                color="#9febb3"
                :label="$t('page.scheduler.referents.title')"
              ></p-toggle>
            </p-row>
          </p-card>
        </p-menu>
      </p-row>
    </p-card>
    <p-tabs v-model.number="selectedTab" color="focus-lighten-2" dense class="mb-2" @click="onTabClick">
      <p-tab :label="$t('page.scheduler.classes.title')">
        <scheduler-class
          v-model="timeRangeStart"
          :time-scale="timeScale"
          :group-id="groupId"
          :search="search"
          :hide-empty-rows="hideEmptyRows"
          :show-lessons="showLessons"
          :show-rooms="showRooms"
          :show-referents="showReferents"
          @update:group-filter-items="groupFilterItems = $event"
          @time-range="onTimeRangeChange"
        ></scheduler-class>
      </p-tab>
      <p-tab :label="$t('page.scheduler.referents.title')">
        <scheduler-referent
          v-model="timeRangeStart"
          :time-scale="timeScale"
          :group-id="groupId"
          :search="search"
          :hide-empty-rows="hideEmptyRows"
          :show-lessons="showLessons"
          :show-rooms="showRooms"
          :show-referents="showReferents"
          :group-filter-items.sync="groupFilterItems"
          @time-range="onTimeRangeChange"
        ></scheduler-referent>
      </p-tab>
      <p-tab :label="$t('page.scheduler.rooms.title')">
        <scheduler-room
          v-model="timeRangeStart"
          :time-scale="timeScale"
          :group-id="groupId"
          :search="search"
          :hide-empty-rows="hideEmptyRows"
          :show-lessons="showLessons"
          :show-rooms="showRooms"
          :show-referents="showReferents"
          :group-filter-items.sync="groupFilterItems"
          @time-range="onTimeRangeChange"
        ></scheduler-room>
      </p-tab>
    </p-tabs>
  </p-container>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { DayPilot } from 'daypilot-pro-vue';
  import dayjs from 'dayjs';
  import SchedulerClass from './scheduler-class.vue';
  import SchedulerReferent from './scheduler-referent.vue';
  import SchedulerRoom from './scheduler-room.vue';

  export default Vue.extend({
    auth: true,
    components: {
      SchedulerClass,
      // SchedulerLesson,
      SchedulerReferent,
      SchedulerRoom,
    },
    data: () => ({
      timeScale: 'Day',
      selectedTab: 0,
      timeRangeStart: Vue.$date.now().format('YYYY-MM-DD'),
      timeRangeEnd: Vue.$date.now().format('YYYY-MM-DD'),
      search: undefined as string | undefined,
      groupId: undefined as any,
      showLessons: true,
      showRooms: true,
      showReferents: true,
      hideEmptyRows: true,
      groupFilterItems: [] as any[],
      isLoading: false,
      extendedFilterVisible: false,
    }),
    watch: {
      hideEmptyRows() {
        this.$localStorage.set('scheduler-hideEmptyRows', this.hideEmptyRows);
      },
    },
    // mounted() {
    //   const tabId = Number.parseInt(this.$route.query.tab as string, 10);
    //   if (!Number.isNaN(tabId)) {
    //     this.selectedTab = tabId;
    //   }
    // },
    beforeMount() {
      if (!Vue.$service.v2.api.me.getAmIPlanningAdmin()) {
        this.$router.push('/_exterior/login');
      }

      this.hideEmptyRows = this.$localStorage.get('scheduler-hideEmptyRows') ?? true;
      const tabId = this.$route.params.tabId as string;
      if (tabId !== undefined && tabId !== null) {
        this.selectedTab = Number.parseInt(this.$route.params.tabId as string, 10);
      }
    },
    methods: {
      onTimeRangeChange(start: string, end: string, timeScale: string) {
        this.timeRangeEnd = end;
        this.timeRangeStart = start;
        this.timeScale = timeScale;
      },
      navigateInTime(amount: number) {
        const newStart = Vue.$date(this.timeRangeStart).add(amount, <dayjs.ManipulateType> this.timeScale).format('YYYY-MM-DD');
        this.timeRangeStart = newStart;
      },
      dateDisplayFormatter(userInput: string | undefined, value: string | undefined) {
        if (this.timeScale === 'Week') {
          const startTimeObj = this.$date(this.timeRangeStart);
          const endTimeObj = this.$date(this.timeRangeEnd);
          return `${startTimeObj.format('DD.MM.YY')} - ${endTimeObj.format('DD.MM.YY')}`;
        }
        if (this.timeScale === 'Day') {
          return this.$date(value).format('dd DD.MM.YYYY');
        }
        return this.$date(this.timeRangeStart).format('DD.MM.YYYY');
      },
      changeTimeScale(timeScale: string) {
        this.timeScale = timeScale;
      },
      onTabClick(tabId: number) {
        const query = this.$routerUtils.getQueryParams();
        this.$router.replace({ path: `/agogis/cockpit/scheduler/${tabId}`, query });
      },
    },
  });
</script>
