// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import TTObjectSchema from './tt-object-schema';

const schema = {
  $id: '/confirm-lessons-request-schema',
  type: 'object',
  required: ['selectedLessonIds', 'agogisUserId'],
  properties: {
    updateComment: {
      type: 'boolean',
    },
    comment: {
      ...TTObjectSchema,
    },
    updateAdminComment: {
      type: 'boolean',
    },
    adminComment: {
      type: 'string',
      nullable: true,
    },
    selectedLessonIds: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    agogisUserId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
