import Vue from 'vue';
import AuthenticationToken from '@glittr/frontend-core/src/plugins/auth/strategies/bearerToken/authenticationTokenResponse';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import User from '@glittr/frontend-core/src/plugins/auth/user';
import * as msal from '@azure/msal-browser';
import AuthenticationStrategy, { AuthRole } from '@glittr/frontend-core/src/plugins/auth/authenticationStrategy';
import JWTToken from '@glittr/frontend-core/src/plugins/auth/strategies/bearerToken/jwtToken';
import _ from '@glittr/frontend-core/src/utils/index';

export default class BearerTokenAuthStrategy extends AuthenticationStrategy {
  token?: AuthenticationToken;
  refreshPromise?: Promise<void> = undefined;
  user?: User;
  readonly tokenStorageKey: string = 'auth.token';

  refresh():Promise<void> {
    if (Vue.$msalInstance) {
      const config = ((Vue.$config.values as any)['identy-provider-settings'] as any).Microsoft;

      return (Vue.$msalInstance!.acquireTokenSilent({
        account: Vue.$msalInstance.getActiveAccount() || undefined,
        scopes: config.scopes,
        authority: config.authority,
        redirectUri: `${window.location.origin}`,
      })
        .then(() => this.innerrefresh()));
    }
    return this.innerrefresh();
  }
  innerrefresh() {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }
    this.refreshPromise = this.internalRefreshToken();
    this.refreshPromise.then(() => {
      this.refreshPromise = undefined;
    }).catch((error) => {
      this.refreshPromise = undefined;
    });
    return this.refreshPromise ?? Promise.resolve();
  }
  private async internalRefreshToken() {
    if (!this.token || !this.token.accessToken) {
      Vue.$log.info('🛂 [Auth] Access-token not set, redirecting to login');
      this.refreshPromise = undefined;
      this.redirectToLogin();
    } else {
      if (this.needsRefresh) {
        Vue.$log.info('🛂 [Auth] Access-token expired, refreshing');
      } else {
        Vue.$log.info('🛂 [Auth] Manual refresh of Access-token');
      }
      const accessToken = this.token.accessToken.token;
      const { refreshToken } = this.token;
      const refreshTokenApiPath = Vue.$config.values['auth-endpoints-refresh-token'] ?? (Vue.$config.values as any)['endpoints-refresh-token'] ?? 'core/Auth/RefreshToken';
      try {
        const response = await Vue.$service.post<ServiceResponse<AuthenticationToken>>(refreshTokenApiPath, {
          skipInterceptors: true,
          body: { accessToken, refreshToken },
        });
        this.token = response.data.result;
        this.save(this.token);
      } catch (error: any) {
        if (error && error.status === 401) {
          Vue.$log.info('🛂 [Auth] Access-token and refresh-token expired, redirecting to login');
          this.redirectToLogin();
        } else {
          Vue.$log.error('🛂 [Auth] Unable to refresh auth token, an error occurred.');
          Vue.$log.error(error);
          if (error && error.message) {
            throw new Error(error.message);
          }
          throw new Error(Vue.$t('core.error.network.errorRefreshingToken'));
        }
      }
    }
  }

  login(data: any) {
    if (data as msal.SilentRequest && Vue.$msalInstance) {
      return Vue.$msalInstance!.acquireTokenSilent(data as msal.SilentRequest)
        .then(async (result) => {
          const headers : Record<string, string> = {};
          // GEt Token after gettoken from MS
          const bearer = `Bearer ${result!.accessToken}`;
          headers.Authorization = bearer;
          headers['Content-Type'] = 'application/json';
          return new Promise<User | null>((resolve, reject) => {
            // TODO: This needs to be more configurable in general
            const tokenApiPath = Vue.$config.values['auth-endpoints-token'] ?? (Vue.$config.values as any)['endpoints-get-token'] ?? 'core/Auth/GetTokenMicrosoft';
            // eslint-disable-next-line no-promise-executor-return
            return Vue.$service.post<ServiceResponse<AuthenticationToken>>(tokenApiPath, {
              headers,
              body: {
                username: 'Microsoft',
                password: 'Microsoft',
              },
            })
              .then((response: ServiceResponse<AuthenticationToken>) => {
                this.token = response.data.result;
                this.token.accessToken = response.data.result.accessToken;
                this.token.refreshToken = response.data.result.refreshToken;
                this.save(this.token)
                  .then((saveResponse) => {
                    resolve(saveResponse);
                  })
                  .catch((error: any) => {
                    Vue.$log.error(error);
                    reject(error);
                  });
              })
              .catch((error: any) => {
                reject(error);
              });
          });
        });
    }
    const username = data.userName as string || data.username as string;
    const password = data.password as string;
    const shareId = data.shareId as string || data.shareid;

    return new Promise<User | null>((resolve, reject) => {
      // TODO: This needs to be more configurable in general
      const tokenApiPath = Vue.$config.values['auth-endpoints-token'] ?? (Vue.$config.values as any)['endpoints-get-token'] ?? 'core/Auth/GetToken';
      Vue.$service.post<ServiceResponse<AuthenticationToken>>(tokenApiPath, { body: { username, password, shareId }, writeValuesToConsole: false })
        .then((response: ServiceResponse<AuthenticationToken>) => {
          const token = response.data.result;
          this.save(token)
            .then((saveResponse) => {
              resolve(saveResponse);
            })
            .catch((error: any) => {
              Vue.$log.error(error);
              reject(error);
            });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  async logout() {
    await Vue.$msalInstance?.setActiveAccount(null);
    return new Promise<void>((resolve, reject) => {
      this.user = undefined;
      this.token = undefined;
      Vue.$localStorage.remove(this.tokenStorageKey);
      Vue.$fetch.defaults.headers.Authorization = undefined as any;
      Vue.$msalInstance = null;
      resolve();
    });
  }
  get roles(): AuthRole[] {
    if (!this.token) {
      // No roles
      return [];
    }
    const { jwt } = this;
    if (!jwt) {
      // No roles
      return [];
    }
    let roleArray = [jwt.role];
    if (_.isArray(jwt?.role)) {
      roleArray = jwt.role;
    }
    return roleArray;
  }

  get claims(): Record<string, string> {
    return this.jwt as Record<string, string>;
  }

  get jwt(): JWTToken | undefined {
    if (!this.token) {
      return undefined;
    }
    const jwt = this.token.accessToken.token.split('.')[1];
    const jwtDecodedJson = window.atob(jwt);
    return JSON.parse(jwtDecodedJson);
  }

  get isLoggedIn() {
    return _.isSet(this.token) && _.isSet(this.token?.accessToken);
  }

  /**
   * Token is expired or no token has been fetched yet,
   * Call refresh() to make sure the token is loaded correctly
   */
  get needsRefresh() {
    if (!this.token || !this.token.accessToken) {
      return true;
    }
    const expireOn = Vue.$date(this.token!.accessToken.expiresOn);
    if (expireOn === undefined) {
      return true;
    }
    const now = Vue.$date.now();
    return now.isAfter(expireOn);
  }

  async getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      Vue.$guards.waitForAppLoad().then(() => {
        // TODO: This needs to be more configurable in general
        const profileEndpoint = Vue.$config.values['auth-endpoints-user'] ?? (Vue.$config.values as any)['endpoints-get-me'] ?? 'core/Me';
        Vue.$service.get<ServiceResponse<User>>(profileEndpoint, { writeValuesToConsole: false })
          .then((response) => {
            const user = response.data.result;
            if (user !== null) {
              this.user = user;
              Vue.$log.info('👤 [AUTH] Loaded User');
              Vue.$log.debug(user);
              resolve(user);
            } else {
              throw new Error('Unable to load user, result from server was empty');
            }
          })
          .catch((error) => {
            Vue.$log.error(error);
            reject(error);
          });
      });
    });
  }

  load(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      this.token = Vue.$localStorage.get<AuthenticationToken>(this.tokenStorageKey) ?? undefined;
      if (this.token !== undefined) {
        this.setAuthenticationHeader('Bearer', this.token.accessToken.token);
        if (!this.needsRefresh) {
          this.getUser()
            .then((user) => {
              resolve(user);
            })
            .catch((error) => {
              Vue.$log.error(error);
              reject(error);
            });
        }
      }
    });
  }

  save(token: AuthenticationToken): Promise<User | null> {
    return new Promise<User | null>((resolve, reject) => {
      Vue.$localStorage.set(this.tokenStorageKey, token);
      this.load()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
