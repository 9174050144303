// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CockpitNotificationStateLookupModel from '../../model/cockpit-notification-state-lookup-model';
import CockpitNotificationStateLookupDTO from '../dto/cockpit-notification-state-lookup-dto';
import dtoSchema from '../schemas/cockpit-notification-state-lookup-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CockpitNotificationStateLookupModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CockpitNotificationStateLookupDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.sort = data.sort;
    }
  }
  /**
  * @type {int32}
  */
  id?: 0 | 1 | 2 | 3 | 4; // CockpitNotificationStateDTO
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string;
  /**
  * @type {int32}
  */
  sort?: number;

  static toModel(dto: DeepPartial<CockpitNotificationStateLookupDTO>): CockpitNotificationStateLookupModel;
  static toModel(dto: DeepPartial<CockpitNotificationStateLookupDTO> | undefined | null): CockpitNotificationStateLookupModel | undefined;
  static toModel(dto: DeepPartial<CockpitNotificationStateLookupDTO> | undefined | null): CockpitNotificationStateLookupModel | undefined {
    return dto ? new CockpitNotificationStateLookupModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CockpitNotificationStateLookupModel> | CockpitNotificationStateLookupModel): CockpitNotificationStateLookupDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      sort: unwrapped.sort,
    } as CockpitNotificationStateLookupDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CockpitNotificationStateLookupModel, can not map to CockpitNotificationStateLookupDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
