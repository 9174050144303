// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-widget-registration-marketing-questions-definition-request-schema',
  type: 'object',
  required: ['id'],
  properties: {
    id: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
