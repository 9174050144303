// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetSchedulerByClassesRequestModel from '../../model/get-scheduler-by-classes-request-model';
import GetSchedulerByClassesRequestDTO from '../dto/get-scheduler-by-classes-request-dto';
import dtoSchema from '../schemas/get-scheduler-by-classes-request-schema';

export default abstract class GetSchedulerByClassesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetSchedulerByClassesRequestDTO>) {
    super();
    if (data) {
      this.startTime = data.startTime;
      this.endTime = data.endTime;
      this.selectedResourceTypes = data.selectedResourceTypes;
    }
  }
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;
  /**
  */
  selectedResourceTypes?: number[];

  static toModel(dto: DeepPartial<GetSchedulerByClassesRequestDTO>): GetSchedulerByClassesRequestModel;
  static toModel(dto: DeepPartial<GetSchedulerByClassesRequestDTO> | undefined | null): GetSchedulerByClassesRequestModel | undefined;
  static toModel(dto: DeepPartial<GetSchedulerByClassesRequestDTO> | undefined | null): GetSchedulerByClassesRequestModel | undefined {
    return dto ? new GetSchedulerByClassesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetSchedulerByClassesRequestModel> | GetSchedulerByClassesRequestModel): GetSchedulerByClassesRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
      selectedResourceTypes: unwrapped.selectedResourceTypes,
    } as GetSchedulerByClassesRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetSchedulerByClassesRequestModel, can not map to GetSchedulerByClassesRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
