import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import _ from '@glittr/frontend-core/src/utils';
import SchedulerResourceViewModel from './SchedulerResourceViewModel';

export interface SchedulerFilterDTO {

}

export default class SchedulerFilterViewModel extends BaseModel<SchedulerFilterDTO> {
  events = [] as SchedulerResourceViewModel[];

  local = {
    timeScale: undefined as string | undefined,
    search: undefined as string | undefined,
    hideEmptyRows: false,
    groupId: undefined as undefined | number,
  };

  endpoint = {
    showLessons: true,
    showRooms: true,
    showReferents: true,
    showAppointments: true,
    startTime: undefined as undefined | string,
    endTime: undefined as undefined | string,
  };

  get selectedResourceTypes() {
    const showLessons = [this.endpoint.showLessons ? 2 : undefined];
    const showRooms = [this.endpoint.showRooms ? 3 : undefined];
    const showAppointments = [this.endpoint.showAppointments ? 4 : undefined];
    const showReferents = [this.endpoint.showReferents ? 5 : undefined];
    return [...showLessons, ...showRooms, ...showAppointments, ...showReferents] as number[];
  }

  public fromQuery(queries: Record<string, string>): void {
    if (queries.showLessons !== undefined) {
      this.endpoint.showLessons = queries.showLessons === 'true';
    }
    if (queries.showRooms !== undefined) {
      this.endpoint.showRooms = queries.showRooms === 'true';
    }
    if (queries.showReferents !== undefined) {
      this.endpoint.showReferents = queries.showReferents === 'true';
    }
    if (queries.showAppointments !== undefined) {
      this.endpoint.showAppointments = queries.showAppointments === 'true';
    }
    if (queries.showLessons !== undefined) {
      this.local.timeScale = queries.timeScale;
    }
    if (queries.startTime !== undefined) {
      this.endpoint.startTime = queries.startTime;
    }
    if (queries.endTime !== undefined) {
      this.endpoint.endTime = queries.endTime;
    }
  }

  public toQuery(): Record<string, string> {
    const queries = {} as Record<string, string>;
    queries.showLessons = this.endpoint?.showLessons?.toString();
    queries.showRooms = this.endpoint?.showRooms?.toString();
    queries.showReferents = this.endpoint?.showReferents?.toString();
    queries.showAppointments = this.endpoint?.showAppointments?.toString();
    queries.timeScale = this.local?.timeScale?.toString() ?? '';
    queries.startTime = this.endpoint?.startTime?.toString() ?? '';
    queries.endTime = this.endpoint?.endTime?.toString() ?? '';
    return queries;
  }
}
