<template>
  <p-container>
    <p-progress-linear v-if="isLoading" indeterminate></p-progress-linear>
    <p-alert type="error" :value="error" dismissable>
      {{ error }}
    </p-alert>
    <h1>{{ $t('navigation.modulePlanning.name') }}</h1>
    <p-row>
      <p-col xs12 md6 lg2>
        <agogis-year-lookup v-model="selectedYear" :label="$t('page.modulePlanning.label.year')" @input="onYearChange()"></agogis-year-lookup>
      </p-col>
      <p-col xs12 md6 lg2>
        <p-text-field v-model="filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search" @input="onFilterInput"></p-text-field>
      </p-col>
      <p-col xs12 md6 lg4>
        <agogis-module-lookup
          v-model="filter.moduleId"
          :year="selectedYear"
          clearable
          :label="$t('page.modulePlanning.label.module')"
          :show-prototype-class="true"
          @input="onFilterInput"
        ></agogis-module-lookup>
      </p-col>
      <p-col xs12 md6 lg4>
        <agogis-class-lookup
          v-model="filter.classId"
          :year="selectedYear"
          clearable
          :label="$t('page.modulePlanning.label.class')"
          :show-prototype-class="true"
          @input="onFilterInput"
        ></agogis-class-lookup>
      </p-col>
    </p-row>
    <p-list-view
      ref="ordersList"
      v-model="availableModules"
      :service-function="populateData"
      can-select
      multi-select
      :pagination.sync="pagination"
      :columns="columns"
    >
      <template slot="table-actions">
        <p-row>
          <p-col justify-start shrink>
            <p-button @click="onFilterInput">
              <p-icon>refresh</p-icon>
            </p-button>
          </p-col>
          <p-col justify-start shrink>
            <p-button @click="completePlanning()">
              {{ $t('page.modulePlanning.button.completePlanning') }}
            </p-button>
          </p-col>
          <p-col justify-start shrink>
            <p-button
              class="export-button"
              secondary
              :title="$t('page.modulePlanning.label.export')"
              :loading="isExporting"
              :disabled="isExporting || availableModules.length == 0 || isLoading"
              @click="exportList(filter)"
            >
              <p-icon>description</p-icon> {{ $t('page.modulePlanning.label.export') }}
            </p-button>
          </p-col>
          <p-col justify-end>
            <p-button @click="sendNotification()">
              <p-icon left>
                mail
              </p-icon>
              {{ $t('page.modulePlanning.button.sendRequests') }}
            </p-button>
          </p-col>
        </p-row>
      </template>
      <template #completed="{ entry }">
        <p-row align-center justify-center>
          <p-checkbox v-model="entry.id" disabled></p-checkbox>
        </p-row>
      </template>
      <template #entry-actions="{ entry }">
        <p-button @click="gotoDetail(entry.values)">
          <p-icon>search</p-icon>
        </p-button>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
  import PListView from '@glittr/frontend-core/src/components/p-list-view/p-list-view.vue';
  import ExportPrototypeModulesListRequestViewModel from '@/src/services/v1/viewModel/request/AgogisCockpit/ExportPrototypeModulesListRequestViewModel';
  import LecturePrototypeModuleListResourceViewModel from '../../../../../services/v1/viewModel/resource/LecturePrototypeModuleListResourceViewModel';
  import GetPrototypeModulesListRequestViewModel from '../../../../../services/v1/viewModel/request/AgogisCockpit/GetPrototypeModulesListRequestViewModel';

  export default Vue.extend({
    auth: true,
    data: () => ({
      availableModules: [] as LecturePrototypeModuleListResourceViewModel[],
      pagination: {
        totalCount: undefined as any,
        pageSize: undefined as any,
        currentPage: undefined as any,
        totalPages: undefined as any,
        previousPage: undefined as any,
        nextPage: undefined as any,
      } as ResponsePagingInfo,
      isLoading: false,
      isExporting: false,
      error: undefined as any,
      selectedYear: undefined as any,
      filter: new GetPrototypeModulesListRequestViewModel().fromModel({}),
      list: {},
      columns: [] as {}[],
      extendedFilterVisible: false,
    }),
    watch: {
      pagination: {
        deep: true,
        handler() { this.writeRequestToQuery(); },
      },
    },
    async beforeMount() {
      this.setQueryValuesToFilter();
      this.getQueryFromRequest();
    },
    async mounted() {
      this.columns = [
        {
          value: this.$t('page.modulePlanning.table.module'), field: 'moduleName', textAlign: 'left',
        },
        {
          value: this.$t('page.scheduler.columns.class'), field: 'className', textAlign: 'left',
        },
        {
          value: this.$t('page.modulePlanning.table.start'), field: 'moduleStartDate', textAlign: 'left', format: (value:any) => this.$format.localDate(value),
        },
        {
          value: this.$t('page.modulePlanning.table.end'), field: 'moduleEndDate', textAlign: 'left', format: (value:any) => this.$format.localDate(value),
        },
        {
          value: this.$t('page.modulePlanning.table.numberOfRequiredReferents'), field: 'countOfRequiredLecturers', textAlign: 'right',
        },
        {
          value: this.$t('page.modulePlanning.table.numberOfRequestedClasses'), field: 'countOfRequestedClasses', textAlign: 'right',
        },
        {
          value: this.$t('page.modulePlanning.table.numberOfProvisionalClasses'), field: 'countOfProvisionalClasses', textAlign: 'right',
        },
        {
          value: this.$t('page.modulePlanning.table.numberOfReservedClasses'), field: 'countOfReservedClasses', textAlign: 'right',
        },
        {
          value: this.$t('page.modulePlanning.table.numberOfConfirmedClasses'), field: 'countOfConfirmedClasses', textAlign: 'right',
        },
        {
          value: this.$t('page.modulePlanning.table.completed'), field: 'completed', textAlign: 'center',
        },
      ];
    },
    methods: {
      gotoDetail(entry: { moduleId: any, classId: any, classStartYear: any }) {
        this.$router.push({
          path: '/agogis/cockpit/schedule-planning/modules/detail',
          query: {
            moduleid: entry.moduleId,
            classid: entry.classId,
            classyear: entry.classStartYear,
          },
        });
      },
      defaultYear(): number {
        const date = Vue.$date(`${Vue.$date.now().year()}-07-31`);
        if (Vue.$date.now() > date) {
          return Vue.$date.now().year() + 1;
        }
        return Vue.$date.now().year();
      },
      refresh() {
        const listView = this.$refs.ordersList as InstanceType<typeof PListView>;
        listView.populateList();
      },
      getQueryFromRequest() {
        const queryParams = new GetPrototypeModulesListRequestViewModel();
        queryParams.fromQuery(this.$routerUtils.getQueryParams());
        this.filter.searchCriteria = queryParams.searchCriteria;
        this.filter.classId = queryParams.classId;

        this.pagination.currentPage = queryParams['paging.CurrentPage']!;
        this.pagination.pageSize = queryParams['paging.PageSize']!;
      },
      clearAdvancedFilter() {
        this.filter.classId = undefined as number | undefined;
      },
      completePlanning() {
        this.$dialog.notify('Not implemented', 'This function is not implemented yet');
      },
      sendNotification() {
        this.$dialog.notify('Not implemented', 'This function is not implemented yet');
      },
      setQueryValuesToFilter() {
        const queryParams = this.$routerUtils.getQueryParams();
        this.selectedYear = this.selectedYear || +(queryParams.classyear || this.defaultYear());
        this.filter.fromQuery(queryParams);
      },
      onYearChange() {
        this.clearAdvancedFilter();
        this.onFilterInput();
      },
      onFilterInput() {
        this.pagination.currentPage = 1;
        this.$debounce(() => {
          this.writeRequestToQuery();
          this.refresh();
        }, 500, this)();
      },
      writeRequestToQuery() {
        this.filter['paging.CurrentPage'] = this.pagination.currentPage;
        this.filter['paging.PageSize'] = this.pagination.pageSize;
        this.filter['paging.IncludeCount'] = true;
        this.$routerUtils.updateQueryParams({ ...this.filter.toQuery(), classyear: this.selectedYear }, { preserve: false });
      },
      async populateData(data: any) {
        const userId = await this.$service.v2.api.me.getUserId();
        this.filter.userId = userId;
        this.filter['paging.CurrentPage'] = data.query['paging.CurrentPage'];
        this.filter['paging.PageSize'] = data.query['paging.PageSize'];
        this.filter['paging.IncludeCount'] = true;
        this.selectedYear = this.selectedYear || this.defaultYear();
        const response = await this.$service.api.agogisCockpit.getPrototypeModulesList(this.selectedYear, this.filter); // zusätzliches attribut isprototype = true
        response.items = response.items.map((i) => ({ ...i.toDTO() })) as any[];
        return response;
      },
      async exportList(filter: GetPrototypeModulesListRequestViewModel) {
        this.isExporting = true;
        const response = await Vue.$service.api.agogisCockpit.exportPrototypeModulesList(this.selectedYear, new ExportPrototypeModulesListRequestViewModel().fromModel(filter));
        const now = Vue.$date.now().format('YYYYMMDD-HHmmss');
        Vue.$service.utils.downloadFile(`${this.selectedYear}-Planingmodules-${now}.xlsx`, response);
        this.isExporting = false;
      },

    },
  });
</script>
