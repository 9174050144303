import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import Vue from 'vue';
import LectureLessonListModelBase from '../_generated/modelBases/lecture-lesson-list-model-base';
import LectureLessonBookingActionModel from './lecture-lesson-booking-action-model';

export default class LectureLessonListModel extends LectureLessonListModelBase {
  onSelectionChanged = new DataEvent<[this]>();
  items: DataRecord[] = [];

  get lecturerStatusCaption() {
    return this.lecturerStatus?.name ?? '-';
  }

  isBookingActionPossible(action: LectureLessonBookingActionModel) {
    // Bit mask shenanigans
    // eslint-disable-next-line no-bitwise
    return ((this.bookingAction ?? 0) & action) === action;
  }

  get canBeAssigned() {
    return this.isBookingActionPossible(LectureLessonBookingActionModel.Assign);
    // return this.isNotYetAssigned || canConfirm || this.lecturerId !== Vue.$service.api.me.getAgogisUserId();
  }

  get canBeConfirmed() {
    return this.isBookingActionPossible(LectureLessonBookingActionModel.Confirm);
    // return this.isNotYetAssigned || canConfirm || this.lecturerId !== Vue.$service.api.me.getAgogisUserId();
  }

  get canBeDeclined() {
    return this.isBookingActionPossible(LectureLessonBookingActionModel.Decline);
    // return canDecline && this.lecturerId === Vue.$service.api.me.getAgogisUserId();
  }

  get hasNoBookingAction() {
    return (this.bookingAction ?? 0) === LectureLessonBookingActionModel.None;
  }

  get lecturerFullName() {
    if (!this.lecturerFirstName && !this.lecturerLastName) {
      return undefined;
    } if (!this.lecturerFirstName) {
      return this.lecturerLastName;
    } if (!this.lecturerLastName) {
      return this.lecturerFirstName;
    }
    return `${this.lecturerLastName} ${this.lecturerFirstName}`;
  }

  get timeOfDay() {
    const startDate = Vue.$date(this.startTime);
    const endDate = Vue.$date(this.endTime);
    if (endDate.diff(startDate, 'days') === 0) {
      if (endDate.hour() > 12 && startDate.hour() < 12) {
        return Vue.$t('page.lesson.label.timeOfDay.fullDay');
      }
      return (startDate.hour() >= 12 ? Vue.$t('page.lesson.label.timeOfDay.afternoon') : Vue.$t('page.lesson.label.timeOfDay.morning'));
    }
    return Vue.$t('page.lesson.label.timeOfDay.multipleDays');
  }
}
