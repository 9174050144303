// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LectureLessonStatusResourceListResultViewModel from '../../viewModel/resource/LectureLessonStatusResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getLectureLessonStates(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lecture-lesson-states'] ?? 'LectureLessonStatus';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonStatusResourceListResultViewModel().fromDTO(dto);
  },
});
