export default class BookingCommentComponentModel {
  comment:string|undefined = undefined;
  label:string = '';
  isReadOnly:boolean = true;
  canEdit:boolean = false;

  initialize(label:string, isReadOnly: boolean, comments: string[]) {
    this.isReadOnly = isReadOnly;
    this.label = label;

    const commentsDistincted = [...new Set(comments.map((x) => x ?? ''))];
    this.canEdit = commentsDistincted.length === 1 && !isReadOnly;
    this.comment = this.canEdit ? commentsDistincted[0] : undefined;
  }
  updateComments(comments: string[]) {
    const commentsDistincted = [...new Set(comments.map((x) => x ?? ''))];
    this.canEdit = commentsDistincted.length === 1 && !this.isReadOnly;
    this.comment = this.canEdit ? commentsDistincted[0] : undefined;
  }
}
