// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ApiPagingRequestViewModel from '../../../../viewModel/resource/ApiPagingRequestViewModel';
import WidgetSearchClassRequestViewModel from '../../../../viewModel/resource/WidgetSearchClassRequestViewModel';
import WidgetSearchClassRequestDTO from '../../dto/resource/WidgetSearchClassRequestDTO';

export default abstract class WidgetSearchClassRequestModel extends BaseModel<WidgetSearchClassRequestDTO> {
  /**
  */
  get searchCriteria() { return this.dto.searchCriteria; }
  set searchCriteria(value) { this.dto.searchCriteria = value; }
  /**
  */
  get paging() { return this.getObjectModel(() => this.dto.paging, ApiPagingRequestViewModel)!; }
  set paging(value) { this.setObjectModel(() => this.dto.paging, value); }
  /**
  */
  get toccoFilter() { return this.dto.toccoFilter; }
  set toccoFilter(value) { this.dto.toccoFilter = value; }
  /**
  */
  get searchTag() { return this.dto.searchTag; }
  set searchTag(value) { this.dto.searchTag = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.searchCriteria = qs.searchcriteria;
    this.dto.toccoFilter = qs.toccofilter;
    this.dto.searchTag = qs.searchtag;
  }
}
