/* eslint-disable no-bitwise */

// eslint-disable-next-line no-shadow
enum LectureLessonBookingActionModel {
  None = 0,
  Assign = 1 << 0,
  Confirm = 1 << 1,
  Decline = 1 << 2,
}

export default LectureLessonBookingActionModel;
