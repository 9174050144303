// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetClassesRequestModel from '../../model/get-classes-request-model';
import LookupYearsRequestModel from '../../model/lookup-years-request-model';
import LookupLecturerClassesRequestModel from '../../model/lookup-lecturer-classes-request-model';
import ClassListPagingResultModel from '../../model/class-list-paging-result-model';
import Int32LookupListResultModel from '../../model/int32-lookup-list-result-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getClasses(request: GetClassesRequestModel, config?: RequestConfig) {
    const requestDTO = GetClassesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-classes'] ?? 'Classes';
    const response = await service.get<any>(endpointPath, {
      query: {
        classStartYear: requestDTO.classStartYear,
        periodFrom: requestDTO.periodFrom,
        periodTo: requestDTO.periodTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ClassListPagingResultModel.toModel(dto));
  },
  /**  */
  async lookupYears(request: LookupYearsRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-years'] ?? 'Classes/Years/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupListResultModel.toModel(dto));
  },
  /**  */
  async lookupLecturerClasses(request: LookupLecturerClassesRequestModel, config?: RequestConfig) {
    const requestDTO = LookupLecturerClassesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-lecturer-classes'] ?? 'Classes/Lookup/ClassYear/{classYear}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${requestDTO.classYear}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
});
