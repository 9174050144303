// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import LectureWorkloadSummaryStatusItemResourceViewModel from '../../../../viewModel/resource/LectureWorkloadSummaryStatusItemResourceViewModel';
import LectureWorkloadSummaryStatusItemResourceDTO from '../../dto/resource/LectureWorkloadSummaryStatusItemResourceDTO';

export default abstract class LectureWorkloadSummaryStatusItemResourceModel extends BaseModel<LectureWorkloadSummaryStatusItemResourceDTO> {
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {double}
  */
  get totalDays() { return this.dto.totalDays; }
  set totalDays(value) { this.dto.totalDays = value; }
  /**
  * @type {double}
  */
  get totalDaysTT() { return this.dto.totalDaysTT; }
  set totalDaysTT(value) { this.dto.totalDaysTT = value; }
  /**
  * @type {int64}
  */
  get statusId() { return this.dto.statusId; }
  set statusId(value) { this.dto.statusId = value; }
  /**
  */
  get htmlColor() { return this.dto.htmlColor; }
  set htmlColor(value) { this.dto.htmlColor = value; }
  /**
  */
  get isIncludedInWorkload() { return this.dto.isIncludedInWorkload; }
  set isIncludedInWorkload(value) { this.dto.isIncludedInWorkload = value; }
  /**
  */
  get isInOwnClass() { return this.dto.isInOwnClass; }
  set isInOwnClass(value) { this.dto.isInOwnClass = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.totaldays);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalDays = value;
    value = Number.parseFloat(qs.totaldaystt);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalDaysTT = value;
    value = Number.parseFloat(qs.statusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.statusId = value;
    this.dto.htmlColor = qs.htmlcolor;
    value = qs.isIncludedInWorkload === 'true' ? true : qs.isincludedinworkload;
    value = qs.isIncludedInWorkload === 'false' ? false : undefined;
    this.dto.isIncludedInWorkload = value;
    value = qs.isInOwnClass === 'true' ? true : qs.isinownclass;
    value = qs.isInOwnClass === 'false' ? false : undefined;
    this.dto.isInOwnClass = value;
  }
}
