// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ModulePlanningLecturerModel from '../../model/module-planning-lecturer-model';
import ModulePlanningLecturerDTO from '../dto/module-planning-lecturer-dto';
import dtoSchema from '../schemas/module-planning-lecturer-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ModulePlanningLecturerModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ModulePlanningLecturerDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.fullName = data.fullName ?? undefined;
      this.lessonAssignmentId = data.lessonAssignmentId;
      this.assignedLessonId = data.assignedLessonId;
      this.assignedClassId = data.assignedClassId;
      this.lessonAssignmentStatusId = data.lessonAssignmentStatusId;
      this.lessonAssignmentStatusName = data.lessonAssignmentStatusName ?? undefined;
      this.lecturerAssignmentComment = data.lecturerAssignmentComment ?? undefined;
      this.lecturerAssignmentAdminComment = data.lecturerAssignmentAdminComment ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  fullName?: string;
  /**
  * @type {int64}
  */
  lessonAssignmentId?: number;
  /**
  * @type {int64}
  */
  assignedLessonId?: number;
  /**
  * @type {int64}
  */
  assignedClassId?: number;
  /**
  * @type {int64}
  */
  lessonAssignmentStatusId?: number;
  /**
  */
  lessonAssignmentStatusName?: string;
  /**
  */
  lecturerAssignmentComment?: string;
  /**
  */
  lecturerAssignmentAdminComment?: string;

  static toModel(dto: DeepPartial<ModulePlanningLecturerDTO>): ModulePlanningLecturerModel;
  static toModel(dto: DeepPartial<ModulePlanningLecturerDTO> | undefined | null): ModulePlanningLecturerModel | undefined;
  static toModel(dto: DeepPartial<ModulePlanningLecturerDTO> | undefined | null): ModulePlanningLecturerModel | undefined {
    return dto ? new ModulePlanningLecturerModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ModulePlanningLecturerModel> | ModulePlanningLecturerModel): ModulePlanningLecturerDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      fullName: unwrapped.fullName,
      lessonAssignmentId: unwrapped.lessonAssignmentId,
      assignedLessonId: unwrapped.assignedLessonId,
      assignedClassId: unwrapped.assignedClassId,
      lessonAssignmentStatusId: unwrapped.lessonAssignmentStatusId,
      lessonAssignmentStatusName: unwrapped.lessonAssignmentStatusName,
      lecturerAssignmentComment: unwrapped.lecturerAssignmentComment,
      lecturerAssignmentAdminComment: unwrapped.lecturerAssignmentAdminComment,
    } as ModulePlanningLecturerDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ModulePlanningLecturerModel, can not map to ModulePlanningLecturerDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
