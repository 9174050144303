// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetStatesRequestModel from '../../model/set-states-request-model';
import SetStatesRequestDTO from '../dto/set-states-request-dto';
import dtoSchema from '../schemas/set-states-request-schema';

export default abstract class SetStatesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetStatesRequestDTO>) {
    super();
    if (data) {
      this.state = data.state;
      this.ids = data.ids ?? undefined;
      this.information = data.information ?? undefined;
    }
  }
  /**
  * @type {int32}
  */
  state?: 0 | 1 | 2 | 3 | 4; // CockpitNotificationStateDTO
  /**
  */
  ids?: number[];
  /**
  */
  information?: string;

  static toModel(dto: DeepPartial<SetStatesRequestDTO>): SetStatesRequestModel;
  static toModel(dto: DeepPartial<SetStatesRequestDTO> | undefined | null): SetStatesRequestModel | undefined;
  static toModel(dto: DeepPartial<SetStatesRequestDTO> | undefined | null): SetStatesRequestModel | undefined {
    return dto ? new SetStatesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetStatesRequestModel> | SetStatesRequestModel): SetStatesRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      state: unwrapped.state,
      ids: unwrapped.ids,
      information: unwrapped.information,
    } as SetStatesRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SetStatesRequestModel, can not map to SetStatesRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
