// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetClassInformationModel from '../../model/widget-class-information-model';
import WidgetClassInformationDTO from '../dto/widget-class-information-dto';
import dtoSchema from '../schemas/widget-class-information-schema';
import WidgetClassFaqModel from '../../model/widget-class-faq-model';

export default abstract class WidgetClassInformationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetClassInformationDTO>) {
    super();
    if (data) {
      this.classTypeKey = data.classTypeKey ?? undefined;
      this.title = data.title ?? undefined;
      this.subtitle = data.subtitle ?? undefined;
      this.wordSubtitle = data.wordSubtitle ?? undefined;
      this.summary = data.summary ?? undefined;
      this.faq = data.faq?.map((i) => WidgetClassFaqModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  classTypeKey?: string;
  /**
  */
  title?: string;
  /**
  */
  subtitle?: string[];
  /**
  * @readonly
  */
  wordSubtitle?: string;
  /**
  */
  summary?: string;
  /**
  */
  faq?: WidgetClassFaqModel[];

  static toModel(dto: DeepPartial<WidgetClassInformationDTO>): WidgetClassInformationModel;
  static toModel(dto: DeepPartial<WidgetClassInformationDTO> | undefined | null): WidgetClassInformationModel | undefined;
  static toModel(dto: DeepPartial<WidgetClassInformationDTO> | undefined | null): WidgetClassInformationModel | undefined {
    return dto ? new WidgetClassInformationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetClassInformationModel> | WidgetClassInformationModel): WidgetClassInformationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      classTypeKey: unwrapped.classTypeKey,
      title: unwrapped.title,
      subtitle: unwrapped.subtitle,
      wordSubtitle: unwrapped.wordSubtitle,
      summary: unwrapped.summary,
      faq: unwrapped.faq,
    } as WidgetClassInformationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetClassInformationModel, can not map to WidgetClassInformationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
