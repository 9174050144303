// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPrototypeModulesListRequestViewModel from '../../viewModel/request/AgogisCockpit/GetPrototypeModulesListRequestViewModel';
import ExportPrototypeModulesListRequestViewModel from '../../viewModel/request/AgogisCockpit/ExportPrototypeModulesListRequestViewModel';
import GetLectureLessonHistoryPagedListRequestViewModel from '../../viewModel/request/AgogisCockpit/GetLectureLessonHistoryPagedListRequestViewModel';
import LecturePrototypeModuleListResourcePagingResultViewModel from '../../viewModel/resource/LecturePrototypeModuleListResourcePagingResultViewModel';
import ModulePlanningOverviewResourceViewModel from '../../viewModel/resource/ModulePlanningOverviewResourceViewModel';
import LecturerWorkloadSummaryResourceViewModel from '../../viewModel/resource/LecturerWorkloadSummaryResourceViewModel';
import LectureLessonHistoryListResourcePagingResultViewModel from '../../viewModel/resource/LectureLessonHistoryListResourcePagingResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getPrototypeModulesList(year: number, request: GetPrototypeModulesListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-prototype-modules-list'] ?? 'agogisCockpit/classYears/{year}/PrototypeModules';
    endpointPath = endpointPath.replace(/\{year\}/g, `${year}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        classId: requestDTO.classId,
        classIds: requestDTO.classIds,
        moduleId: requestDTO.moduleId,
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LecturePrototypeModuleListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async exportPrototypeModulesList(year: number, request: ExportPrototypeModulesListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-export-prototype-modules-list'] ?? 'agogisCockpit/classYears/{year}/PrototypeModules/Export';
    endpointPath = endpointPath.replace(/\{year\}/g, `${year}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        classId: requestDTO.classId,
        classIds: requestDTO.classIds,
        moduleId: requestDTO.moduleId,
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
  /**  */
  async getPrototypeModuleOverview(classId: number, moduleId: number, year: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-prototype-module-overview'] ?? 'agogisCockpit/classYears/{year}/PrototypeModules/Classes/{classId}/Modules/{moduleId}/Overview';
    endpointPath = endpointPath.replace(/\{classId\}/g, `${classId}`);
    endpointPath = endpointPath.replace(/\{moduleId\}/g, `${moduleId}`);
    endpointPath = endpointPath.replace(/\{year\}/g, `${year}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ModulePlanningOverviewResourceViewModel().fromDTO(dto);
  },
  /**  */
  async lookupWorkloadSummary(id: number, year: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-workload-summary'] ?? 'agogisCockpit/classYears/{year}/Lecturers/{id}/WorkloadSummary';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{year\}/g, `${year}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LecturerWorkloadSummaryResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getLectureLessonHistoryPagedList(request: GetLectureLessonHistoryPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lecture-lesson-history-paged-list'] ?? 'agogisCockpit/AgogisCockpit';
    const response = await service.get<any>(endpointPath, {
      query: {
        lectureLessonId: requestDTO.lectureLessonId,
        lecturerId: requestDTO.lecturerId,
        from: requestDTO.from,
        to: requestDTO.to,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonHistoryListResourcePagingResultViewModel().fromDTO(dto);
  },
});
