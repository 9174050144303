import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Int64LookupModel from '@glittr/frontend-core/src/services/v2/model/int64-lookup-model';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetMeRequestModel from '@glittr/frontend-core/src/services/v2/model/get-me-request-model';
import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import DataRecord, { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource, { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
import LecturerBookingSummaryView from './lecturer-booking-summary-view';
import LookupLecturerClassesRequestDTO from '../../../../../services/v2/_generated/dto/lookup-lecturer-classes-request-dto';
import LookupLecturerClassesRequestModel from '../../../../../services/v2/model/lookup-lecturer-classes-request-model';
import LookupEventTypesRequestModel from '../../../../../services/v2/model/lookup-event-types-request-model';
import GetLectureLessonStatesRequestModel from '../../../../../services/v2/model/get-lecture-lesson-states-request-model';
import LectureLessonListModel from '../../../../../services/v2/model/lecture-lesson-list-model';
import SearchLessonsRequestModel from '../../../../../services/v2/model/search-lessons-request-model';
import ModuleListResourceModel from '../../../../../services/v2/model/module-list-resource-model';
import LookupLecturerModulesRequestModel from '../../../../../services/v2/model/lookup-lecturer-modules-request-model';
import LookupLecturerModulesRequestDTO from '../../../../../services/v2/_generated/dto/lookup-lecturer-modules-request-dto';
import SearchLessonByModuleRequestModel from '../../../../../services/v2/model/search-lesson-by-module-request-model';
import SearchLessonByModuleRequestDTO from '../../../../../services/v2/_generated/dto/search-lesson-by-module-request-dto';
import SearchLessonsRequestDTO from '../../../../../services/v2/_generated/dto/search-lessons-request-dto';
import ExportLessonsRequestDTO from '../../../../../services/v2/_generated/dto/export-lessons-request-dto';
import ExportLessonsRequestModel from '../../../../../services/v2/model/export-lessons-request-model';

export type LessonListDisplayType = 'All' | 'GroupedByModule';
export interface IPageFilter {
  classYear: number,
  lecturerId: number | undefined,
  lectureLessonStatusIds: number[] | undefined,
  eventTypeIds: number[] | undefined,
  searchCriteria: string | undefined,
  classIds: number[] | undefined,
  primaryLeadLecturerId: number | undefined,
}
export default class LessonListPage extends PageBase {
  filter: IPageFilter = {
    classYear: this.defaultYear(),
    lecturerId: undefined,
    lectureLessonStatusIds: [],
    eventTypeIds: [],
    searchCriteria: '',
    classIds: [],
    primaryLeadLecturerId: undefined,
  };

  moduleIds: number[] = [];

  classes: Int64LookupModel[] = [];

  eventTypes: Int64LookupModel[] = [];

  lectureLessonStates: Int64LookupModel[] = [];

  isLoading = true;

  isExporting = false;

  isPlanningAdmin = false;

  canEditCommentTT = false;

  isPrototype = false;

  isAssignToLessonsDrawerVisible = true;

  isAssignToLessonsDrawerMinimized = true;

  filterChangeEvent = new DataEvent<[SearchLessonsRequestModel]>();

  lessonSelectionChangeEvent = new DataEvent<[DataRecord<LectureLessonListModel>[]]>();

  lecturerBookingSummary = new LecturerBookingSummaryView(this.filterChangeEvent, this.lessonSelectionChangeEvent);

  currentUserId: number = -1;

  isInitialized = false;

  lastSearchCriteria = '';

  modulesDataSource = new DataSource({
    selectCommand: () => this.loadModules(this.filter, this.isPrototype),

    noRefreshOnFilterChange: true,
  });

  allLessonsDataSource = new DataSource({
    selectCommand: () => this.loadAllLessons(this.filter, this.isPrototype),

    noRefreshOnFilterChange: true,

  });

  selectedLessons: DataRecord<LectureLessonListModel>[] | undefined;

  defaultYear(): number {
    const date = Vue.$date(`${Vue.$date.now().year()}-07-31`);
    if (Vue.$date.now() > date) {
      return Vue.$date.now().year() + 1;
    }
    return Vue.$date.now().year();
  }

  async initialize(): Promise<void> {
    this.currentUserId = (await Vue.$service.v2.api.me.getMe({} as GetMeRequestModel)).data.id!;
    this.isPlanningAdmin = await Vue.$service.v2.api.me.getAmIPlanningAdmin();
    this.canEditCommentTT = this.isPlanningAdmin || await Vue.$service.v2.api.me.getAmILeadLecturer();
    this.isPrototype = true;
    const filter: IPageFilter = {
      ...this.toIPageFilter(Vue.$routerUtils.getQueryParams() as any, this.isPrototype, this.currentUserId),
    };
    this.moduleIds = this.toSelectedModulesIds(Vue.$routerUtils.getQueryParams() as any);
    this.allLessonsDataSource.paging = { ...this.allLessonsDataSource.paging, ...this.toPagination(Vue.$routerUtils.getQueryParams() as any) };
    if (!this.isPlanningAdmin) {
      filter.lecturerId = Vue.$service.v2.api.me.getAgogisUserId();
    }
    await this.refresh(this, filter);
    this.isInitialized = true;
  }

  // DATA LOADERS
  async updateLookupLecturerClasses(filter: IPageFilter) {
    const request = new LookupLecturerClassesRequestModel(this.toRequestLookupLecturerClasses(filter, this.isPrototype, this.currentUserId));
    this.classes = (await Vue.$service.v2.api.classes.lookupLecturerClasses(request)).items.map((x) => x.data);
  }

  async updateLookupEventTypes(filter: IPageFilter) {
    const request: LookupEventTypesRequestModel = new LookupEventTypesRequestModel(this.toRequestLookupEventTypes(filter));
    this.eventTypes = (await Vue.$service.v2.api.eventType.lookupEventTypes(request)).items.map((x) => x.data);
  }

  async updateLookupLessonLecturerAssignmentStatus(filter: IPageFilter) {
    const request: GetLectureLessonStatesRequestModel = new GetLectureLessonStatesRequestModel(this.toRequestLookupLessonLecturerAssignmentStatus(filter));
    this.lectureLessonStates = (await Vue.$service.v2.api.lecturerLessonAssignments.getLessonLecturerAssignmentStatusLookup(request)).items.map((x) => x.data);
  }

  async loadModules(filter: IPageFilter, isPrototype: boolean): Promise<DataRecord<ModuleListResourceModel>[]> {
    const request: LookupLecturerModulesRequestModel = new LookupLecturerModulesRequestModel(this.toRequestLookupLecturerModulesRequestModel(filter, isPrototype, this.currentUserId));
    const resp = await Vue.$service.v2.api.modules.lookupLecturerModules(request);
    const modules = resp.items.sort((a, b) => (a.data.caption ?? '').localeCompare(b.data.caption ?? '')).map((i) => new DataRecord(new ModuleListResourceModel(i.data)));
    // // The selected modules should not disappear if a filter is set
    const selectedModuleIds = this.moduleIds;
    modules.forEach(async (i) => {
      // Selected lessons should remain selected
      i.isSelected = (selectedModuleIds.some((selModuleId) => selModuleId === i.data.id)) ?? false;
      if (i.isSelected) {
        await this.onModuleSelectionChanged(this, i);
      }
    });

    return modules;
  }

  async loadAllLessons(filter: IPageFilter, isPrototype: boolean) {
    const request: SearchLessonsRequestModel = new SearchLessonsRequestModel(this.toRequestSearchLessonsRequestDTO(filter, isPrototype, this.allLessonsDataSource.paging, this.currentUserId));
    const resp = await Vue.$service.v2.api.lessons.searchLessons(request);
    Vue.$routerUtils.updateQueryParams(this.toQueryParamter(this.filter, resp.paginationData?.currentPage || 1, resp.paginationData?.pageSize || 25, this.moduleIds));
    resp.items.forEach((i) => {
      // Selected lessons should remain selected
      i.isSelected = (this.selectedLessons?.some((selLesson) => selLesson.data.id === i.data.id)) ?? false;
    });
    this.selectedLessons = resp.items.filter((x) => x.isSelected);
    return resp;
  }

  async loadLessons(filter: IPageFilter, isPrototype: boolean, moduleVm: ModuleListResourceModel) {
    const request = new SearchLessonByModuleRequestModel(this.toRequestSearchLessonByModuleRequestDTO(filter, isPrototype, moduleVm.id, this.currentUserId));
    const resp = await Vue.$service.v2.api.lessons.searchLessonByModule(request);
    resp.items.forEach((i) => {
      // Selected lessons should remain selected
      i.isSelected = (this.selectedLessons?.some((selLesson) => selLesson.data.id === i.data.id)) ?? false;
    });
    this.selectedLessons = [
      ...(this.selectedLessons || []).filter((x) => x.data.lectureModuleId !== moduleVm.id),
      ...resp.items.filter((x) => x.isSelected)];
    return resp.items;
  }

  // MAPPERS
  toRequestLookupLecturerClasses(data: IPageFilter, isPrototype: boolean, currentUserId: number): DeepPartial<LookupLecturerClassesRequestDTO> {
    return {
      'paging.IncludeCount': false,
      'paging.NoPaging': true,
      classYear: data.classYear,
      // lectureLessonStatusId : data.lectureLessonStatusId,
      userId: currentUserId,
      lecturerId: data.lecturerId,
      // primaryLeadLecturerId: data.primaryLeadLecturerId,
      isPrototype: isPrototype ? undefined : false,
      // searchCriteria: data.searchCriteria,
    } as DeepPartial<LookupLecturerClassesRequestDTO>;
  }

  toRequestLookupEventTypes(data: IPageFilter): DeepPartial<LookupEventTypesRequestModel> {
    return {
    } as DeepPartial<LookupEventTypesRequestModel>;
  }

  toRequestLookupLessonLecturerAssignmentStatus(data: IPageFilter): DeepPartial<GetLectureLessonStatesRequestModel> {
    return {} as DeepPartial<GetLectureLessonStatesRequestModel>;
  }

  toRequestLookupLecturerModulesRequestModel(data: IPageFilter, isPrototype: boolean, currentUserId: number): DeepPartial<LookupLecturerModulesRequestDTO> {
    return {
      'paging.IncludeCount': false,
      'paging.NoPaging': true,
      classYear: data.classYear,
      moduleId: undefined,
      classIds: data.classIds,
      lectureLessonStatusIds: data.lectureLessonStatusIds,
      eventTypeIds: data.eventTypeIds,
      isPrototype: isPrototype ? undefined : false,
      userId: currentUserId,
      lecturerId: data.lecturerId,
      primaryLeadLecturerId: data.primaryLeadLecturerId,
      searchCriteria: data.searchCriteria,
    } as DeepPartial<LookupLecturerModulesRequestDTO>;
  }

  toRequestSearchLessonsRequestDTO(data: IPageFilter, isPrototype: boolean, paging: IDataSourcePaging, currentUserId: number): DeepPartial<SearchLessonsRequestDTO> {
    return {
      'paging.CurrentPage': paging.currentPage,
      'paging.IncludeCount': true,
      'paging.NoPaging': false,
      'paging.PageSize': paging.pageSize,
      classYear: data.classYear,
      moduleId: undefined,
      classIds: data.classIds,
      lectureLessonStatusIds: data.lectureLessonStatusIds,
      eventTypeIds: data.eventTypeIds,
      isPrototype: isPrototype ? undefined : false,
      userId: currentUserId,
      lecturerId: data.lecturerId,
      primaryLeadLecturerId: data.primaryLeadLecturerId,
      searchCriteria: data.searchCriteria,
    } as DeepPartial<SearchLessonsRequestDTO>;
  }

  toRequestSearchLessonByModuleRequestDTO(data: IPageFilter, isPrototype: boolean, moduleId: number, currentUserId: number): DeepPartial<SearchLessonByModuleRequestDTO> {
    return {
      classYear: data.classYear,
      moduleId,
      classIds: data.classIds,
      lectureLessonStatusIds: data.lectureLessonStatusIds,
      eventTypeIds: data.eventTypeIds,
      lectureLessonStatusId: data.lectureLessonStatusIds,
      userId: currentUserId,
      lecturerId: data.lecturerId,
      primaryLeadLecturerId: data.primaryLeadLecturerId,
      isPrototype: isPrototype ? undefined : false,
      searchCriteria: data.searchCriteria,
    } as DeepPartial<SearchLessonByModuleRequestDTO>;
  }

  toRequestExportLessonsRequestDTO(data: IPageFilter, isPrototype: boolean, moduleIds: number[], lessonIds: (number | undefined)[] | undefined, currentUserId: number): DeepPartial<ExportLessonsRequestDTO> {
    return {
      classYear: data.classYear,
      // classId = data.classId,
      moduleIds,
      lessonIds,
      classIds: data.classIds,
      lectureLessonStatusIds: data.lectureLessonStatusIds,
      eventTypeIds: data.eventTypeIds,

      // lectureLessonStatusId: data.lectureLessonStatusId,
      userId: currentUserId,
      lecturerId: data.lecturerId,
      // primaryLeadLecturerId: data.primaryLeadLecturerId,
      isPrototype: isPrototype ? undefined : false,
      searchCriteria: data.searchCriteria,
    } as DeepPartial<ExportLessonsRequestDTO>;
  }

  toSearchLessonsRequestModel(data: IPageFilter): SearchLessonsRequestModel {
    return {
      classYear: data.classYear,
      classIds: data.classIds,
      lecturerId: data.lecturerId,
      // primaryLeadLecturerId: data.primaryLeadLecturerId,

    } as SearchLessonsRequestModel;
  }

  toQueryParamter(data: IPageFilter, currentPage: number, pageSize: number, modulIds: number[] | null): any {
    return {
      classYear: +data.classYear,
      lecturerId: data.lecturerId,
      lectureLessonStatusIds: data.lectureLessonStatusIds?.toString(),
      eventTypeIds: data.eventTypeIds?.toString(),
      searchCriteria: data.searchCriteria,
      classIds: data.classIds?.toString(),
      modulIds: modulIds?.toString(),
      primaryLeadLecturerId: data.primaryLeadLecturerId,
      currentPage,
      pageSize,
    };
  }

  toSelectedModulesIds(data: any): number[] {
    return data.modulIds ? data.modulIds.split(',').map((x: string) => +x) : [];
  }

  toIPageFilter(data: any, isPrototype: boolean, currentUserId: number): IPageFilter {
    return {
      classYear: +(data.classYear || this.defaultYear()),
      lecturerId: data.lecturerId ? +data.lecturerId : undefined,
      lectureLessonStatusIds: data.lectureLessonStatusIds ? data.lectureLessonStatusIds.split(',').map((x: string) => +x) : undefined,
      eventTypeIds: data.eventTypeIds ? data.eventTypeIds.split(',').map((x: string) => +x) : undefined,
      searchCriteria: data.searchCriteria,
      classIds: data.classIds ? data.classIds.split(',').map((x: string) => +x) : undefined,
      isPrototype: isPrototype ? undefined : false,
      userId: currentUserId,
      primaryLeadLecturerId: undefined,
    } as IPageFilter;
  }

  toPagination(data: any): IDataSourcePaging {
    return {
      pageSize: +(data.pageSize || 25),
      currentPage: +(data.currentPage || 1),
    };
  }

  async clear(scope: LessonListPage) {
    scope.filter = {
      classYear: this.defaultYear(),
      lecturerId: undefined,
      lectureLessonStatusIds: [],
      eventTypeIds: [],
      searchCriteria: '',
      classIds: [],
      primaryLeadLecturerId: undefined,
    };
    if (!scope.isPlanningAdmin) {
      scope.filter.lecturerId = Vue.$service.v2.api.me.getAgogisUserId();
    }
    await scope.onDateFilterChange(scope);
  }

  async export(scope: LessonListPage, filter: IPageFilter, isPrototype: boolean) {
    scope.isExporting = true;
    const request: ExportLessonsRequestModel = new ExportLessonsRequestModel(scope.toRequestExportLessonsRequestDTO(filter, isPrototype, scope.selectedModules.map((x) => x.data.id), undefined, scope.currentUserId));
    const response = await Vue.$service.v2.api.lessons.exportLessons(request);
    const now = Vue.$date.now().format('YYYYMMDD-HHmmss');
    Vue.$service.utils.downloadFile(`${filter.classYear}-Lessons-${now}.xlsx`, response.data);
    scope.isExporting = false;
  }

  async refresh(scope: LessonListPage, filter: IPageFilter, resetPage = false) {
    await scope.refreshFilter(scope, filter);
    await scope.onFilterChange(scope, resetPage);
  }

  async refreshLessons(scope: LessonListPage) {
    await scope.onModuleFilterChange(scope);
  }

  async refreshFilter(scope: LessonListPage, filter: IPageFilter): Promise<void> {
    scope.isLoading = true;
    await scope.updateLookupLecturerClasses(filter);
    await scope.updateLookupEventTypes(filter);
    await scope.updateLookupLessonLecturerAssignmentStatus(filter);
    scope.filter = filter;
    scope.isLoading = false;
  }

  // Filter Events
  async clearSearchCriteria(scope: LessonListPage) {
    scope.lastSearchCriteria = '';
    scope.filter.searchCriteria = scope.lastSearchCriteria;
    scope.onFilterChange(scope, true);
  }

  async updateSearchCriteria(scope: LessonListPage, value: any) {
    if (!(value instanceof InputEvent)) return;
    const event = (value as InputEvent);
    const element = (event.target as HTMLInputElement);
    if (element.value === scope.lastSearchCriteria) return;
    scope.lastSearchCriteria = ((value as InputEvent)?.target as HTMLInputElement).value;
    scope.filter.searchCriteria = scope.lastSearchCriteria;
    scope.onFilterChange(scope, true);
  }

  async onDateFilterChange(scope: LessonListPage) {
    scope.filter = {
      classYear: scope.filter.classYear,
      lecturerId: scope.filter.lecturerId,
      lectureLessonStatusIds: [],
      eventTypeIds: [],
      searchCriteria: '',
      classIds: [],
      primaryLeadLecturerId: undefined,
    };
    await scope.clearModuleSelection(scope);

    await scope.clearLessonSelection(scope);
    await scope.refresh(scope, scope.filter, true);
  }

  async onModuleFilterChange(scope: LessonListPage) {
    scope.moduleIds = scope.modulesDataSource.data?.filter((x) => x.isSelected).map((y) => y.data.id) || [];
    let selLessons = [] as DataRecord<LectureLessonListModel>[];
    if (scope.lessonListDisplayType === 'GroupedByModule') {
      // eslint-disable-next-line no-restricted-syntax
      for (const selModule of scope.modulesDataSource.data || []) {
        // eslint-disable-next-line no-await-in-loop
        await scope.onModuleSelectionChanged(scope, selModule);
        if (selModule.isSelected) {
          const items = (selModule.data.items.data ?? []).filter((lesson) => lesson.isSelected);
          // eslint-disable-next-line no-restricted-syntax
          for (const lesson of items) {
            selLessons.push(lesson);
          }
        }
      }
      Vue.$routerUtils.updateQueryParams(scope.toQueryParamter(scope.filter, 1, scope.allLessonsDataSource.paging?.pageSize || 25, scope.moduleIds));
    } else if (scope.lessonListDisplayType === 'All') {
      selLessons = scope.allLessonsDataSource.data?.items.filter((i) => i.isSelected) ?? [];
      Vue.$routerUtils.updateQueryParams(scope.toQueryParamter(scope.filter, scope.allLessonsDataSource.paging.currentPage || 1, scope.allLessonsDataSource.paging.pageSize || 25, this.moduleIds));
    }
    scope.selectedLessons = selLessons;
    scope.lecturerBookingSummary.onFilterChange({ classYear: scope.filter.classYear, classIds: scope.filter.classIds, lecturerId: scope.filter.lecturerId });

    scope.lessonSelectionChangeEvent.invoke(scope.selectedLessons);
  }

  async onModuleSelectAll(scope: LessonListPage, moduleVm: DataRecord<ModuleListResourceModel>) {
    moduleVm.data.items.data?.forEach((lesson) => {
      lesson.isSelected = moduleVm.data.allLessonsSelected ?? false;
      const index = scope.selectedLessons?.indexOf(lesson) ?? -1;
      if (lesson.isSelected) {
        if (index === -1) { scope.selectedLessons?.push(lesson); }
      } else if (index > -1) {
        scope.selectedLessons?.splice(index, 1);
      }
    });
    await scope.onLessonSelectionChanged(scope);
  }

  async onModuleSelectionChanged(scope: LessonListPage, moduleVm: DataRecord<ModuleListResourceModel>) {
    if (scope.lessonListDisplayType !== 'All') {
      if (moduleVm.isSelected) {
        if ((moduleVm.data.items.data ?? []).length === 0) {
          moduleVm.data.items = new DataSource({
            selectCommand: () => scope.loadLessons(scope.filter, scope.isPrototype, moduleVm.data),
          });
          await moduleVm.data.items.select();
        }
      } else {
        moduleVm.data.items.data?.forEach((lesson) => {
          lesson.isSelected = false;
        });
      }
    }
  }

  async onPageChanged(scope: LessonListPage) {
    await scope.allLessonsDataSource.select();
  }

  async onLessonSelectionChanged(scope: LessonListPage) {
    await scope.onModuleFilterChange(scope);
    if ((scope.selectedLessons?.length ?? 0) > 0) {
      scope.isAssignToLessonsDrawerMinimized = false;
    }
    scope.isAssignToLessonsDrawerVisible = scope.isAssignToLessonsDrawerVisible || ((scope.selectedLessons || []).length > 0);
    scope.lessonSelectionChangeEvent.invoke(scope.selectedLessons || []);
  }

  async onFilterChange(scope: LessonListPage, resetPage = false) {
    // Update data for modules and lessons
    scope.isLoading = true;
    scope.modulesDataSource.isLoading = true;
    scope.allLessonsDataSource.isLoading = true;
    scope.selectedLessons = [];
    if (scope.isInitialized) { await scope.clearModuleSelection(scope); }

    await scope.modulesDataSource.select();
    scope.lecturerBookingSummary.onFilterChange({ classYear: scope.filter.classYear, classIds: scope.filter.classIds, lecturerId: scope.filter.lecturerId });
    const selectedModuleIds = scope.moduleIds;
    scope.modulesDataSource.data?.forEach(async (i) => {
      // Selected lessons should remain selected
      i.isSelected = (selectedModuleIds.some((selModuleId) => selModuleId === i.data.id)) ?? false;
      if (i.isSelected) {
        await scope.onModuleSelectionChanged(scope, i);
      }
    });

    if (resetPage) {
      scope.allLessonsDataSource.paging.currentPage = 1;
    }
    await scope.allLessonsDataSource.select();
    await scope.lessonSelectionChangeEvent.invoke(scope.selectedLessons);

    scope.isLoading = false;
  }

  // ASSIGNEMENT
  async onCancelAssignToLessons(scope: LessonListPage) {
    scope.lecturerBookingSummary.clearBookingForm();
    scope.isAssignToLessonsDrawerMinimized = true;
    await scope.onLessonSelectionChanged(scope);
  }

  async onAssignedToLessonsSubmitted(scope: LessonListPage) {
    scope.lecturerBookingSummary.clearBookingForm();
    await scope.allLessonsDataSource.select();
    scope.modulesDataSource.data?.forEach(async (selModule) => {
      if (selModule.isSelected) {
        selModule.data.items.data = [];
      }
    });
    await scope.refreshLessons(scope);
    scope.isAssignToLessonsDrawerMinimized = true;
  }

  async onCommentToLessonsSubmitted(scope: LessonListPage) {
    scope.lecturerBookingSummary.clearBookingForm();
    await scope.allLessonsDataSource.select();
    scope.modulesDataSource.data?.forEach(async (selModule) => {
      if (selModule.isSelected) {
        selModule.data.items.data = [];
      }
    });
    await scope.refreshLessons(scope);
    scope.isAssignToLessonsDrawerMinimized = true;
  }

  async clearModuleSelection(scope: LessonListPage) {
    scope.moduleIds = [];
    scope.modulesDataSource.data?.filter((x) => x.isSelected).forEach((module) => {
      module.isSelected = false;
    });
    Vue.$routerUtils.updateQueryParams(scope.toQueryParamter(scope.filter, scope.allLessonsDataSource.paging?.currentPage || 1, scope.allLessonsDataSource.paging?.pageSize || 25, scope.moduleIds));
  }

  async clearLessonSelection(scope: LessonListPage) {
    scope.modulesDataSource.data?.filter((x) => x.data.items.data?.some((y) => y.isSelected)).forEach((moduleVm) => {
      moduleVm.data.items.data?.filter((x) => x.isSelected).forEach((lesson: { isSelected: boolean; }) => {
        lesson.isSelected = false;
      });
    });
    await scope.clearModuleSelection(scope);
    await scope.modulesDataSource.select();
    scope.allLessonsDataSource.data?.items.filter((x) => x.isSelected).forEach((lesson: { isSelected: boolean; }) => {
      lesson.isSelected = false;
    });
    scope.selectedLessons = [];
    scope.lessonSelectionChangeEvent.invoke(scope.selectedLessons);
    scope.isAssignToLessonsDrawerMinimized = true;
  }

  // UI Controlling
  get lessonTableColumns() {
    function insertIf(condition: boolean, ...elements: any) {
      return condition ? elements : [];
    }
    return [
      {
        value: Vue.$t('page.lesson.table.date'),
        field: 'startTime',
        textAlign: 'left',
        format: (value: string) => Vue.$format.localDate(value),
      },
      {
        value: Vue.$t('page.modulePlanning.label.class'),
        field: 'className',
        textAlign: 'left',
      },
      ...insertIf(this.lessonListDisplayType !== 'GroupedByModule', {
        value: Vue.$t('page.modulePlanning.label.module'),
        field: 'moduleName',
        textAlign: 'left',
      }),
      {
        value: Vue.$t('page.lesson.table.description'),
        field: ['lessonName', 'comment_TT'],
        popUpIndex: 1,
        textAlign: 'left',
      },
      {
        value: Vue.$t('page.lesson.table.duration'),
        field: ['duration', 'timeOfDay'],
        formatString: '{0}/{1}',
        textAlign: 'left',
      },
      {
        value: Vue.$t('page.lesson.table.teacherName'),
        field: 'lecturerFullName',
        textAlign: 'left',
      },
      {
        value: Vue.$t('page.lesson.table.requestedTeacher'),
        field: 'lecturerComment',
        textAlign: 'left',
      },
      {
        value: Vue.$t('page.lesson.table.status'),
        field: 'lecturerStatusCaption',
        textAlign: 'right',
      },
    ];
  }

  getDetailUrl(scope: LessonListPage) {
    if (scope.isPlanningAdmin) {
      return '/agogis/cockpit/schedule-planning/lessons/';
    }
    return undefined;
  }

  get isLeadLecturer(): boolean {
    return this.selectedLessons !== undefined
            && this.selectedLessons.length > 0
            && (this.isPlanningAdmin || this.selectedLessons.every((l) => l.data.primaryLeadLecturerId === this.lecturerBookingSummary.filter.lecturerId));
  }

  get selectedModules(): DataRecordWrapResponse<ModuleListResourceModel>[] {
    return this.modulesDataSource.data?.filter((i) => i.data && this.moduleIds.some((y: number) => y === i.data?.id!)) ?? [];
  }

  get lessonListDisplayType(): LessonListDisplayType {
    if (this.moduleIds.length > 0) {
      return 'GroupedByModule';
    }
    return 'All';
  }
}
