// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CockpitNotificationModel from '../../model/cockpit-notification-model';
import CockpitNotificationDTO from '../dto/cockpit-notification-dto';
import dtoSchema from '../schemas/cockpit-notification-schema';

export default abstract class CockpitNotificationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CockpitNotificationDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.creatorId = data.creatorId;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modified = data.modified ?? undefined;
      this.recipient = data.recipient ?? undefined;
      this.recipientId = data.recipientId;
      this.mailTemplateId = data.mailTemplateId ?? undefined;
      this.subject = data.subject ?? undefined;
      this.state = data.state;
      this.information = data.information ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  */
  recipient?: string;
  /**
  * @type {int64}
  */
  recipientId?: number;
  /**
  */
  mailTemplateId?: string;
  /**
  */
  subject?: string;
  /**
  * @type {int32}
  */
  state?: 0 | 1 | 2 | 3 | 4; // CockpitNotificationStateDTO
  /**
  */
  information?: string;

  static toModel(dto: DeepPartial<CockpitNotificationDTO>): CockpitNotificationModel;
  static toModel(dto: DeepPartial<CockpitNotificationDTO> | undefined | null): CockpitNotificationModel | undefined;
  static toModel(dto: DeepPartial<CockpitNotificationDTO> | undefined | null): CockpitNotificationModel | undefined {
    return dto ? new CockpitNotificationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CockpitNotificationModel> | CockpitNotificationModel): CockpitNotificationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      creatorId: unwrapped.creatorId,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modified: unwrapped.modified,
      recipient: unwrapped.recipient,
      recipientId: unwrapped.recipientId,
      mailTemplateId: unwrapped.mailTemplateId,
      subject: unwrapped.subject,
      state: unwrapped.state,
      information: unwrapped.information,
    } as CockpitNotificationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CockpitNotificationModel, can not map to CockpitNotificationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
