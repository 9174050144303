<template>
  <div>
    <p-row v-if="model.comment">
      <p-col xs4 class="text-field-label">
        <p-checkbox
          v-model="model.comment.isTT"
          :is-read-only="!model.canEdit || isReadOnly"
          :label="$t('page.lesson.table.istt')"
          @input="model.canEdit?$emit('input', model.comment):undefined;"
        ></p-checkbox>
      </p-col><p-col xs8>
        <p-text-field
          v-model="model.comment.ttComment"
          :disabled="!model.canEdit || isReadOnly || !model.comment.isTT"
          solo
          @input="model.canEdit?$emit('input', model.comment):undefined;"
        />
      </p-col>
    </p-row>
    <p-textarea
      v-if="model.comment"
      v-model="model.comment.comment"
      class="agogis-booking-comment"
      :label="model.label"
      :disabled="!model.canEdit || isReadOnly"
      @input="model.canEdit?$emit('input', model.comment):undefined;"
    ></p-textarea>
    <p-alert v-if="!model.canEdit" :dismissable="false" :value="true" type="info">
      {{ $t('page.lesson.table.remarkReadonly') }}
    </p-alert>
  </div>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import BookingCommentTTComponentModel from './agogis-booking-comment-tt.vue.model';
  import ITTObjectModel from '../services/v2/model/itt-object-model';

  export default Vue.extend({
    name: 'AgogisBookingCommentTtComponent',
    props: {
      comments: { type: Array as PropType<ITTObjectModel[]>, default: () => [] as ITTObjectModel[] },
      isReadOnly: { default: true, type: Boolean },
      label: { default: '', type: String },
    },
    data: () => ({
      model: new BookingCommentTTComponentModel(),
    }),
    watch: {
      comments: {
        immediate: true,
        handler() {
          this.model.updateComments(this.comments);
        },
      },
    },
    async mounted() {
      this.model.initialize(this.label, this.isReadOnly, this.comments);
    },
  });
</script>
