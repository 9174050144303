// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LecturePrototypeModuleListModel from '../../model/lecture-prototype-module-list-model';
import LecturePrototypeModuleListDTO from '../dto/lecture-prototype-module-list-dto';
import dtoSchema from '../schemas/lecture-prototype-module-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class LecturePrototypeModuleListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LecturePrototypeModuleListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.moduleId = data.moduleId;
      this.moduleName = data.moduleName ?? undefined;
      this.moduleDescription = data.moduleDescription ?? undefined;
      this.moduleStartDate = data.moduleStartDate;
      this.moduleEndDate = data.moduleEndDate;
      this.moduleTotalDuration = data.moduleTotalDuration;
      this.moduleLessonsCount = data.moduleLessonsCount;
      this.classId = data.classId;
      this.className = data.className ?? undefined;
      this.classStartYear = data.classStartYear;
      this.classIsPrototype = data.classIsPrototype;
      this.countOfRequiredLecturers = data.countOfRequiredLecturers ?? undefined;
      this.countOfRequestedClasses = data.countOfRequestedClasses ?? undefined;
      this.countOfProvisionalClasses = data.countOfProvisionalClasses ?? undefined;
      this.countOfReservedClasses = data.countOfReservedClasses ?? undefined;
      this.countOfConfirmedClasses = data.countOfConfirmedClasses ?? undefined;
      this.countOfRefusedClasses = data.countOfRefusedClasses ?? undefined;
      this.countOfOpenClasses = data.countOfOpenClasses ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  moduleId?: number;
  /**
  */
  moduleName?: string;
  /**
  */
  moduleDescription?: string;
  /**
  * @type {date-time}
  */
  moduleStartDate?: string;
  /**
  * @type {date-time}
  */
  moduleEndDate?: string;
  /**
  * @type {double}
  */
  moduleTotalDuration?: number;
  /**
  * @type {int32}
  */
  moduleLessonsCount?: number;
  /**
  * @type {int64}
  */
  classId?: number;
  /**
  */
  className?: string;
  /**
  * @type {int32}
  */
  classStartYear?: number;
  /**
  */
  classIsPrototype?: boolean;
  /**
  * @type {int32}
  */
  countOfRequiredLecturers?: number;
  /**
  * @type {int32}
  */
  countOfRequestedClasses?: number;
  /**
  * @type {int32}
  */
  countOfProvisionalClasses?: number;
  /**
  * @type {int32}
  */
  countOfReservedClasses?: number;
  /**
  * @type {int32}
  */
  countOfConfirmedClasses?: number;
  /**
  * @type {int32}
  */
  countOfRefusedClasses?: number;
  /**
  * @type {int32}
  */
  countOfOpenClasses?: number;

  static toModel(dto: DeepPartial<LecturePrototypeModuleListDTO>): LecturePrototypeModuleListModel;
  static toModel(dto: DeepPartial<LecturePrototypeModuleListDTO> | undefined | null): LecturePrototypeModuleListModel | undefined;
  static toModel(dto: DeepPartial<LecturePrototypeModuleListDTO> | undefined | null): LecturePrototypeModuleListModel | undefined {
    return dto ? new LecturePrototypeModuleListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LecturePrototypeModuleListModel> | LecturePrototypeModuleListModel): LecturePrototypeModuleListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      moduleId: unwrapped.moduleId,
      moduleName: unwrapped.moduleName,
      moduleDescription: unwrapped.moduleDescription,
      moduleStartDate: unwrapped.moduleStartDate,
      moduleEndDate: unwrapped.moduleEndDate,
      moduleTotalDuration: unwrapped.moduleTotalDuration,
      moduleLessonsCount: unwrapped.moduleLessonsCount,
      classId: unwrapped.classId,
      className: unwrapped.className,
      classStartYear: unwrapped.classStartYear,
      classIsPrototype: unwrapped.classIsPrototype,
      countOfRequiredLecturers: unwrapped.countOfRequiredLecturers,
      countOfRequestedClasses: unwrapped.countOfRequestedClasses,
      countOfProvisionalClasses: unwrapped.countOfProvisionalClasses,
      countOfReservedClasses: unwrapped.countOfReservedClasses,
      countOfConfirmedClasses: unwrapped.countOfConfirmedClasses,
      countOfRefusedClasses: unwrapped.countOfRefusedClasses,
      countOfOpenClasses: unwrapped.countOfOpenClasses,
    } as LecturePrototypeModuleListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LecturePrototypeModuleListModel, can not map to LecturePrototypeModuleListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
