// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BuildingListModel from '../../model/building-list-model';
import BuildingListDTO from '../dto/building-list-dto';
import dtoSchema from '../schemas/building-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import Int64LookupModel from '../../model/int64-lookup-model';

export default abstract class BuildingListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BuildingListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.group = data.group ? Int64LookupModel.toModel(data.group) : undefined;
      this.sortOrder = data.sortOrder;
      this.shortName = data.shortName ?? undefined;
      this.city = data.city ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string[];
  /**
  */
  group?: Int64LookupModel;
  /**
  * @type {int32}
  */
  sortOrder?: number;
  /**
  */
  shortName?: string;
  /**
  */
  city?: string;

  static toModel(dto: DeepPartial<BuildingListDTO>): BuildingListModel;
  static toModel(dto: DeepPartial<BuildingListDTO> | undefined | null): BuildingListModel | undefined;
  static toModel(dto: DeepPartial<BuildingListDTO> | undefined | null): BuildingListModel | undefined {
    return dto ? new BuildingListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BuildingListModel> | BuildingListModel): BuildingListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      group: unwrapped.group,
      sortOrder: unwrapped.sortOrder,
      shortName: unwrapped.shortName,
      city: unwrapped.city,
    } as BuildingListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BuildingListModel, can not map to BuildingListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
