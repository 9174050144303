// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetClassRegisterRequestRequestModel from '../../model/widget-class-register-request-request-model';
import WidgetClassRegisterRequestRequestDTO from '../dto/widget-class-register-request-request-dto';
import dtoSchema from '../schemas/widget-class-register-request-request-schema';
import ContactAddressModel from '../../model/contact-address-model';
import ContactCorrespondenceAddressModel from '../../model/contact-correspondence-address-model';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class WidgetClassRegisterRequestRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetClassRegisterRequestRequestDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.contactAddress = ContactAddressModel.toModel(data.contactAddress);
      this.invoiceAddress = data.invoiceAddress ? ContactCorrespondenceAddressModel.toModel(data.invoiceAddress) : undefined;
      this.correspondenceAddress = data.correspondenceAddress ? ContactCorrespondenceAddressModel.toModel(data.correspondenceAddress) : undefined;
      this.registrationAnswers = data.registrationAnswers ? DynamicFormPayloadModel.toModel(data.registrationAnswers) : undefined;
      this.hasDyslexia = data.hasDyslexia ?? undefined;
      this.dyslexiaCertificateDocumentId = data.dyslexiaCertificateDocumentId ?? undefined;
      this.hasAdhd = data.hasAdhd ?? undefined;
      this.adhdCertificateDocumentId = data.adhdCertificateDocumentId ?? undefined;
      this.isForeignLanguage = data.isForeignLanguage ?? undefined;
      this.toccoNativeLanguageId = data.toccoNativeLanguageId ?? undefined;
      this.schoolEducationCountry = data.schoolEducationCountry ?? undefined;
      this.hasVisualImpairment = data.hasVisualImpairment ?? undefined;
      this.visualImpairmentCertificateDocumentId = data.visualImpairmentCertificateDocumentId ?? undefined;
      this.hasHearingImpairment = data.hasHearingImpairment ?? undefined;
      this.hearingImpairmentCertificateDocumentId = data.hearingImpairmentCertificateDocumentId ?? undefined;
      this.hasOtherDisabilities = data.hasOtherDisabilities ?? undefined;
      this.otherDisabilitiesComment = data.otherDisabilitiesComment ?? undefined;
      this.employmentPosition = data.employmentPosition ?? undefined;
      this.employmentArea = data.employmentArea ?? undefined;
      this.employmentGradePercent = data.employmentGradePercent;
      this.employmentStartDate = data.employmentStartDate ?? undefined;
      this.comment = data.comment ?? undefined;
      this.marketingAnswers = data.marketingAnswers ? DynamicFormPayloadModel.toModel(data.marketingAnswers) : undefined;
      this.requestSummary = data.requestSummary ? RequestSummaryModel.toModel(data.requestSummary) : undefined;
      this.acceptNewsletter = data.acceptNewsletter;
      this.acceptGdpr = data.acceptGdpr;
      this.id = data.id;
    }
  }
  /**
  * @type {int32}
  */
  eventId?: number;
  /**
  */
  contactAddress?: ContactAddressModel;
  /**
  */
  invoiceAddress?: ContactCorrespondenceAddressModel;
  /**
  */
  correspondenceAddress?: ContactCorrespondenceAddressModel;
  /**
  */
  registrationAnswers?: DynamicFormPayloadModel;
  /**
  */
  hasDyslexia?: boolean;
  /**
  */
  dyslexiaCertificateDocumentId?: string;
  /**
  */
  hasAdhd?: boolean;
  /**
  */
  adhdCertificateDocumentId?: string;
  /**
  */
  isForeignLanguage?: boolean;
  /**
  * @type {int32}
  */
  toccoNativeLanguageId?: number;
  /**
  */
  schoolEducationCountry?: string;
  /**
  */
  hasVisualImpairment?: boolean;
  /**
  */
  visualImpairmentCertificateDocumentId?: string;
  /**
  */
  hasHearingImpairment?: boolean;
  /**
  */
  hearingImpairmentCertificateDocumentId?: string;
  /**
  */
  hasOtherDisabilities?: boolean;
  /**
  */
  otherDisabilitiesComment?: string;
  /**
  */
  employmentPosition?: string;
  /**
  */
  employmentArea?: string;
  /**
  * @type {int32}
  */
  employmentGradePercent?: number;
  /**
  * @type {date-time}
  */
  employmentStartDate?: string;
  /**
  */
  comment?: string;
  /**
  */
  marketingAnswers?: DynamicFormPayloadModel;
  /**
  */
  requestSummary?: RequestSummaryModel;
  /**
  */
  acceptNewsletter?: boolean;
  /**
  */
  acceptGdpr?: boolean;
  /**
  * @type {int32}
  */
  id?: number;

  static toModel(dto: DeepPartial<WidgetClassRegisterRequestRequestDTO>): WidgetClassRegisterRequestRequestModel;
  static toModel(dto: DeepPartial<WidgetClassRegisterRequestRequestDTO> | undefined | null): WidgetClassRegisterRequestRequestModel | undefined;
  static toModel(dto: DeepPartial<WidgetClassRegisterRequestRequestDTO> | undefined | null): WidgetClassRegisterRequestRequestModel | undefined {
    return dto ? new WidgetClassRegisterRequestRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetClassRegisterRequestRequestModel> | WidgetClassRegisterRequestRequestModel): WidgetClassRegisterRequestRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      contactAddress: unwrapped.contactAddress,
      invoiceAddress: unwrapped.invoiceAddress,
      correspondenceAddress: unwrapped.correspondenceAddress,
      registrationAnswers: unwrapped.registrationAnswers,
      hasDyslexia: unwrapped.hasDyslexia,
      dyslexiaCertificateDocumentId: unwrapped.dyslexiaCertificateDocumentId,
      hasAdhd: unwrapped.hasAdhd,
      adhdCertificateDocumentId: unwrapped.adhdCertificateDocumentId,
      isForeignLanguage: unwrapped.isForeignLanguage,
      toccoNativeLanguageId: unwrapped.toccoNativeLanguageId,
      schoolEducationCountry: unwrapped.schoolEducationCountry,
      hasVisualImpairment: unwrapped.hasVisualImpairment,
      visualImpairmentCertificateDocumentId: unwrapped.visualImpairmentCertificateDocumentId,
      hasHearingImpairment: unwrapped.hasHearingImpairment,
      hearingImpairmentCertificateDocumentId: unwrapped.hearingImpairmentCertificateDocumentId,
      hasOtherDisabilities: unwrapped.hasOtherDisabilities,
      otherDisabilitiesComment: unwrapped.otherDisabilitiesComment,
      employmentPosition: unwrapped.employmentPosition,
      employmentArea: unwrapped.employmentArea,
      employmentGradePercent: unwrapped.employmentGradePercent,
      employmentStartDate: unwrapped.employmentStartDate,
      comment: unwrapped.comment,
      marketingAnswers: unwrapped.marketingAnswers,
      requestSummary: unwrapped.requestSummary,
      acceptNewsletter: unwrapped.acceptNewsletter,
      acceptGdpr: unwrapped.acceptGdpr,
      id: unwrapped.id,
    } as WidgetClassRegisterRequestRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetClassRegisterRequestRequestModel, can not map to WidgetClassRegisterRequestRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
