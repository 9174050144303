<template>
  <!-- Begin Agogis.Widget.NextCourses -->
  <div class="liste angebote">
    <agogis-widget-course-card v-for="e in courses" :key="e.id" :value="e"></agogis-widget-course-card>
    <div v-if="!isResultAvailable" class="item">
      <div class="image">
        <div class="backgroundimage" :style="{'background-image':`url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)`}"></div>
      </div>
      <div class="text_wrapper">
        <div class="row">
          <div class="col-12">
            <div class="titel">
              Ist Ihr gewünschtes Angebot noch nicht ersichtlich?
            </div>
            <div>
              <p>
                Unsere Bildungsangebote werden fortlaufend geplant und publiziert.<br>
                Bitte besuchen Sie unsere Webseite regelmässig.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-language: {{ $config.values['language'] }}
    </div>
  </div>
  <!-- End Agogis.Widget.NextCourses -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
  import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
  import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';
  import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';

  export default Vue.extend({
    layout: 'layout-widget',
    data: () => ({
      courses: [] as WidgetClassListResourceViewModel[],
      isTermineVisible: [] as boolean[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
      isResultAvailable():boolean {
        if (this.courses.length === 0) { return false; }
        return true;
      },
    },
    async mounted() {
      try {
        const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
        widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
        const page = new ApiPagingRequestViewModel();
        page.pageSize = this.pageSize;
        widgetSearchClassRequestVM.paging = page;
        const response = await this.$service.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
        this.courses = response.items;
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
          // = errorResponse.data;
        }
        this.$log.error(e);
      }
    },
  });
</script>
