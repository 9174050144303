import Vue from 'vue';
import agogisCockpitServices from '../api/AgogisCockpit';
import apiKeysServices from '../api/ApiKeys';
import appServices from '../api/App';
import appLogsServices from '../api/AppLogs';
import appointmentsServices from '../api/Appointments';
import appSystemServices from '../api/AppSystem';
import appSystemsServices from '../api/AppSystems';
import appSystemWebApisServices from '../api/AppSystemWebApis';
import authServices from '../api/Auth';
import callLogsServices from '../api/CallLogs';
import classesServices from '../api/Classes';
import cockpitNotificationsServices from '../api/CockpitNotifications';
import contactIdMappingsServices from '../api/ContactIdMappings';
import documentsServices from '../api/Documents';
import dynamicFormServices from '../api/DynamicForm';
import eventTypeServices from '../api/EventType';
import fieldMappingServices from '../api/FieldMapping';
import httpMethodLookupServices from '../api/HttpMethodLookup';
import integrationCallsServices from '../api/IntegrationCalls';
import integrationTasksServices from '../api/IntegrationTasks';
import lectureLessonStatusServices from '../api/LectureLessonStatus';
import lecturerLessonAssignmentsServices from '../api/LecturerLessonAssignments';
import lessonsServices from '../api/Lessons';
import locationsServices from '../api/Locations';
import meServices from '../api/Me';
import modulesServices from '../api/Modules';
import onlinePaymentTransactionServices from '../api/OnlinePaymentTransaction';
import processesServices from '../api/Processes';
import publicHolidaysServices from '../api/PublicHolidays';
import referentsServices from '../api/Referents';
import roomsServices from '../api/Rooms';
import schedulerResourcesServices from '../api/SchedulerResources';
import sequencenumbersServices from '../api/Sequencenumbers';
import tenantsServices from '../api/Tenants';
import usersServices from '../api/Users';
import widgetServices from '../api/Widget';
import widgetClassesServices from '../api/WidgetClasses';
import widgetReservationsServices from '../api/WidgetReservations';

export default () => ({
  agogisCockpit: { ...agogisCockpitServices(Vue.$service) },
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appointments: { ...appointmentsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  appSystems: { ...appSystemsServices(Vue.$service) },
  appSystemWebApis: { ...appSystemWebApisServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  callLogs: { ...callLogsServices(Vue.$service) },
  classes: { ...classesServices(Vue.$service) },
  cockpitNotifications: { ...cockpitNotificationsServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  eventType: { ...eventTypeServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  httpMethodLookup: { ...httpMethodLookupServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  lectureLessonStatus: { ...lectureLessonStatusServices(Vue.$service) },
  lecturerLessonAssignments: { ...lecturerLessonAssignmentsServices(Vue.$service) },
  lessons: { ...lessonsServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  modules: { ...modulesServices(Vue.$service) },
  onlinePaymentTransaction: { ...onlinePaymentTransactionServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  publicHolidays: { ...publicHolidaysServices(Vue.$service) },
  referents: { ...referentsServices(Vue.$service) },
  rooms: { ...roomsServices(Vue.$service) },
  schedulerResources: { ...schedulerResourcesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
  widget: { ...widgetServices(Vue.$service) },
  widgetClasses: { ...widgetClassesServices(Vue.$service) },
  widgetReservations: { ...widgetReservationsServices(Vue.$service) },
});
