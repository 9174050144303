/* eslint-disable no-bitwise */
import LectureLessonBookingActionResourceModel from '../../generated/types/model/resource/LectureLessonBookingActionResourceModel';

// type LectureLessonBookingActionResourceViewModel = LectureLessonBookingActionResourceModel;
// export default LectureLessonBookingActionResourceViewModel;

// eslint-disable-next-line no-shadow
enum LectureLessonBookingActionResourceViewModel {
  None = 0,
  Assign = 1 << 0,
  Confirm = 1 << 1,
  Decline = 1 << 2,
}

export default LectureLessonBookingActionResourceViewModel;
