<template>
  <p-container fluid class="schedule-planning-lesson">
    <!-- Title -->
    <h1>{{ $t('navigation.lesson.name') }}</h1>
    <!-- Filter -->
    <p-row class="filter">
      <p-col shrink>
        <div class="p-text-field active has-actions-slot">
          <label for="text-field-153" class="text-field-label"> </label>
          <p-button @click="page.refresh(page, page.filter)">
            <p-icon>refresh</p-icon>
          </p-button>
        </div>
      </p-col>
      <p-col shrink>
        <agogis-year-lookup v-model="page.filter.classYear" shrink :is-loading="page.isLoading"
          :label="$t('page.lessonList.label.year')" @input="page.onDateFilterChange(page)"></agogis-year-lookup>
      </p-col>
      <p-col shrink grow>
        <p-text-field ref="inputSearchCriteria" v-model="page.filter.searchCriteria"
          v-debounce="(value) => page.updateSearchCriteria(page, value)" :is-loading="page.isLoading" clearable
          :label="$t('core.app.search')" context-icon="search" @clear="page.clearSearchCriteria(page)"></p-text-field>
      </p-col>
      <p-col shrink grow>
        <p-select-multiple v-model="page.filter.classIds" :is-loading="page.isLoading" :items="page.classes" clearable
          can-search :label="$t('page.lessonList.label.class')"
          @input="page.onFilterChange(page, true)"></p-select-multiple>
      </p-col>
      <p-col shrink grow>
        <agogis-lecturer-lookup v-model="page.filter.lecturerId" :is-loading="page.isLoading"
          :disabled="!(page.isPlanningAdmin)" :label="$t('page.lesson.table.teacherName')" context-icon="search"
          clearable @input="page.onFilterChange(page, true)"></agogis-lecturer-lookup>
      </p-col>
      <p-col shrink grow>
        <p-select-multiple v-model="page.filter.lectureLessonStatusIds" :is-loading="page.isLoading"
          :items="page.lectureLessonStates" clearable :label="$t('page.lesson.table.status')"
          @input="page.onFilterChange(page, true)"></p-select-multiple>
      </p-col>
      <p-col v-if="page.isPlanningAdmin" shrink grow>
        <p-select-multiple v-model="page.filter.eventTypeIds" :is-loading="page.isLoading" :items="page.eventTypes"
          clearable :label="$t('page.lesson.table.eventType')"
          @input="page.onFilterChange(page, true)"></p-select-multiple>
      </p-col>
    </p-row>
    <p-row class="filter" no-gutters>
      <p-col justify-end>
        <p-button link tertiary color="base" :title="$t('page.lessonList.label.reset')" @click="page.clear(page)">
          {{ $t('page.lessonList.label.reset') }}
        </p-button>
      </p-col>
    </p-row>
    <!-- MainContent -->
    <div class="main-wrapper">
      <div>
        <div class="main">
          <p-side-drawer v-model="page.isAssignToLessonsDrawerVisible" class="lesson-assignment-side-drawer"
            :overlay="page.isAssignToLessonsDrawerVisible" :elevation="page.isAssignToLessonsDrawerVisible ? 3 : 0"
            right minifiable :minified.sync="page.isAssignToLessonsDrawerMinimized">
            <div v-if="!page.isLoading && !page.modulesDataSource.isLoading && !page.allLessonsDataSource.isLoading"
              class="drawer-minified-content">
              <p-row v-if="page.isAssignToLessonsDrawerMinimized" column grow class="booking-summary-minimized">
                <p-col shrink>
                  <div class="label">
                    Pensum
                  </div>
                  <div class="value">
                    {{ page.lecturerBookingSummary.bookedWorkload }}d
                  </div>
                </p-col>
                <p-col grow>
                  <div class="label">
                    Gewählt
                  </div>
                  <div class="value">
                    {{ page.lecturerBookingSummary.selectedWorkload }}d
                  </div>
                </p-col>
                <p-col shrink>
                  <p-divider color="app-3" class="my-3"></p-divider>
                  <strong>
                    <div class="label">
                      Total
                    </div>
                    <div class="value">
                      {{ page.lecturerBookingSummary.totalWorkload }}d
                    </div>
                  </strong>
                </p-col>
              </p-row>
              <!-- BEHALTEN WIR, DA DIESER EXPORT BESTIMMT NOCH KOMMT Martin Lippuner 17.05.2023 <p-row class="booking-summary-wrapper export">
                <p-col grow justify-end shrink>
                  <p-button

                    class="export-button"
                    secondary
                    :title="$t('page.lessonList.label.exportSummary')"
                    @click="page.exportSummary(page,page.filter,page.isPrototype)"
                  >
                    <p-icon>description</p-icon> {{ page.isAssignToLessonsDrawerMinimized?'':$t('page.lessonList.label.exportSummary') }}
                  </p-button>
                </p-col>
              </p-row> -->
            </div>
            <template #drawer-footer-actions>
              <p-row justify-end no-gutters>
                <p-button v-if="!page.isAssignToLessonsDrawerMinimized" class="my-0 py-0" tertiary
                  @click="page.isAssignToLessonsDrawerVisible = false">
                  <p-icon>close</p-icon>
                </p-button>
              </p-row>
            </template>
            <template #drawer-content>
              <div v-if="!page.isLoading && !page.modulesDataSource.isLoading && !page.allLessonsDataSource.isLoading"
                class="booking-summary-wrapper">
                <div>
                  <h5>Pensum</h5>
                  <div class="mb-2">
                    <p-row v-for="summaryEntry in page.lecturerBookingSummary.workloadBreakdown"
                      :key="summaryEntry.name" no-gutters class="caption-1"
                      :class="summaryEntry.isIncludedInWorkload ? 'text-color-text-lighten-1' : 'text-color-text-lighten-3'">
                      <p-col grow>
                        <span>{{ summaryEntry.name }}</span>
                      </p-col>
                      <p-col shrink>
                        <span class="mr-1">{{ `${summaryEntry.totalDays}d` }}</span>
                      </p-col>
                    </p-row>
                  </div>
                  <p-divider class="mb-2"></p-divider>
                  <div class="mb-4">
                    <strong>
                      <p-row no-gutters class="caption-1 text-color-text">
                        <p-col grow>
                          <span>Zwischentotal</span>
                        </p-col>
                        <p-col shrink>
                          <span class="mr-1">{{ `${page.lecturerBookingSummary.bookedWorkload}d` }}</span>
                        </p-col>
                      </p-row>
                    </strong>
                  </div>
                  <!-- <div class="mb-2">
                    <strong>
                      <p-row
                        no-gutters
                        class="caption-1 text-color-text"
                      >
                        <p-col grow>
                          <span>Ausgewählt</span>
                        </p-col>
                        <p-col shrink>
                          <span class="mr-1">{{ `${page.lecturerBookingSummary.selectedWorkload}d` }}</span>
                        </p-col>
                      </p-row>
                    </strong>
                  </div> -->
                  <h5 class="mb-5">
                    <p-row no-gutters>
                      <p-col grow>
                        Ausgewählt
                      </p-col>
                      <p-col shrink>
                        {{ `${page.lecturerBookingSummary.selectedWorkload}d` }}
                      </p-col>
                    </p-row>
                  </h5>
                  <p-row column>
                    <p-col grow>
                      <div v-if="page.lecturerBookingSummary.bookingFormMessage">
                        <p-alert class="booking-summary-alert"
                          :type="page.lecturerBookingSummary.bookingFormMessageType" :value="true">
                          {{ page.lecturerBookingSummary.bookingFormMessage }}
                        </p-alert>
                      </div>
                      <agogis-assign-to-lesson-form v-else :value="page.lecturerBookingSummary"
                        @cancel="page.onCancelAssignToLessons(page)"
                        @submitted="page.onAssignedToLessonsSubmitted(page)"
                        @commented="page.onCommentToLessonsSubmitted(page)"></agogis-assign-to-lesson-form>
                    </p-col>
                    <p-col shrink>
                      <div class="my-3">
                        <p-divider></p-divider>
                        <h5>
                          <p-row>
                            <p-col grow>
                              <strong>Total</strong>
                            </p-col>
                            <p-col shrink>
                              <strong>{{ `${page.lecturerBookingSummary.totalWorkload}d` }}</strong>
                            </p-col>
                          </p-row>
                        </h5>
                      </div>
                    </p-col>
                    <p-col v-if="page.canEditCommentTT && page.isLeadLecturer" shrink>
                      <h5><p-icon>info_outline</p-icon>{{ $t('page.lesson.lessonComment.title') }}</h5>
                      <hr aria-orientation="horizontal" class="mb-2 p-divider border-color-app-1">
                      <p-textarea v-if="page.lecturerBookingSummary.canEdit_comment_TT"
                        v-model="page.lecturerBookingSummary.commentTT" class="agogis-booking-commentTT"
                        :label="$t('page.lesson.lessonComment.name')"></p-textarea>
                      <p-row v-if="page.lecturerBookingSummary.canEdit_comment_TT" justify-end>
                        <p-col xs6>
                          <p-button stretch secondary :loading="page.lecturerBookingSummary.isBookingFormLoading"
                            :disabled="page.lecturerBookingSummary.isBookingFormLoading"
                            @click="page.lecturerBookingSummary.onCommentTTCancel">
                            {{ $t('core.app.cancel') }}
                          </p-button>
                        </p-col>
                        <p-col xs6>
                          <p-button stretch primary :loading="page.lecturerBookingSummary.isBookingFormLoading"
                            :disabled="!page.lecturerBookingSummary.hasChanges_CommentTT || page.lecturerBookingSummary.isBookingFormLoading"
                            @click="page.lecturerBookingSummary.onCommentTTSubmit(page.lecturerBookingSummary.selectedLessons, page.lecturerBookingSummary.commentTT)">
                            {{ $t('core.app.ok') }}
                          </p-button>
                        </p-col>
                      </p-row>
                    </p-col>
                  </p-row>
                </div>
              </div>
            </template>
            <template #content>
              <p-row class="content-wrapper">
                <p-col class="module-list-container" md4 lg3 xl2>
                  <div class="module-list-wrapper">
                    <p-row class="module-list-heading">
                      <p-col>
                        <h4>Module</h4>
                      </p-col>
                    </p-row>
                    <p-divider class="ma-0 pa-0"></p-divider>
                    <div class="module-list-content">
                      <p-progress-linear v-if="page.isLoading || page.modulesDataSource.isLoading"
                        indeterminate></p-progress-linear>
                      <p-list v-else dense>
                        <p-list-entry v-for="lectureModule in page.modulesDataSource.data" :key="lectureModule.data.id"
                          :title="lectureModule.data.details" class="iy-1">
                          <p-checkbox v-model="lectureModule.isSelected" :label="lectureModule.data.caption"
                            @input="page.onModuleFilterChange(page)"></p-checkbox>
                        </p-list-entry>
                      </p-list>
                    </div>
                  </div>
                </p-col>
                <p-col class="lessons-list-container" md8 lg9 xl10>
                  <p-row class="heading">
                    <p-col shrink>
                      <h4>Lektionen</h4>
                    </p-col>
                    <p-col>
                      <p-button class="export-button" secondary :title="$t('page.lessonList.label.export')"
                        :loading="page.isExporting" :disabled="page.isExporting || page.isLoading"
                        @click="page.export(page, page.filter, page.isPrototype)">
                        <p-icon>description</p-icon> {{ $t('page.lessonList.label.export') }}
                      </p-button>
                    </p-col>
                  </p-row>
                  <p-divider class="ma-0 pa-0"></p-divider>
                  <p-row class="lessons-wrapper">
                    <div v-if="page.lessonListDisplayType === 'GroupedByModule'"
                      class="lesson-list-content no-paging grouped-by-module">
                      <div v-for="moduleVm in page.selectedModules" :key="moduleVm.data.id" class="module"
                        :title="moduleVm.data.details">
                        <p-toolbar dense color="base" class="group-header">
                          <p-checkbox v-model="moduleVm.data.allLessonsSelected" class="module-title"
                            :disabled="moduleVm.data.items.isLoading" :label="moduleVm.data.caption"
                            @input="page.onModuleSelectAll(page, moduleVm)"></p-checkbox>
                        </p-toolbar>
                        <p-progress-linear v-if="page.isLoading || moduleVm.data.items.isLoading" class="my-4"
                          indeterminate></p-progress-linear>
                        <agogis-table v-else v-model="moduleVm.data.items.data" :columns="page.lessonTableColumns"
                          :goto-detail-url="page.getDetailUrl(page)"
                          @onItemsSelectionChanged="page.onLessonSelectionChanged(page)"></agogis-table>
                      </div>
                    </div>

                    <div v-else-if="page.lessonListDisplayType === 'All'" class="lesson-list-content all">
                      <div>
                        <p-progress-linear v-if="page.isLoading || page.allLessonsDataSource.isLoading" class="my-4"
                          indeterminate></p-progress-linear>
                        <agogis-table v-else-if="page.allLessonsDataSource.data"
                          v-model="page.allLessonsDataSource.data.items" :columns="page.lessonTableColumns"
                          :goto-detail-url="page.getDetailUrl(page)"
                          @onItemsSelectionChanged="page.onLessonSelectionChanged(page)"></agogis-table>
                      </div>
                    </div>
                  </p-row>
                  <p-pagination v-if="page.isInitialized && page.lessonListDisplayType === 'All'"
                    v-model="page.allLessonsDataSource.paging" @input="page.onPageChanged(page)"></p-pagination>
                </p-col>
              </p-row>
            </template>
          </p-side-drawer>
        </div>
      </div>
    </div>
  </p-container>
</template>

<script lang="ts">
import Vue from 'vue';
import AgogisTable from '../../../../../components/agogis-table.vue';
import LessonListPage from './lesson-list.vue.model';
import AgogisAssignToLessonForm from './agogis-assign-to-lesson-form.vue';

export default Vue.extend({
  auth: true,
  components: { AgogisTable, AgogisAssignToLessonForm },
  data: () => ({
    page: new LessonListPage(),
    visible: true,
  }),
  async mounted() {
    this.page.initialize();
  },
  methods: {
  },
});
</script>
