import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateLessonAssignmentRequestDTO from '../_generated/dto/update-lesson-assignment-request-dto';
import UpdateLessonAssignmentRequestModelBase from '../_generated/modelBases/update-lesson-assignment-request-model-base';
import TTObjectModel from './tt-object-model';

export default class UpdateLessonAssignmentRequestModel extends UpdateLessonAssignmentRequestModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs
  constructor(data?: DeepPartial<UpdateLessonAssignmentRequestDTO>) {
    super(data);
    this.comment = new TTObjectModel();
  }
}
