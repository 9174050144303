// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateCommentLessonsRequestModel from '../../model/update-comment-lessons-request-model';
import UpdateCommentLessonsRequestDTO from '../dto/update-comment-lessons-request-dto';
import dtoSchema from '../schemas/update-comment-lessons-request-schema';
import TTObjectModel from '../../model/tt-object-model';

export default abstract class UpdateCommentLessonsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateCommentLessonsRequestDTO>) {
    super();
    if (data) {
      this.updateComment = data.updateComment;
      this.comment = data.comment ? TTObjectModel.toModel(data.comment) : undefined;
      this.updateAdminComment = data.updateAdminComment;
      this.adminComment = data.adminComment ?? undefined;
      this.selectedLessonIds = data.selectedLessonIds;
      this.agogisUserId = data.agogisUserId;
    }
  }
  /**
  */
  updateComment?: boolean;
  /**
  */
  comment?: TTObjectModel;
  /**
  */
  updateAdminComment?: boolean;
  /**
  */
  adminComment?: string;
  /**
  */
  selectedLessonIds?: number[];
  /**
  * @type {int64}
  */
  agogisUserId?: number;

  static toModel(dto: DeepPartial<UpdateCommentLessonsRequestDTO>): UpdateCommentLessonsRequestModel;
  static toModel(dto: DeepPartial<UpdateCommentLessonsRequestDTO> | undefined | null): UpdateCommentLessonsRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateCommentLessonsRequestDTO> | undefined | null): UpdateCommentLessonsRequestModel | undefined {
    return dto ? new UpdateCommentLessonsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateCommentLessonsRequestModel> | UpdateCommentLessonsRequestModel): UpdateCommentLessonsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      updateComment: unwrapped.updateComment,
      comment: unwrapped.comment,
      updateAdminComment: unwrapped.updateAdminComment,
      adminComment: unwrapped.adminComment,
      selectedLessonIds: unwrapped.selectedLessonIds,
      agogisUserId: unwrapped.agogisUserId,
    } as UpdateCommentLessonsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateCommentLessonsRequestModel, can not map to UpdateCommentLessonsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
