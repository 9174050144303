// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import Int64LookupSchema from './int64-lookup-schema';
import RoomListSchema from './room-list-schema';

const schema = {
  $id: '/appointment-list-schema',
  type: 'object',
  required: ['id', 'sortOrder'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    details: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    group: {
      ...Int64LookupSchema,
    },
    sortOrder: {
      type: 'integer',
    },
    isAllDay: {
      type: 'boolean',
    },
    startTime: {
      type: 'string',
      format: 'date-time',
    },
    endTime: {
      type: 'string',
      format: 'date-time',
    },
    bookedRooms: {
      type: 'array',
      nullable: true,
      items: { ...RoomListSchema },
    },
  },
} as IJSONSchema;

export default schema;
