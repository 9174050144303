import { VueConstructor } from 'vue';
import bearerTokenGuard from '@glittr/frontend-core/src/plugins/auth/strategies/bearerToken/bearerTokenGuard';
import { CoreOptions } from '@glittr/frontend-core';
import BearerTokenAuthentication from './bearerTokenAuthStrategy';

export default {
  install: (Vue: VueConstructor, options: CoreOptions) => {
    const auth = new BearerTokenAuthentication();
    // Preload values from storage if they exist
    Vue.$guards.push(bearerTokenGuard);
    auth.load();
    Vue.$auth = auth;
    Vue.prototype.$auth = Vue.$auth;
  },
};
