// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ITTObjectViewModel from '../../../../viewModel/resource/ITTObjectViewModel';
import ITTObjectDTO from '../../dto/resource/ITTObjectDTO';

export default abstract class ITTObjectModel extends BaseModel<ITTObjectDTO> {
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  */
  get ttComment() { return this.dto.ttComment; }
  set ttComment(value) { this.dto.ttComment = value; }
  /**
  */
  get isTT() { return this.dto.isTT; }
  set isTT(value) { this.dto.isTT = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.comment = qs.comment;
    this.dto.ttComment = qs.ttcomment;
    value = qs.isTT === 'true' ? true : qs.istt;
    value = qs.isTT === 'false' ? false : undefined;
    this.dto.isTT = value;
  }
}
