// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import SubjectAreaResourceViewModel from '../../../../viewModel/resource/SubjectAreaResourceViewModel';
import SubjectAreaResourceDTO from '../../dto/resource/SubjectAreaResourceDTO';

export default abstract class SubjectAreaResourceModel extends BaseModel<SubjectAreaResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.caption = qs.caption;
  }
}
