import _ from '@glittr/frontend-core/src/utils';
import Vue from 'vue';
import { DayPilot } from 'daypilot-pro-vue';
import SchedulerResourceListResultModel from '../../generated/types/model/resource/SchedulerResourceListResultModel';

export default class SchedulerResourceListResultViewModel extends SchedulerResourceListResultModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get itemsForDayPilot() {
    const mapTagsToIcons = (tags: string[] | undefined | null) => {
      const icons = [] as {id: string, caption: string}[];
      tags?.forEach((tag) => {
        const tagLower = tag.toLowerCase();
        switch (tagLower) {
          case 'beginner_course':
            icons.push({ id: 'book', caption: Vue.$t(`page.scheduler.lessonTypeEnum.${tagLower}`) });
            break;
          case 'course':
            icons.push({ id: 'menu_book', caption: Vue.$t(`page.scheduler.lessonTypeEnum.${tagLower}`) });
            break;
          case 'specialist_course':
            icons.push({ id: 'school', caption: Vue.$t(`page.scheduler.lessonTypeEnum.${tagLower}`) });
            break;
          case 'vl':
            icons.push({ id: 'laptop', caption: Vue.$t(`page.scheduler.lessonTypeEnum.${tagLower}`) });
            break;
          default:
            break;
        }
      });
      return icons;
    };

    const events = [] as DayPilot.EventData[];
    this.items.forEach((e) => {
      const icons = mapTagsToIcons(e.tags);
      const cell = {} as DayPilot.EventData;
      cell.id = DayPilot.guid();
      cell.resource = e.parentResourceId!;
      cell.sort = e.sort?.map((s) => s.toString());
      if (cell.text === '') { cell.text = 'Data Error: missing caption'; }
      const startDate = Vue.$date(e.startDate!);
      const endDate = Vue.$date(e.endDate!);
      cell.start = startDate?.format('YYYY-MM-DDTHH:mm:ss');
      cell.end = endDate?.format('YYYY-MM-DDTHH:mm:ss');
      cell.tags = e.tags;
      let iconsHtml = '';
      const differenceDate = endDate?.diff(startDate, 'hour') ?? 0;
      const captionLen = e?.caption?.length ?? 0;
      cell.height = 28;
      if (captionLen > 150 || icons.length > 1) {
        cell.height = 44;
      } else if (differenceDate <= 6) {
        if (captionLen > 85) {
          cell.height = 44;
        } else if (captionLen > 45) {
          cell.height = 36;
        }
      }
      icons.forEach((icon) => {
        iconsHtml += `<span title="${icon.caption}" class="scheduler-event-icon material-icons-outlined">${icon.id}</span>`;
      });
      cell.html = `
        <div class="scheduler-event">
          <span class="event-text">${e.caption!}</span>
          <div class="event-icons-wrapper">
            ${iconsHtml}
          </div>
        </div>`;
      cell.bubbleHtml = `<span class="pre-line">${e.caption}\n${e.details}</span>` ?? undefined;

      // cell.duration = e.durationHours;
      switch (e.type?.id) {
        case 1:
          cell.cssClass = 'resource-class';
          break;
        case 2:
          cell.cssClass = 'resource-lesson';
          break;
        case 3:
          cell.cssClass = 'resource-room';
          break;
        case 4:
          cell.cssClass = 'resource-appointment';
          break;
        case 5:
          cell.cssClass = 'resource-referent';
          break;
        default:// unknown?
          console.error(`Unknown resource type received '${e.type?.id}'`);
          cell.cssClass = 'resource-unknown';
          cell.text += ` (Data Error: unmapped type: ${JSON.stringify(e.type)})`;
          break;
      }
      events.push(cell);
    });
    return events;
  }
}
