// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ModulePlanningClassResourceViewModel from '../../../../viewModel/resource/ModulePlanningClassResourceViewModel';
import ModulePlanningClassResourceDTO from '../../dto/resource/ModulePlanningClassResourceDTO';

export default abstract class ModulePlanningClassResourceModel extends BaseModel<ModulePlanningClassResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {int32}
  */
  get primaryLeadLecturerId() { return this.dto.primaryLeadLecturerId; }
  set primaryLeadLecturerId(value) { this.dto.primaryLeadLecturerId = value; }
  /**
  * @type {int32}
  */
  get startYear() { return this.dto.startYear; }
  set startYear(value) { this.dto.startYear = value; }
  /**
  */
  get isPrototype() { return this.dto.isPrototype; }
  set isPrototype(value) { this.dto.isPrototype = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.primaryleadlecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.primaryLeadLecturerId = value;
    value = Number.parseFloat(qs.startyear);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.startYear = value;
    value = qs.isPrototype === 'true' ? true : qs.isprototype;
    value = qs.isPrototype === 'false' ? false : undefined;
    this.dto.isPrototype = value;
  }
}
