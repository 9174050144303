import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetApi from '../generated/api/Widget';

export default (service: Servicelayer) => ({
  ...WidgetApi(service),

  // Add custom calls here

});
