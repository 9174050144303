<template>
  <p-container>
    <h1>{{ $t('page.dashboard.title') }}</h1>
    <p-row>
      <p-col v-if="page.isPlanningAdmin" xs12 md6 lg4 xl3>
        <p-card fill-height>
          <p-row column>
            <h4>{{ $t('page.dashboard.scheduler.title') }}</h4>
            <span class="caption-1">{{ $t('page.dashboard.scheduler.description') }}</span>
          </p-row>
          <p-toolbar>
            <p-button @click="gotoScheduler()">
              {{ $t('page.dashboard.scheduler.button') }}
            </p-button>
          </p-toolbar>
        </p-card>
      </p-col>
      <p-col v-if="!page.isPlanningAdmin" xs12 md6 lg4 xl3>
        <p-card fill-height>
          <p-row column>
            <h4>Pensum</h4>
            <span v-if="page.workloadBreakdown.length>0" class="caption-1">
              <div class="mb-2">
                <p-row
                  v-for="summaryEntry in page.workloadBreakdown"
                  :key="summaryEntry.name"
                  no-gutters
                  class="caption-1"
                  :class="summaryEntry.isIncludedInWorkload ? 'text-color-text-lighten-1' : 'text-color-text-lighten-3'"
                >
                  <p-col grow>
                    <span>{{ summaryEntry.name }}</span>
                  </p-col>
                  <p-col shrink>
                    <span class="mr-1">{{ `${summaryEntry.totalDays}d` }}</span>
                  </p-col>
                </p-row>
              </div></span>
            <span v-if="page.workloadBreakdown.length==0" class="caption-1">
              {{ $t('page.dashboard.pensum.nodata') }}</span>
          </p-row>
          <p-toolbar>
            <p-button @click="gotoLessons()">
              {{ $t('page.dashboard.lesson.button') }}
            </p-button>
          </p-toolbar>
        </p-card>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import DashboardPage from './dashboard.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      page: new DashboardPage(),
      visible: true,
    }),
    computed: {
      defaultYear(): number {
        const date = Vue.$date(`${Vue.$date.now().year()}-07-31`);
        if (Vue.$date.now() > date) {
          return Vue.$date.now().year() + 1;
        }
        return Vue.$date.now().year();
      },
    },
    async mounted() {
      this.page.initialize();
    },
    methods: {
      gotoScheduler() {
        this.$router.push('/agogis/cockpit/agenda/?tab=0');
      },
      gotoLessons() {
        this.$router.push(`/agogis/cockpit/schedule-planning/lessons?classYear=${this.defaultYear}`);
      },
    },

  });
</script>
