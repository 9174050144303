// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AgogisUserSchema from './agogis-user-schema';
import LectureWorkloadSummaryStatusItemSchema from './lecture-workload-summary-status-item-schema';

const schema = {
  $id: '/lecturer-workload-summary-schema',
  type: 'object',
  required: ['classYear', 'totalDays', 'totalDaysTT'],
  properties: {
    classYear: {
      type: 'integer',
    },
    totalDays: {
      type: 'number',
    },
    totalDaysTT: {
      type: 'number',
    },
    lecturer: {
      ...AgogisUserSchema,
    },
    summaryItemsByStatus: {
      type: 'array',
      nullable: true,
      items: { ...LectureWorkloadSummaryStatusItemSchema },
    },
  },
} as IJSONSchema;

export default schema;
