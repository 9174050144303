// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ITTObjectViewModel from '../../../../viewModel/resource/ITTObjectViewModel';
import LectureLessonResourceViewModel from '../../../../viewModel/resource/LectureLessonResourceViewModel';
import LectureLessonResourceDTO from '../../dto/resource/LectureLessonResourceDTO';

export default abstract class LectureLessonResourceModel extends BaseModel<LectureLessonResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get lectureClassId() { return this.dto.lectureClassId; }
  set lectureClassId(value) { this.dto.lectureClassId = value; }
  /**
  */
  get lectureClassName() { return this.dto.lectureClassName; }
  set lectureClassName(value) { this.dto.lectureClassName = value; }
  /**
  * @type {int64}
  */
  get lectureModuleId() { return this.dto.lectureModuleId; }
  set lectureModuleId(value) { this.dto.lectureModuleId = value; }
  /**
  */
  get lectureModuleName() { return this.dto.lectureModuleName; }
  set lectureModuleName(value) { this.dto.lectureModuleName = value; }
  /**
  */
  get lectureModuleDescription() { return this.dto.lectureModuleDescription; }
  set lectureModuleDescription(value) { this.dto.lectureModuleDescription = value; }
  /**
  */
  get lessonName() { return this.dto.lessonName; }
  set lessonName(value) { this.dto.lessonName = value; }
  /**
  * @type {date-time}
  */
  get startTime() { return this.dto.startTime; }
  set startTime(value) { this.dto.startTime = value; }
  /**
  * @type {date-time}
  */
  get endTime() { return this.dto.endTime; }
  set endTime(value) { this.dto.endTime = value; }
  /**
  * @type {double}
  */
  get duration() { return this.dto.duration; }
  set duration(value) { this.dto.duration = value; }
  /**
  * @type {int64}
  */
  get toccoLessonId() { return this.dto.toccoLessonId; }
  set toccoLessonId(value) { this.dto.toccoLessonId = value; }
  /**
  * @type {int64}
  */
  get toccoClassId() { return this.dto.toccoClassId; }
  set toccoClassId(value) { this.dto.toccoClassId = value; }
  /**
  */
  get teamTeaching() { return this.dto.teamTeaching; }
  set teamTeaching(value) { this.dto.teamTeaching = value; }
  /**
  */
  get comment_TT() { return this.dto.comment_TT; }
  set comment_TT(value) { this.dto.comment_TT = value; }
  /**
  */
  get ttComment() { return this.getObjectModel(() => this.dto.ttComment, ITTObjectViewModel)!; }
  set ttComment(value) { this.setObjectModel(() => this.dto.ttComment, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.lectureclassid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lectureClassId = value;
    this.dto.lectureClassName = qs.lectureclassname;
    value = Number.parseFloat(qs.lecturemoduleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lectureModuleId = value;
    this.dto.lectureModuleName = qs.lecturemodulename;
    this.dto.lectureModuleDescription = qs.lecturemoduledescription;
    this.dto.lessonName = qs.lessonname;
    this.dto.startTime = qs.starttime;
    this.dto.endTime = qs.endtime;
    value = Number.parseFloat(qs.duration);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.duration = value;
    value = Number.parseFloat(qs.toccolessonid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.toccoLessonId = value;
    value = Number.parseFloat(qs.toccoclassid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.toccoClassId = value;
    value = qs.teamTeaching === 'true' ? true : qs.teamteaching;
    value = qs.teamTeaching === 'false' ? false : undefined;
    this.dto.teamTeaching = value;
    this.dto.comment_TT = qs.comment_tt;
  }
}
