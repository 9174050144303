// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/widget-class-content-item-schema',
  type: 'object',
  required: ['order'],
  nullable: true,
  properties: {
    order: {
      type: 'integer',
    },
    isBulletPoint: {
      type: 'boolean',
    },
    text: {
      type: 'string',
      nullable: true,
    },
    wordText: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
