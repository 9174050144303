<template>
  <p-container>
    <p-row column>
      <p-col>
        <h3>{{ $t('page.settings.title.languageSettings') }}</h3>
      </p-col>
      <p-col>
        <p-row>
          <p-button
            v-for="langCode in $translation.appLanguageCodes"
            :key="langCode"
            :secondary="langCode !== $translation.get()"
            spacious
            @click="$translation.set(langCode)"
          >
            {{ langCode.toUpperCase() }}
          </p-button>
        </p-row>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
  });
</script>
