<template>
  <div class="agogis-year-lookup">
    <p-row no-gutters nowrap align-end>
      <p-col align-end shrink>
        <p-button v-if="!noSteppers" :disabled="isPreviousYearDisabled" tertiary class="mr-0" @click="addYear(-1)">
          <p-icon>chevron_left</p-icon>
        </p-button>
        <p-select
          class="yearinput"
          :value="innerValue"
          :loading="isLoading"
          :disabled="disabled"
          :items="items"
          :errors="error"
          v-bind="$props"
          :required="required"
          @input="$emit('input', $event)"
          v-on="$listeners"
        ></p-select>
        <p-button v-if="!noSteppers" :disabled="isNextYearDisabled" tertiary class="ml-0" @click="addYear(1)">
          <p-icon>chevron_right</p-icon>
        </p-button>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import LookupYearsRequestModel from '../../services/v2/model/lookup-years-request-model';
  import Int32LookupModel from '../../services/v2/model/int32-lookup-model';
  import Int32LookupModelBase from '../../services/v2/_generated/modelBases/int32-lookup-model-base';

  export default Vue.extend({
    props: {
      value: { default: undefined, type: Number },
      disabled: { default: false, type: Boolean },
      readonly: { default: false, type: Boolean },
      solo: { default: false, type: Boolean },
      label: { default: undefined, type: String },
      required: { default: false, type: Boolean },
      clearable: { default: false, type: Boolean },
      noSteppers: { default: false, type: Boolean },
    },
    data: () => ({
      isLoading: false,
      items: [] as Int32LookupModel[],
      error: undefined as any,
      innerValue: undefined as number | undefined,
    }),
    computed: {
      isNextYearDisabled(): boolean {
        if (this.isLoading || this.disabled) {
          return true;
        }
        const maxYear = Math.max(...this.items.map((i) => i.id!));
        return !!this.innerValue && this.innerValue >= maxYear;
      },
      isPreviousYearDisabled(): boolean {
        if (this.isLoading || this.disabled) {
          return true;
        }
        const minYear = Math.min(...this.items.map((i) => i.id!));
        return !!this.innerValue && this.innerValue <= minYear;
      },
      defaultYear(): number {
        const date = this.$date(`${this.$date.now().year()}-07-31`);
        if (this.$date.now() > date) {
          return this.$date.now().year() + 1;
        }
        return this.$date.now().year();
      },
    },
    watch: {
      value() {
        if (typeof this.value === 'string') {
          this.$emit('input', `${Number.parseInt(this.value, 10)}/${Number.parseInt(this.value, 10) + 1}`);
        } else {
          this.readValueToInnerValue();
        }
      },
    },
    async beforeMount() {
      if (!this.value) {
        this.$emit('input', this.defaultYear);
      }
      if (this.value) {
        this.readValueToInnerValue();
      }
      this.isLoading = true;
      try {
        const response = await this.$service.v2.api.classes.lookupYears({} as LookupYearsRequestModel, {});
        this.items = response.items.map((x) => { return { ...x.data, caption: x.data.id ? (`${x.data.id}/${(x.data.id + 1)}`) : undefined } as Int32LookupModelBase; });
      } catch (error : any) {
        this.error = error?.message;
      }
      this.isLoading = false;
    },
    methods: {
      readValueToInnerValue() {
        if (typeof this.value === 'string') {
          this.innerValue = Number.parseInt(this.value, 10);
        } else {
          this.innerValue = this.value;
        }
      },
      addYear(amount?: number) {
        if (!this.innerValue) {
          const [firstEntry] = this.items;
          this.innerValue = firstEntry.id!;
          return;
        }
        this.innerValue += (amount ?? 1);
        this.$debounce(() => {
          this.$emit('input', this.innerValue);
        }, 400, this)();
      },
    },
  });
</script>
