<template>
  <!-- Begin Agogis.Widget.CourseTextResources -->
  <div class="angebot content">
    <div v-if="showTitle" class="heading">
      <h2>{{ classInfo.title }}</h2>
      <h3 v-if="classInfo.subtitle">
        <template v-for="(sub, i) in classInfo.subtitle">
          <!--eslint-disable-next-line vue/require-v-for-key-->
          {{ sub }} <br v-if="i < classInfo.subtitle.length-1">
        </template>
      </h3>
    </div>
    <div v-if="showSummary" class="summary highlight pre-line">
      {{ classInfo.summary }}
    </div>
    <div v-if="showAccordion" class="accordion">
      <ul>
        <li v-for="(faq, index) in classInfo.faq" :key="faq.order" class="item">
          <h4 :class="isAccordeonDetailVisible[index]?'open':''" @click="toggleDetail(index)">
            {{ faq.title }}
          </h4>
          <div v-show="isAccordeonDetailVisible[index]" class="detail">
            <div v-for="txt in faq.content" :key="txt.order">
              <p v-if="!txt.isBulletPoint">
                {{ txt.text }}
              </p>
              <ul v-if="txt.isBulletPoint">
                <li v-if="txt.isBulletPoint">
                  {{ txt.text }}
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="showPrint" class="print pdf">
      <a v-if="!isDownloadLoading" @click="onPdfDownload">Download Factsheet</a>
      <progress v-if="isDownloadLoading" id="file" indeterminate="true"></progress>
    </div>
    <div class="debug">
      data-language: {{ $config.values['language'] }}<br>
      data-widget-toccocoursekey: {{ $config.values['widget-toccocoursekey'] }}<br>
      data-widget-textresource-showtitle: {{ $config.values['widget-textresource-showtitle'] }}<br>
      data-widget-textresource-showsummary: {{ $config.values['widget-textresource-showsummary'] }}<br>
      data-widget-textresource-showaccordion: {{ $config.values['widget-textresource-showaccordion'] }}<br>
      data-widget-textresource-showprint: {{ $config.values['widget-textresource-showprint'] }}
    </div>
  </div>
  <!-- End Agogis.Widget.CourseTextResources -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
  import WidgetClassInformationResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassInformationResourceViewModel';
  import WidgetSearchClassInformationRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassInformationRequestViewModel';

  export default Vue.extend({
    layout: 'layout-widget',
    data: () => ({
      classInfo: {} as WidgetClassInformationResourceViewModel,
      isDownloadLoading: false,
      isAccordeonDetailVisible: [] as boolean[],
    }),
    computed: {
      toccoCourseKey(): string {
        return this.$config.values['widget-toccocoursekey'];
      },
      showTitle(): boolean {
        return this.$config.values['widget-textresource-showtitle'];
      },
      showSummary(): boolean {
        return this.$config.values['widget-textresource-showsummary'];
      },
      showAccordion(): boolean {
        return this.$config.values['widget-textresource-showaccordion'];
      },
      showPrint(): boolean {
        return this.$config.values['widget-textresource-showprint'];
      },
    },
    async mounted() {
      try {
        const widgetSearchClassInfoResourceRequestVM = new WidgetSearchClassInformationRequestViewModel();
        widgetSearchClassInfoResourceRequestVM.classTypeKey = this.toccoCourseKey;
        const response = await this.$service.api.widgetClasses.getWidgetClassInfo(widgetSearchClassInfoResourceRequestVM);
        this.classInfo = response;
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
          // = errorResponse.data;
        }
        this.$log.error(e);
      }
    },
    methods: {
      async onPdfDownload() {
        this.isDownloadLoading = true;
        try {
          const widgetSearchClassInfoResourceRequestVM = new WidgetSearchClassInformationRequestViewModel();
          widgetSearchClassInfoResourceRequestVM.classTypeKey = this.toccoCourseKey;
          const response = await this.$service.api.widgetClasses.downloadPdf(widgetSearchClassInfoResourceRequestVM);
          const fileName = `agogis-${this.classInfo.title?.toLocaleLowerCase().split(' ').join('-')}.pdf`;
          this.$service.utils.downloadFile(fileName, response);
        } catch (e) {
          const errorResponse = e as ServiceResponse;
          if (errorResponse.status === 503) {
          // = errorResponse.data;
          }
          this.$log.error(e);
        } finally {
          this.isDownloadLoading = false;
        }
      },
      toggleDetail(index:number) {
        this.$set(this.isAccordeonDetailVisible, index, !this.isAccordeonDetailVisible[index]);
      },
    },
  });
</script>
