<template>
  <p-app theme="glittr">
    <template slot="header">
      <p-toolbar dense>
        <p-button secondary @click="toggleSideDrawerVisible()">
          <p-icon>menu</p-icon>
        </p-button>
        <p-img
          v-if="!$media.isMobile"
          to="/"
          width="180"
          height="28px"
          contain
          :src="require('@src/assets/logo.svg')"
        ></p-img>
        <p-col justify-end align-center>
          <agogis-language-selection class="mr-5"></agogis-language-selection>
          <p-menu v-model="profileMenuVisible" bottom left>
            <template slot="activator">
              <p-button secondary>
                <p-icon>
                  person
                </p-icon>
              </p-button>
            </template>
            <p-card>
              <p-row column>
                <router-link to="/_me/profile">
                  <p-button stretch>
                    {{ $t('core.page.profile.button.showProfile') }}
                    <p-icon right>
                      manage_accounts
                    </p-icon>
                  </p-button>
                </router-link>
                <router-link v-if="isAdmin" to="/_hostadmin/">
                  <p-button stretch>
                    {{ $t("core.page.user.select.hostAdmin") }}
                    <p-icon right>
                      admin_panel_settings
                    </p-icon>
                  </p-button>
                </router-link>
                <p-divider></p-divider>
                <p-button @click="doLogoff">
                  {{ $t('core.page.profile.button.logoff') }}
                  <p-icon right>
                    exit_to_app
                  </p-icon>
                </p-button>
              </p-row>
            </p-card>
          </p-menu>
        </p-col>
      </p-toolbar>
    </template>

    <p-side-drawer
      v-model="isSidedrawerVisible"
      :overlay="$media.isMobile"
      :minified.sync="isNavMinifed"
      minifiable
      color="base"
      class="main-navigation"
    >
      <template #drawer-content>
        <p-list-group>
          <p-list spacious>
            <p-list-entry to="/agogis/cockpit/" color="white" icon-left="dashboard">
              {{ $t('navigation.home.name') }}
            </p-list-entry>
            <p-list-entry v-if="isPlanningAdmin" to="/agogis/cockpit/agenda/?tab=0" color="white" icon-left="event">
              {{ $t('navigation.scheduler.name') }}
            </p-list-entry>
            <p-list-group icon-left="view_timeline" color="white">
              <template #activator>
                {{ $t('navigation.planning.name') }}
              </template>
              <p-list-entry
                v-if="isPlanningAdmin"
                :to="`/agogis/cockpit/schedule-planning/modules?classyear=${defaultYear}`"
                color="white"
                icon-left="view_quilt"
              >
                {{ $t('navigation.modulePlanning.name') }}
              </p-list-entry>
              <p-list-entry
                :to="`/agogis/cockpit/schedule-planning/lessons?classyear=${defaultYear}`"
                color="white"
                icon-left="list"
              >
                {{ $t('navigation.lesson.name') }}
              </p-list-entry>
              <p-list-entry v-if="isPlanningAdmin" to="/agogis/cockpit/notifications/" color="white" icon-left="mail">
                {{ $t('navigation.notifications.name') }}
              </p-list-entry>
            </p-list-group>
            <p-list-group v-if="isLocalhost" icon-left="widgets" color="white">
              <template #activator>
                {{ $t('navigation.widgets.name') }}
              </template>
              <p-list-entry color="white" to="/_widgets/" icon-left="account_tree" @click="navigationClicked">
                {{ $t('navigation.widgets.overview.name') }}
              </p-list-entry>
              <p-list-entry color="white" to="/_widgets/nextEvents" icon-left="account_tree" @click="navigationClicked">
                {{ $t('navigation.widgets.nextEvents.name') }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_widgets/nextCourses"
                icon-left="account_tree"
                @click="navigationClicked"
              >
                {{ $t('navigation.widgets.nextCourses.name') }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_widgets/courseInfoEvents"
                icon-left="account_tree"
                @click="navigationClicked"
              >
                {{ $t('navigation.widgets.courseInfoEvents.name') }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_widgets/searchCourses"
                icon-left="account_tree"
                @click="navigationClicked"
              >
                {{ $t('navigation.widgets.searchCourses.name') }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_widgets/registerCourse?id=15216"
                icon-left="account_tree"
                @click="navigationClicked"
              >
                {{ $t('navigation.widgets.registerCourse.name') }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_widgets/eventsByDate"
                icon-left="account_tree"
                @click="navigationClicked"
              >
                {{ $t('navigation.widgets.eventsByDate.name') }}
              </p-list-entry>
              <p-list-entry color="white" to="/_widgets/poi" icon-left="account_tree" @click="navigationClicked">
                {{ $t('navigation.widgets.poi.name') }}
              </p-list-entry>
            </p-list-group>
            <p-list-group v-if="isAdmin" color="white" icon-left="memory">
              <template #activator>
                Integration
              </template>
              <p-list-entry color="white" to="/_admin/processes" icon-left="account_tree" @click="navigationClicked">
                {{ $t("core.page.processes.page.title") }}
              </p-list-entry>
              <p-list-entry
                color="white"
                :to="`/_admin/integrationlog?dateFrom=${date}`"
                icon-left="merge_type"
                @click="navigationClicked"
              >
                {{ $t("core.page.integrationlog.page.title") }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_admin/contactidmapping"
                icon-left="social_distance"
                @click="navigationClicked"
              >
                {{ $t("core.page.contactidmapping.page.title") }}
              </p-list-entry>
              <p-list-entry
                color="white"
                to="/_admin/fieldmapping"
                icon-left="compare_arrows"
                @click="navigationClicked"
              >
                {{ $t('core.page.fieldmapping.page.list.title') }}
              </p-list-entry>
            </p-list-group>
          </p-list>
        </p-list-group>
      </template>
      <template v-if="isAdmin" #drawer-footer-actions>
        <p-button :secondary="isSettingsVisible" :tertiary="!isSettingsVisible" @click="toggleSettings">
          <p-icon>
            settings
          </p-icon>
        </p-button>
      </template>
      <template #content>
        <p-side-drawer v-model="isSettingsVisible" :overlay="$media.isMobile">
          <template #drawer-header-image>
            <p-icon>
              settings
            </p-icon>
          </template>
          <template #drawer-header-title>
            {{ $t("admin.settings.page.title") }}
          </template>
          <template #drawer-content>
            <p-list-group>
              <p-list class="mt-3" spacious>
                <p-list-entry to="/_admin/dashboard" icon-left="dashboard" @click="settingsClicked">
                  {{ $t('core.page.dashboard.page.title') }}
                </p-list-entry>
                <p-list-entry
                  :to="`/_admin/applog?levels=error&dateFrom=${date}`"
                  icon-left="list"
                  @click="settingsClicked"
                >
                  {{ $t('core.page.appLog.page.title') }}
                </p-list-entry>
                <p-list-entry :to="`/_admin/calllog?dateFrom=${date}`" icon-left="list" @click="settingsClicked">
                  {{ $t('core.page.callLog.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/users" icon-left="person" @click="settingsClicked">
                  {{ $t('core.page.user.page.title') }}
                </p-list-entry>
                <p-list-entry to="/_admin/sequencenumbers" icon-left="pin" @click="settingsClicked">
                  {{ $t('core.page.sequencenumbers.page.title') }}
                </p-list-entry>
              </p-list>
            </p-list-group>
          </template>
          <template v-if="$media.isMobile" #drawer-footer-actions>
            <p-button tertiary @click="isSettingsVisible = false">
              <p-icon>
                arrow_back
              </p-icon>
            </p-button>
          </template>
          <template #content>
            <slot></slot>
          </template>
        </p-side-drawer>
      </template>
    </p-side-drawer>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    data: () => ({
      date: Vue.$date.now().format('YYYY-MM-DD'),
      profileMenuVisible: false,
      isSidedrawerVisible: true,
      isSidedrawerMinified: false,
      isMobile: false,
      isSettingsVisible: false,
      isNavMinifed: false,
    }),
    computed: {
      defaultYear(): number {
        const date = Vue.$date(`${Vue.$date.now().year()}-07-31`);
        if (Vue.$date.now() > date) {
          return Vue.$date.now().year() + 1;
        }
        return Vue.$date.now().year();
      },
      user() {
        return this.$auth.user ?? {};
      },
      isPlanningAdmin() {
        return this.$service.v2.api.me.getAmIPlanningAdmin();
      },
      isAdmin() {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
      isLocalhost(): boolean {
        const route = window.location.hostname;
        return route === 'localhost';
      },
    },
    watch: {
      isNavMinifed() {
        this.$sessionStorage.set('isNavMinifed', this.isNavMinifed);
      },
    },
    beforeMount() {
      if (this.$media.isNotMobile) {
        this.isSidedrawerVisible = this.$sessionStorage.get<boolean>('isSidedrawerVisible') ?? true;
        this.isSettingsVisible = this.isAdmin && (this.$sessionStorage.get<boolean>('isSettingsVisible') ?? false);
        this.isNavMinifed = this.$sessionStorage.get<boolean>('isNavMinifed') ?? false;
      }
    },
    methods: {
      async doLogoff() {
        try {
          await this.$auth.logout();
          this.$router.push('/_exterior/login');
        } catch (error) {
          this.$log.error('Unable to log out an error occurred');
          this.$log.error(error);
        }
      },
      toggleSettings() {
        this.isSettingsVisible = !this.isSettingsVisible;
        if (this.$media.isMobile) {
          this.toggleSideDrawerVisible(false);
        } else {
          this.isNavMinifed = this.isSettingsVisible;
        }
        this.$sessionStorage.set('isSettingsVisible', this.isSettingsVisible);
      },
      navigationClicked() {
        if (this.$media.isMobile) {
          this.toggleSideDrawerVisible(false);
        }
      },
      settingsClicked() {
        if (this.$media.isMobile) {
          this.toggleSettings();
        }
      },
      toggleSideDrawerVisible(isVisible?: boolean) {
        const newVisibleState = isVisible ?? !this.isSidedrawerVisible;
        this.isSidedrawerVisible = newVisibleState;
        this.$sessionStorage.set('isSidedrawerVisible', this.isSidedrawerVisible);
      },
    },
  });
</script>
