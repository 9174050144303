// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LookupLecturerModulesRequestViewModel from '../../viewModel/request/Modules/LookupLecturerModulesRequestViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async lookupLecturerModules(classYear: number, request: LookupLecturerModulesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-lecturer-modules'] ?? 'Modules/Lookup/ClassYear/{classYear}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${classYear}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        classId: requestDTO.classId,
        classIds: requestDTO.classIds,
        moduleId: requestDTO.moduleId,
        lectureLessonStatusId: requestDTO.lectureLessonStatusId,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
});
