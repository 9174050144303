<template>
  <div class="p-table agogis-table">
    <table class="p-table zebra dense">
      <th class="caption-1 py-1 table-header-selection">
        <p-checkbox :value="!hasUnselectedItems(value)" :disabled="!value" @input="setIsSelectAllSelectable($event, value)">
        </p-checkbox>
      </th>
      <th
        v-for="(column, index) in columns"
        :key="`header${index}`"
        class="caption-1 py-1 "
        :class="{ [`$(column.class) table-header-${column.field}`]: true }"
        style="text-align: left"
      >
        {{ column.value }}
      </th>
      <th v-if="gotoDetailUrl" class="caption-1 py-1 table-header-gotoDetail"></th>
      <template v-for="(record, index) in value">
        <tr v-if="isDataRecord(record)" :key="`tableRow${index}`" :class="{ 'color-focus-lighten-3': record.isSelected, 'p-table-row': true }">
          <td class="table-cell-selection">
            <p-checkbox v-model="record.isSelected" :disabled="getIsDisabled(record)" @input="$emit('onItemsSelectionChanged', record)">
            </p-checkbox>
          </td>
          <td v-for="(column, indexcol) in columns" :key="`tableCell${indexcol}`" :class="{ [`table-cell-${column.field}`]: true }" :style="{ 'text-align': column.textAlign ? column.textAlign : 'left' }">
            <nobr>
              {{ getFormattedDisplay(record, column) }}
              <div v-if="column.popUpIndex !== undefined && record.data[column.field[column.popUpIndex]]" class="info-tooltip" :title="record.data[column.field[column.popUpIndex]]">
                <p-icon color="interaction">
                  info_outline
                </p-icon>
              </div>
            </nobr>
          </td>
          <td class="table-cell-selection">
            <p-button v-if="gotoDetailUrl && record.data.id" dense @click="$router.push(gotoDetailUrl + record.data.id)">
              <p-icon>search</p-icon>
            </p-button>
          </td>
        </tr>
        <slot v-else name="specialRow" :item="record"></slot>
      </template>
    </table>
  </div>
</template>
<script lang="ts">
  import DataRecord, { isDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
  import Vue, { PropType } from 'vue';

  const StringFormat = (str: string, args: string[]) => str.replace(/{(\d+)}/g, (match, index) => args[index] || '');

  export interface IAgogisTableColumn {
    field: string | string[],
    value?: string,
    textAlign?: string,
    // eslint-disable-next-line no-unused-vars
    format?: (value: any) => string,
    formatString?: string,
    popUpIndex?: number,
    class:string
  }

  export default Vue.extend({
    name: 'AgogisTable',
    props: {
      value: { type: Array as PropType<DataRecord<any>[]>, default: () => [] as DataRecord<any>[], validator(values: any[]) { return values?.every((i) => isDataRecord(i)); } },
      columns: { type: Array as PropType<IAgogisTableColumn[]>, default: () => [] as IAgogisTableColumn[] },
      disabledFunction: { type: Function, default: undefined },
      isDataRecordFunction: { type: Function, default: undefined },
      gotoDetailUrl: { type: String, default: undefined },
    },
    data: () => ({

    }),
    methods: {
      hasUnselectedItems(items: DataRecord<any>[]) {
        return items && items.some((element) => element.isSelected === false && !this.getIsDisabled(element));
      },
      setIsSelectAllSelectable(newValue:boolean, items: DataRecord<any>[]) {
        if (items) {
          items.forEach((element) => {
            if (!this.getIsDisabled(element)) {
              element.isSelected = newValue;
            }
          });
          this.$emit('onItemsSelectionChanged');
        }
      },
      getIsDisabled(item:DataRecord<any>):boolean {
        return this.disabledFunction !== undefined && (!(item?.data) || this.disabledFunction(item.data));
      },
      isDataRecord(item:DataRecord<any>):boolean {
        return this.isDataRecordFunction === undefined || ((item?.data) && this.isDataRecordFunction(item.data));
      },
      getFormattedDisplay(record: DataRecord<any>, column: IAgogisTableColumn) {
        let fields :string[] = [];
        if (Array.isArray(column.field)) {
          fields = [...column.field as string[]];
        } else {
          fields = [column.field];
        }
        const values = fields.map<string>((field) => record?.data[field]);
        if (column.popUpIndex) {
          values.splice(column.popUpIndex, 1);
        }
        if (values.length > 1) {
          return StringFormat(column.formatString || '', values);
        }
        if (column.format && typeof column.format === 'function') {
          return column.format(values[0]);
        }
        return values[0];
      },
    },
  });
</script>
