// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LectureLessonHistoryListResourceViewModel from '../../../../viewModel/resource/LectureLessonHistoryListResourceViewModel';
import LectureLessonHistoryListResourceDTO from '../../dto/resource/LectureLessonHistoryListResourceDTO';

export default abstract class LectureLessonHistoryListResourceModel extends BaseModel<LectureLessonHistoryListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get lectureLessonId() { return this.dto.lectureLessonId; }
  set lectureLessonId(value) { this.dto.lectureLessonId = value; }
  /**
  * @type {int64}
  */
  get lecturerId() { return this.dto.lecturerId; }
  set lecturerId(value) { this.dto.lecturerId = value; }
  /**
  */
  get lecturerName() { return this.dto.lecturerName; }
  set lecturerName(value) { this.dto.lecturerName = value; }
  /**
  */
  get statusOld() { return this.dto.statusOld; }
  set statusOld(value) { this.dto.statusOld = value; }
  /**
  */
  get statusNew() { return this.dto.statusNew; }
  set statusNew(value) { this.dto.statusNew = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  */
  get adminComment() { return this.dto.adminComment; }
  set adminComment(value) { this.dto.adminComment = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.lecturelessonid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lectureLessonId = value;
    value = Number.parseFloat(qs.lecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lecturerId = value;
    this.dto.lecturerName = qs.lecturername;
    this.dto.statusOld = qs.statusold;
    this.dto.statusNew = qs.statusnew;
    this.dto.comment = qs.comment;
    this.dto.adminComment = qs.admincomment;
  }
}
