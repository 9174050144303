// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/export-lessons-request-schema',
  type: 'object',
  required: ['classYear', 'userId'],
  properties: {
    classYear: {
      type: 'integer',
    },
    moduleIds: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    classIds: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    lectureLessonStatusIds: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    eventTypeIds: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    userId: {
      type: 'integer',
    },
    lecturerId: {
      type: 'integer',
    },
    primaryLeadLecturerId: {
      type: 'integer',
    },
    isPrototype: {
      type: 'boolean',
    },
    searchCriteria: {
      type: 'string',
    },
    'paging.CurrentPage': {
      type: 'integer',
    },
    'paging.PageSize': {
      type: 'integer',
    },
    'paging.IncludeCount': {
      type: 'boolean',
    },
    'paging.NoPaging': {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
