// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ITTObjectViewModel from '../../../../viewModel/resource/ITTObjectViewModel';
import LectureLessonLecturerResourceViewModel from '../../../../viewModel/resource/LectureLessonLecturerResourceViewModel';
import LectureLessonLecturerResourceDTO from '../../dto/resource/LectureLessonLecturerResourceDTO';

export default abstract class LectureLessonLecturerResourceModel extends BaseModel<LectureLessonLecturerResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get lecturerId() { return this.dto.lecturerId; }
  set lecturerId(value) { this.dto.lecturerId = value; }
  /**
  * @type {int64}
  */
  get lecturerRoleId() { return this.dto.lecturerRoleId; }
  set lecturerRoleId(value) { this.dto.lecturerRoleId = value; }
  /**
  */
  get lecturerRoleName() { return this.dto.lecturerRoleName; }
  set lecturerRoleName(value) { this.dto.lecturerRoleName = value; }
  /**
  */
  get lecturerFirstName() { return this.dto.lecturerFirstName; }
  set lecturerFirstName(value) { this.dto.lecturerFirstName = value; }
  /**
  */
  get lecturerLastName() { return this.dto.lecturerLastName; }
  set lecturerLastName(value) { this.dto.lecturerLastName = value; }
  /**
  */
  get lecturerFullName() { return this.dto.lecturerFullName; }
  set lecturerFullName(value) { this.dto.lecturerFullName = value; }
  /**
  */
  get lecturerStatusName() { return this.dto.lecturerStatusName; }
  set lecturerStatusName(value) { this.dto.lecturerStatusName = value; }
  /**
  */
  get lecturerComment() { return this.dto.lecturerComment; }
  set lecturerComment(value) { this.dto.lecturerComment = value; }
  /**
  */
  get ttComment() { return this.getObjectModel(() => this.dto.ttComment, ITTObjectViewModel)!; }
  set ttComment(value) { this.setObjectModel(() => this.dto.ttComment, value); }
  /**
  */
  get lecturerAdminComment() { return this.dto.lecturerAdminComment; }
  set lecturerAdminComment(value) { this.dto.lecturerAdminComment = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.lecturerid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lecturerId = value;
    value = Number.parseFloat(qs.lecturerroleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lecturerRoleId = value;
    this.dto.lecturerRoleName = qs.lecturerrolename;
    this.dto.lecturerFirstName = qs.lecturerfirstname;
    this.dto.lecturerLastName = qs.lecturerlastname;
    this.dto.lecturerFullName = qs.lecturerfullname;
    this.dto.lecturerStatusName = qs.lecturerstatusname;
    this.dto.lecturerComment = qs.lecturercomment;
    this.dto.lecturerAdminComment = qs.lectureradmincomment;
  }
}
