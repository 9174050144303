import ITTObjectModelBase from '../services/v2/_generated/modelBases/itt-object-model-base';
import ITTObjectModel from '../services/v2/model/itt-object-model';

export default class BookingCommentTTComponentModel {
  comment:ITTObjectModelBase|undefined = new ITTObjectModel();
  label:string = '';
  isReadOnly:boolean = true;
  canEdit:boolean = false;

  initialize(label:string, isReadOnly: boolean, comments: ITTObjectModel[]) {
    this.isReadOnly = isReadOnly;
    this.label = label;

    const commentsDistincted = [...new Set(comments.map((x) => `${x?.isTT}${x?.ttComment}${x?.comment}`))];
    this.canEdit = commentsDistincted.length === 1 && !isReadOnly;
    this.comment = this.canEdit ? comments[0] : undefined;
  }
  updateComments(comments: ITTObjectModel[]) {
    const commentsDistincted = [...new Set(comments.map((x) => `${x?.isTT}${x?.ttComment}${x?.comment}`))];
    this.canEdit = commentsDistincted.length === 1 && !this.isReadOnly;
    this.comment = this.canEdit ? comments[0] : undefined;
  }
}
