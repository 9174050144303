<template>
  <!-- Begin Agogis.Widget.SearchCourses -->
  <div class="search angebote">
    <div class="inputfilter">
      <label for="txtSearchCourses">Geben Sie das gewünschte Angebot ein<br>
        <input id="txtSearchCourses" v-model="searchTerm" type="text" @input="onSearchInput" @keypress.enter.prevent="onSearchInput" />
        <a @click="clearSearch">alle Angebote</a>
      </label>
      <!-- <div class="result">
        <div>Resultat1</div>
        <div>Resultat2</div>
        <div>Resultat3</div>
        <div>Resultat4</div>
        <div>Resultat5</div>
      </div> -->
    </div>
    <div class="liste angebote">
      <agogis-widget-course-card v-for="e in courses" :key="e.id" :value="e"></agogis-widget-course-card>
      <div v-if="!isResultAvailable && !isLoading" class="item">
        <div class="image">
          <div class="backgroundimage" :style="{'background-image':`url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)`}"></div>
        </div>
        <div class="text_wrapper">
          <div class="row">
            <div class="col-12">
              <div class="titel">
                Ist Ihr gewünschtes Angebot noch nicht ersichtlich?
              </div>
              <div>
                <p>
                  Versuchen Sie es mit einer Eingabe nach dem Alphabet oder kontaktieren Sie unsere Bildungsadministration unter <a href="tel:+41433667110">043 366 71 10</a>.<br>
                  <br>
                  Unsere Bildungsangebote werden fortlaufend geplant und publiziert.<br>
                  Bitte besuchen Sie unsere Webseite regelmässig.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loading">
        <progress id="file" indeterminate="true"></progress>
      </div>

      <div class="debug">
        data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
        data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
        data-language: {{ $config.values['language'] }}<br>
        searchTerm: {{ searchTerm }}<br>
        searchTag: {{ searchTag }}<br>
      </div>
    </div>
    <!-- End Agogis.Widget.NextCourses -->
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';
  import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
  import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';

  export default Vue.extend({
    layout: 'layout-widget',
    data: () => ({
      courses: [] as WidgetClassListResourceViewModel[],
      searchTerm: '' as string,
      searchTag: '' as string,
      isLoading: true as boolean,
      isTermineVisible: [] as boolean[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
      isResultAvailable():boolean {
        if (this.courses.length === 0) { return false; }
        return true;
      },
    },
    async mounted() {
      const qp = this.$routerUtils.getQueryParams();
      if (qp.search !== undefined) {
        this.searchTerm = qp.search;
      }
      if (qp.tag !== undefined) {
        this.searchTag = qp.tag;
      }
      this.doSearch();
    },
    methods: {
      async onSearchInput() {
        this.$debounce(this.doSearch, 500, this)();
      },
      async clearSearch() {
        this.searchTerm = '';
        this.doSearch();
      },
      async doSearch() {
        try {
          this.isLoading = true;
          const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
          widgetSearchClassRequestVM.searchCriteria = this.searchTerm;
          widgetSearchClassRequestVM.searchTag = this.searchTag;
          widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
          const page = new ApiPagingRequestViewModel();
          page.pageSize = this.pageSize;
          widgetSearchClassRequestVM.paging = page;
          const response = await this.$service.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
          this.courses = response.items;
        } catch (e) {
          this.$log.error(e);
        } finally {
          this.isLoading = false;
        }
      },
      toggleTermine(id:number) {
        this.$set(this.isTermineVisible, id, !this.isTermineVisible[id]);
      },
    },
  });
</script>
