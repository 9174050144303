<template>
  <p-container>
    <h1>{{ $t('page.dashboard.title') }}</h1>
    <p-row>
      <p-col xs12 md6 lg4 xl3>
        <p-card fill-height>
          <p-row column>
            <h4>{{ $t('navigation.modulePlanning.name') }}</h4>
            <span class="caption-1">{{ $t('page.dashboard.modulePlanning.description') }}</span>
            <p-button @click="gotoModulePlanning()">
              {{ $t('page.dashboard.modulePlanning.button') }}
            </p-button>
          </p-row>
        </p-card>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    methods: {
      gotoModulePlanning() {
        this.$router.push('/agogis/cockpit/schedule-planning/modules');
      },
    },
  });
</script>
