<template>
  <!-- Begin Agogis.Widget.POI -->
  <table v-if="page.reservationsDataSource.data" class="liste poi">
    <tr>
      <th class="veranstaltung">
        Veranstaltung
      </th>
      <th class="datum-zeit">
        Datum / Zeit
      </th>
      <th class="raum">
        Raum
      </th>
      <th class="doz-ref">
        Doz / Ref
      </th>
    </tr>
    <tr v-for="res in page.reservationsDataSource.data.items" :key="res.data.id">
      <td>
        {{ res.data.eventName }}
      </td>
      <td>
        {{ res.data.getDateTimeString() }}
      </td>
      <td>
        {{ res.data.getRoomString() }}
      </td>
      <td>
        {{ res.data.getLecturersString() }}
      </td>
    </tr>
    <tr v-if="page.reservationsDataSource.paging.totalPages && page.reservationsDataSource.paging.totalPages>1" class="pageinformationrow">
      <td colspan="4">
        <div class="pageinformation">
          Seite {{ page.pageNumber }} / {{ page.pageCount }}
        </div>
      </td>
    </tr>
    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-widget-poi-autorefresh: {{ $config.values['widget-poi-autorefresh'] }}<br>
      data-language: {{ $config.values['language'] }}
    </div>
  </table>
  <!-- End Agogis.Widget.POI -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import WidgetPoiPage from './widget-poi.vue.model';

  export default Vue.extend({
    layout: 'layout-widget',
    auth: false,
    data: () => ({
      page: new WidgetPoiPage(),
    }),
    async mounted() {
      this.page.initialize();
    },
  });
</script>
