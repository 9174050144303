// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactAddressSchema from './contact-address-schema';
import ContactCorrespondenceAddressSchema from './contact-correspondence-address-schema';
import DynamicFormPayloadSchema from './dynamic-form-payload-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/widget-class-register-request-request-schema',
  type: 'object',
  required: ['eventId', 'contactAddress', 'employmentGradePercent', 'id'],
  properties: {
    eventId: {
      type: 'integer',
    },
    contactAddress: {
      ...ContactAddressSchema,
    },
    invoiceAddress: {
      ...ContactCorrespondenceAddressSchema,
    },
    correspondenceAddress: {
      ...ContactCorrespondenceAddressSchema,
    },
    registrationAnswers: {
      ...DynamicFormPayloadSchema,
    },
    hasDyslexia: {
      type: 'boolean',
      nullable: true,
    },
    dyslexiaCertificateDocumentId: {
      type: 'string',
      nullable: true,
    },
    hasAdhd: {
      type: 'boolean',
      nullable: true,
    },
    adhdCertificateDocumentId: {
      type: 'string',
      nullable: true,
    },
    isForeignLanguage: {
      type: 'boolean',
      nullable: true,
    },
    toccoNativeLanguageId: {
      type: 'integer',
      nullable: true,
    },
    schoolEducationCountry: {
      type: 'string',
      nullable: true,
    },
    hasVisualImpairment: {
      type: 'boolean',
      nullable: true,
    },
    visualImpairmentCertificateDocumentId: {
      type: 'string',
      nullable: true,
    },
    hasHearingImpairment: {
      type: 'boolean',
      nullable: true,
    },
    hearingImpairmentCertificateDocumentId: {
      type: 'string',
      nullable: true,
    },
    hasOtherDisabilities: {
      type: 'boolean',
      nullable: true,
    },
    otherDisabilitiesComment: {
      type: 'string',
      nullable: true,
    },
    employmentPosition: {
      type: 'string',
      nullable: true,
    },
    employmentArea: {
      type: 'string',
      nullable: true,
    },
    employmentGradePercent: {
      type: 'integer',
    },
    employmentStartDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    comment: {
      type: 'string',
      nullable: true,
    },
    marketingAnswers: {
      ...DynamicFormPayloadSchema,
    },
    requestSummary: {
      ...RequestSummarySchema,
    },
    acceptNewsletter: {
      type: 'boolean',
    },
    acceptGdpr: {
      type: 'boolean',
    },
    id: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
