// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateLessonAssignmentRequestModel from '../../model/update-lesson-assignment-request-model';
import UpdateLessonAssignmentRequestDTO from '../dto/update-lesson-assignment-request-dto';
import dtoSchema from '../schemas/update-lesson-assignment-request-schema';
import TTObjectModel from '../../model/tt-object-model';

export default abstract class UpdateLessonAssignmentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateLessonAssignmentRequestDTO>) {
    super();
    if (data) {
      this.updateComment = data.updateComment;
      this.comment = data.comment ? TTObjectModel.toModel(data.comment) : undefined;
      this.updateAdminComment = data.updateAdminComment;
      this.adminComment = data.adminComment ?? undefined;
      this.selectedLessonIds = data.selectedLessonIds;
      this.agogisUserId = data.agogisUserId;
      this.lectureLessonStatusId = data.lectureLessonStatusId;
    }
  }
  /**
  */
  updateComment?: boolean;
  /**
  */
  comment?: TTObjectModel;
  /**
  */
  updateAdminComment?: boolean;
  /**
  */
  adminComment?: string;
  /**
  */
  selectedLessonIds?: number[];
  /**
  * @type {int64}
  */
  agogisUserId?: number;
  /**
  * @type {int64}
  */
  lectureLessonStatusId?: number;

  static toModel(dto: DeepPartial<UpdateLessonAssignmentRequestDTO>): UpdateLessonAssignmentRequestModel;
  static toModel(dto: DeepPartial<UpdateLessonAssignmentRequestDTO> | undefined | null): UpdateLessonAssignmentRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateLessonAssignmentRequestDTO> | undefined | null): UpdateLessonAssignmentRequestModel | undefined {
    return dto ? new UpdateLessonAssignmentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateLessonAssignmentRequestModel> | UpdateLessonAssignmentRequestModel): UpdateLessonAssignmentRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      updateComment: unwrapped.updateComment,
      comment: unwrapped.comment,
      updateAdminComment: unwrapped.updateAdminComment,
      adminComment: unwrapped.adminComment,
      selectedLessonIds: unwrapped.selectedLessonIds,
      agogisUserId: unwrapped.agogisUserId,
      lectureLessonStatusId: unwrapped.lectureLessonStatusId,
    } as UpdateLessonAssignmentRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateLessonAssignmentRequestModel, can not map to UpdateLessonAssignmentRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
