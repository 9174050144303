import Vue, { VueConstructor } from 'vue';
import AuthenticationStrategy from '@glittr/frontend-core/src/plugins/auth/authenticationStrategy';
import { CoreOptions } from '@glittr/frontend-core';
import NoopAuthStrategy from '@glittr/frontend-core/src/plugins/auth/strategies/noop';
import BearerTokenAuthStrategy from './strategies/bearerToken';

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $auth: AuthenticationStrategy;
  }
  // eslint-disable-next-line no-shadow
  interface VueConstructor {
    $auth: AuthenticationStrategy;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    /** Does the user need to be logged in to view this page? */
    auth?: boolean;
    role?: string | string[];
  }
}

export default {
  install: (VueInstance: VueConstructor, options: CoreOptions) => {
    const authType = VueInstance.$config.values['auth-type']?.toLowerCase();
    if (!authType) {
      VueInstance.use(NoopAuthStrategy);
      return;
    }

    try {
      let authTypeName = authType;
      switch (authType) {
        case 'bearer':
          Vue.use(BearerTokenAuthStrategy);
          authTypeName = 'Bearer';
          break;
        case 'windowsauthentication':
          // Browser takes care of it, no need to intervene
          Vue.use(NoopAuthStrategy);
          authTypeName = 'Windows authentication';
          break;
        case 'none':
          Vue.use(NoopAuthStrategy);
          authTypeName = 'None';
          break;
        case 'noop':
          Vue.use(NoopAuthStrategy);
          authTypeName = 'No-op';
          break;
        case 'mock':
          Vue.use(NoopAuthStrategy);
          authTypeName = 'Mock, defaults to No-op';
          break;
        default:
          Vue.use(NoopAuthStrategy);
          authTypeName = 'Unknown, defaulting to No-op';
          break;
      }
      Vue.$log.debug(`🛂 [Auth] Authentication strategy: ${authTypeName}`);
    } catch (error) {
      Vue.$log.error(`🛂 [Auth] Unable to determine auth strategy, an error occurred: ${error}`);
      Vue.$log.info('🛂 [Auth] Authentication strategy: No-op');
    }
  },
};
