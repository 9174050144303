<template>
  <div class="agogis-assign-to-lesson-form-reduced">
    <agogis-booking-comment-tt-component
      :comments="value.comments"
      :label="$t('page.lesson.table.remark')"
      :is-read-only="value.isBookingFormLoading"
      @input="$set(value.bookingForm, 'comment', $event);hasChanges=true"
    ></agogis-booking-comment-tt-component>
    <p-row justify-end>
      <p-col v-if="value.lessonSelectionCanBeDeclined" xs6>
        <p-button
          stretch
          color="danger"
          :loading="value.isBookingFormLoading"
          :disabled="value.isBookingFormLoading"
          @click="onDecline"
        >
          <p-icon left>
            cancel
          </p-icon>
          Absagen
        </p-button>
      </p-col>
      <p-col v-if="value.lessonSelectionCanBeConfirmed" xs6>
        <p-button stretch color="positive" :loading="value.isBookingFormLoading" :disabled="value.isBookingFormLoading" @click="onConfirm">
          <p-icon left>
            check
          </p-icon>
          Zusagen
        </p-button>
      </p-col>
      <p-col v-if="value.lessonSelectionCanBeAssigned" xs6>
        <p-button stretch color="positive" :loading="value.isBookingFormLoading" :disabled="value.isBookingFormLoading" @click="onAssign">
          Einsatz buchen
        </p-button>
      </p-col>
    </p-row>
    <p-row justify-end>
      <p-col xs6>
        <p-button
          stretch
          secondary
          :loading="value.isBookingFormLoading"
          :disabled="value.isBookingFormLoading"
          @click="onCancel"
        >
          {{ $t('core.app.cancel') }}
        </p-button>
      </p-col>
      <p-col xs6>
        <p-button
          stretch
          primary
          :loading="value.isBookingFormLoading"
          :disabled="!hasChanges || value.isBookingFormLoading || !value.bookingForm.lectureLessonStatusId"
          @click="onSubmit"
        >
          {{ $t('core.app.ok') }}
        </p-button>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import LecturerBookingSummaryView from './lecturer-booking-summary-view';
  import AgogisBookingCommentTtComponent from '../../../../../components/agogis-booking-comment-tt.vue';

  export default Vue.extend({
    auth: true,
    name: 'AgogisAssignToLessonFormReduced',
    components: {
      AgogisBookingCommentTtComponent,
    },
    props: {
      value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
    },
    data: () => ({
      isLoading: false,
      confirmAssignment: undefined as boolean | undefined,
      hasChanges: false,
      lecturerError: undefined as string | undefined,
      lecturerAssignmentStatusError: undefined as string | undefined,
      errorResponse: undefined as any,
    }),
    computed: {
    },
    methods: {
      async onDecline() {
        const shallDecline = await this.$dialog.confirm('Absage', 'Wollen Sie diese Einsätze wirklich absagen?');
        if (shallDecline) {
          await this.value.declineBookingDataSource.select();
          this.$emit('submit');
        }
      },
      async onConfirm() {
        await this.value.confirmBookingDataSource.select();
        this.$emit('submit');
      },
      async onAssign() {
        await this.value.assignBookingDataSource.select();
        this.$emit('submit');
      },
      onInput() {
        this.$emit('input', []);
      },
      onCancel() {
        this.$emit('cancel');
      },
      onSubmit() {
        let hasError = false;
        this.lecturerError = undefined;
        this.lecturerAssignmentStatusError = undefined;
        if (!this.value.bookingForm.agogisUserId) {
          this.lecturerError = 'Ref/Doz muss ausgewählt werden';
          hasError = true;
        }
        if (Vue._.isEmpty(this.value.bookingForm.lectureLessonStatusId)) {
          this.lecturerAssignmentStatusError = 'Buchungsstatus muss ausgewählt werden';
          hasError = true;
        }
        if (!hasError) {
          this.submitNewComment();
        }
      },
      async submitNewComment() {
        try {
          await this.value.updateCommentDataSource.select();
          this.$emit('newComment');
        } catch (error : any) {
          this.errorResponse = error;
        }
      },
    },
  });
</script>
