// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import ContactCorrespondenceAddressResourceViewModel from '../../../../viewModel/resource/ContactCorrespondenceAddressResourceViewModel';
import DynamicFormPayloadResourceViewModel from '../../../../viewModel/resource/DynamicFormPayloadResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import RegisterClassRequestViewModel from '../../../../viewModel/resource/RegisterClassRequestViewModel';
import RegisterClassRequestDTO from '../../dto/resource/RegisterClassRequestDTO';

export default abstract class RegisterClassRequestModel extends BaseModel<RegisterClassRequestDTO> {
  /**
  * @type {int32}
  */
  get eventId() { return this.dto.eventId; }
  set eventId(value) { this.dto.eventId = value; }
  /**
  */
  get contactAddress() { return this.getObjectModel(() => this.dto.contactAddress, ContactAddressResourceViewModel)!; }
  set contactAddress(value) { this.setObjectModel(() => this.dto.contactAddress, value); }
  /**
  */
  get invoiceAddress() { return this.getObjectModel(() => this.dto.invoiceAddress, ContactCorrespondenceAddressResourceViewModel)!; }
  set invoiceAddress(value) { this.setObjectModel(() => this.dto.invoiceAddress, value); }
  /**
  */
  get correspondenceAddress() { return this.getObjectModel(() => this.dto.correspondenceAddress, ContactCorrespondenceAddressResourceViewModel)!; }
  set correspondenceAddress(value) { this.setObjectModel(() => this.dto.correspondenceAddress, value); }
  /**
  */
  get registrationAnswers() { return this.getObjectModel(() => this.dto.registrationAnswers, DynamicFormPayloadResourceViewModel)!; }
  set registrationAnswers(value) { this.setObjectModel(() => this.dto.registrationAnswers, value); }
  /**
  */
  get hasDyslexia() { return this.dto.hasDyslexia; }
  set hasDyslexia(value) { this.dto.hasDyslexia = value; }
  /**
  */
  get dyslexiaCertificateDocumentId() { return this.dto.dyslexiaCertificateDocumentId; }
  set dyslexiaCertificateDocumentId(value) { this.dto.dyslexiaCertificateDocumentId = value; }
  /**
  */
  get hasAdhd() { return this.dto.hasAdhd; }
  set hasAdhd(value) { this.dto.hasAdhd = value; }
  /**
  */
  get adhdCertificateDocumentId() { return this.dto.adhdCertificateDocumentId; }
  set adhdCertificateDocumentId(value) { this.dto.adhdCertificateDocumentId = value; }
  /**
  */
  get isForeignLanguage() { return this.dto.isForeignLanguage; }
  set isForeignLanguage(value) { this.dto.isForeignLanguage = value; }
  /**
  * @type {int32}
  */
  get toccoNativeLanguageId() { return this.dto.toccoNativeLanguageId; }
  set toccoNativeLanguageId(value) { this.dto.toccoNativeLanguageId = value; }
  /**
  */
  get schoolEducationCountry() { return this.dto.schoolEducationCountry; }
  set schoolEducationCountry(value) { this.dto.schoolEducationCountry = value; }
  /**
  */
  get hasVisualImpairment() { return this.dto.hasVisualImpairment; }
  set hasVisualImpairment(value) { this.dto.hasVisualImpairment = value; }
  /**
  */
  get visualImpairmentCertificateDocumentId() { return this.dto.visualImpairmentCertificateDocumentId; }
  set visualImpairmentCertificateDocumentId(value) { this.dto.visualImpairmentCertificateDocumentId = value; }
  /**
  */
  get hasHearingImpairment() { return this.dto.hasHearingImpairment; }
  set hasHearingImpairment(value) { this.dto.hasHearingImpairment = value; }
  /**
  */
  get hearingImpairmentCertificateDocumentId() { return this.dto.hearingImpairmentCertificateDocumentId; }
  set hearingImpairmentCertificateDocumentId(value) { this.dto.hearingImpairmentCertificateDocumentId = value; }
  /**
  */
  get hasOtherDisabilities() { return this.dto.hasOtherDisabilities; }
  set hasOtherDisabilities(value) { this.dto.hasOtherDisabilities = value; }
  /**
  */
  get otherDisabilitiesComment() { return this.dto.otherDisabilitiesComment; }
  set otherDisabilitiesComment(value) { this.dto.otherDisabilitiesComment = value; }
  /**
  */
  get employmentPosition() { return this.dto.employmentPosition; }
  set employmentPosition(value) { this.dto.employmentPosition = value; }
  /**
  */
  get employmentArea() { return this.dto.employmentArea; }
  set employmentArea(value) { this.dto.employmentArea = value; }
  /**
  * @type {int32}
  */
  get employmentGradePercent() { return this.dto.employmentGradePercent; }
  set employmentGradePercent(value) { this.dto.employmentGradePercent = value; }
  /**
  * @type {date-time}
  */
  get employmentStartDate() { return this.dto.employmentStartDate; }
  set employmentStartDate(value) { this.dto.employmentStartDate = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  */
  get marketingAnswers() { return this.getObjectModel(() => this.dto.marketingAnswers, DynamicFormPayloadResourceViewModel)!; }
  set marketingAnswers(value) { this.setObjectModel(() => this.dto.marketingAnswers, value); }
  /**
  */
  get requestSummary() { return this.getObjectModel(() => this.dto.requestSummary, RequestSummaryResourceViewModel)!; }
  set requestSummary(value) { this.setObjectModel(() => this.dto.requestSummary, value); }
  /**
  */
  get acceptNewsletter() { return this.dto.acceptNewsletter; }
  set acceptNewsletter(value) { this.dto.acceptNewsletter = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.eventid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.eventId = value;
    value = qs.hasDyslexia === 'true' ? true : qs.hasdyslexia;
    value = qs.hasDyslexia === 'false' ? false : undefined;
    this.dto.hasDyslexia = value;
    this.dto.dyslexiaCertificateDocumentId = qs.dyslexiacertificatedocumentid;
    value = qs.hasAdhd === 'true' ? true : qs.hasadhd;
    value = qs.hasAdhd === 'false' ? false : undefined;
    this.dto.hasAdhd = value;
    this.dto.adhdCertificateDocumentId = qs.adhdcertificatedocumentid;
    value = qs.isForeignLanguage === 'true' ? true : qs.isforeignlanguage;
    value = qs.isForeignLanguage === 'false' ? false : undefined;
    this.dto.isForeignLanguage = value;
    value = Number.parseFloat(qs.tocconativelanguageid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.toccoNativeLanguageId = value;
    this.dto.schoolEducationCountry = qs.schooleducationcountry;
    value = qs.hasVisualImpairment === 'true' ? true : qs.hasvisualimpairment;
    value = qs.hasVisualImpairment === 'false' ? false : undefined;
    this.dto.hasVisualImpairment = value;
    this.dto.visualImpairmentCertificateDocumentId = qs.visualimpairmentcertificatedocumentid;
    value = qs.hasHearingImpairment === 'true' ? true : qs.hashearingimpairment;
    value = qs.hasHearingImpairment === 'false' ? false : undefined;
    this.dto.hasHearingImpairment = value;
    this.dto.hearingImpairmentCertificateDocumentId = qs.hearingimpairmentcertificatedocumentid;
    value = qs.hasOtherDisabilities === 'true' ? true : qs.hasotherdisabilities;
    value = qs.hasOtherDisabilities === 'false' ? false : undefined;
    this.dto.hasOtherDisabilities = value;
    this.dto.otherDisabilitiesComment = qs.otherdisabilitiescomment;
    this.dto.employmentPosition = qs.employmentposition;
    this.dto.employmentArea = qs.employmentarea;
    value = Number.parseFloat(qs.employmentgradepercent);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.employmentGradePercent = value;
    this.dto.employmentStartDate = qs.employmentstartdate;
    this.dto.comment = qs.comment;
    value = qs.acceptNewsletter === 'true' ? true : qs.acceptnewsletter;
    value = qs.acceptNewsletter === 'false' ? false : undefined;
    this.dto.acceptNewsletter = value;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
  }
}
