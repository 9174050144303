// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/module-planning-lesson-schema',
  type: 'object',
  required: ['id', 'duration'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    lessonName: {
      type: 'string',
      nullable: true,
    },
    startTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    duration: {
      type: 'number',
    },
    isPrototype: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
