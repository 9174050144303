<template>
  <div class="agogis-assign-to-lesson-form-admin">
    <p-form :model="value.bookingForm">
      <agogis-lecturer-lookup
        :value="value.bookingForm.agogisUserId"
        :label="$t('page.lesson.table.teacherName')"
        context-icon="search"
        clearable
        required
        :disabled="value.isBookingFormLoading"
        :errors="lecturerError"
        @clear="$set(value.bookingForm, 'agogisUserId', undefined);value.reset();hasChanges=true"
        @input="$set(value.bookingForm, 'agogisUserId', $event||undefined);value.reset();hasChanges=true"
      ></agogis-lecturer-lookup>
      <agogis-lecturer-assignment-status-lookup
        :value="value.bookingForm.lectureLessonStatusId"
        label="Status"
        required
        :disabled="value.isBookingFormLoading"
        :errors="lecturerAssignmentStatusError"
        @input="$set(value.bookingForm, 'lectureLessonStatusId', $event);hasChanges=true"
      ></agogis-lecturer-assignment-status-lookup>
      <agogis-booking-comment-tt-component
        :comments="value.comments"
        :label="$t('page.lesson.table.remark')"
        :is-read-only="value.isBookingFormLoading"
        @input="$set(value.bookingForm, 'comment', $event);hasChanges=true"
      ></agogis-booking-comment-tt-component>
      <agogis-booking-comment-component
        :comments="value.adminComments"
        :label="$t('page.lesson.table.adminRemark')"
        :is-read-only="value.isBookingFormLoading"
        @input="$set(value.bookingForm, 'adminComment', $event || '');hasChanges=true"
      ></agogis-booking-comment-component>

      <p-row justify-end>
        <p-col xs6>
          <p-button
            stretch
            secondary
            :loading="value.isBookingFormLoading"
            :disabled="value.isBookingFormLoading"
            @click="onCancel"
          >
            {{ $t('core.app.cancel') }}
          </p-button>
        </p-col>
        <p-col xs6>
          <p-button
            stretch
            primary
            :loading="value.isBookingFormLoading"
            :disabled="!hasChanges || value.isBookingFormLoading"
            @click="onSubmit"
          >
            {{ $t('core.app.ok') }}
          </p-button>
        </p-col>
      </p-row>
    </p-form>
    <p-request-alert v-if="errorResponse" v-model="errorResponse" :dismissable="false"></p-request-alert>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import LecturerBookingSummaryView from './lecturer-booking-summary-view';
  import AgogisBookingCommentComponent from '../../../../../components/agogis-booking-comment.vue';
  import AgogisBookingCommentTtComponent from '../../../../../components/agogis-booking-comment-tt.vue';

  export default Vue.extend({
    auth: true,
    name: 'AgogisAssignToLessonFormAdmin',
    components: {
      AgogisBookingCommentComponent,
      AgogisBookingCommentTtComponent,
    },
    props: {
      value: { default: () => ({} as LecturerBookingSummaryView), type: Object as PropType<LecturerBookingSummaryView> },
    },
    data: () => ({
      hasChanges: false,
      lecturerError: undefined as string | undefined,
      lecturerAssignmentStatusError: undefined as string | undefined,
      errorResponse: undefined as any,
    }),
    computed: {
    },
    methods: {
      onCancel() {
        this.$emit('cancel');
      },
      onSubmit() {
        let hasError = false;
        this.lecturerError = undefined;
        this.lecturerAssignmentStatusError = undefined;
        if (!this.value.bookingForm.agogisUserId) {
          this.lecturerError = 'Ref/Doz muss ausgewählt werden';
          hasError = true;
        }
        if (Vue._.isEmpty(this.value.bookingForm.lectureLessonStatusId)) {
          this.lecturerAssignmentStatusError = 'Buchungsstatus muss ausgewählt werden';
          hasError = true;
        }
        if (!hasError) {
          this.submitAssignment();
        }
      },
      async submitAssignment() {
        try {
          await this.value.updateBookingDataSource.select();
          this.$emit('submit');
        } catch (error : any) {
          this.errorResponse = error;
        }
      },
    },
  });
</script>
