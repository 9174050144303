// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSchedulerByClassesRequestViewModel from '../../viewModel/request/SchedulerResources/GetSchedulerByClassesRequestViewModel';
import GetSchedulerByReferentsRequestViewModel from '../../viewModel/request/SchedulerResources/GetSchedulerByReferentsRequestViewModel';
import GetSchedulerByRoomsRequestViewModel from '../../viewModel/request/SchedulerResources/GetSchedulerByRoomsRequestViewModel';
import GetSchedulerByLessonsRequestViewModel from '../../viewModel/request/SchedulerResources/GetSchedulerByLessonsRequestViewModel';
import GetSchedulerByAppointmentsRequestViewModel from '../../viewModel/request/SchedulerResources/GetSchedulerByAppointmentsRequestViewModel';
import Int32LookupResourceListResultViewModel from '../../viewModel/resource/Int32LookupResourceListResultViewModel';
import SchedulerResourceListResultViewModel from '../../viewModel/resource/SchedulerResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getResourceTypes(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-resource-types'] ?? 'SchedulerResources/ResourceTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int32LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSchedulerByClasses(request: GetSchedulerByClassesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-classes'] ?? 'SchedulerResources/ByClasses';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SchedulerResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSchedulerByReferents(request: GetSchedulerByReferentsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-referents'] ?? 'SchedulerResources/ByReferents';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SchedulerResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSchedulerByRooms(request: GetSchedulerByRoomsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-rooms'] ?? 'SchedulerResources/ByRooms';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SchedulerResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSchedulerByLessons(request: GetSchedulerByLessonsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-lessons'] ?? 'SchedulerResources/ByLessons';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SchedulerResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSchedulerByAppointments(request: GetSchedulerByAppointmentsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-scheduler-by-appointments'] ?? 'SchedulerResources/ByAppointments';
    const response = await service.get<any>(endpointPath, {
      query: {
        startTime: requestDTO.startTime,
        endTime: requestDTO.endTime,
        selectedResourceTypes: requestDTO.selectedResourceTypes,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SchedulerResourceListResultViewModel().fromDTO(dto);
  },
});
