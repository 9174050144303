// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureLessonHistoryListModel from '../../model/lecture-lesson-history-list-model';
import LectureLessonHistoryListDTO from '../dto/lecture-lesson-history-list-dto';
import dtoSchema from '../schemas/lecture-lesson-history-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class LectureLessonHistoryListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureLessonHistoryListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.lectureLessonId = data.lectureLessonId;
      this.lecturerId = data.lecturerId;
      this.lecturerName = data.lecturerName ?? undefined;
      this.statusOld = data.statusOld ?? undefined;
      this.statusNew = data.statusNew ?? undefined;
      this.comment = data.comment ?? undefined;
      this.adminComment = data.adminComment ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  lectureLessonId?: number;
  /**
  * @type {int64}
  */
  lecturerId?: number;
  /**
  */
  lecturerName?: string;
  /**
  */
  statusOld?: string;
  /**
  */
  statusNew?: string;
  /**
  */
  comment?: string;
  /**
  */
  adminComment?: string;

  static toModel(dto: DeepPartial<LectureLessonHistoryListDTO>): LectureLessonHistoryListModel;
  static toModel(dto: DeepPartial<LectureLessonHistoryListDTO> | undefined | null): LectureLessonHistoryListModel | undefined;
  static toModel(dto: DeepPartial<LectureLessonHistoryListDTO> | undefined | null): LectureLessonHistoryListModel | undefined {
    return dto ? new LectureLessonHistoryListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureLessonHistoryListModel> | LectureLessonHistoryListModel): LectureLessonHistoryListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      lectureLessonId: unwrapped.lectureLessonId,
      lecturerId: unwrapped.lecturerId,
      lecturerName: unwrapped.lecturerName,
      statusOld: unwrapped.statusOld,
      statusNew: unwrapped.statusNew,
      comment: unwrapped.comment,
      adminComment: unwrapped.adminComment,
    } as LectureLessonHistoryListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureLessonHistoryListModel, can not map to LectureLessonHistoryListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
