// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ApiPagingRequestSchema from './api-paging-request-schema';

const schema = {
  $id: '/get-widget-reservations-request-schema',
  type: 'object',
  required: ['toccoFilter'],
  properties: {
    searchCriteria: {
      type: 'string',
      nullable: true,
    },
    paging: {
      ...ApiPagingRequestSchema,
    },
    toccoFilter: {
      type: 'string',
      minLength: 1,
    },
  },
} as IJSONSchema;

export default schema;
