import Vue from 'vue';
import agogisCockpitServices from './_generated/api/agogis-cockpit';
import apiKeysServices from './_generated/api/api-keys';
import appServices from './_generated/api/app';
import appLogsServices from './_generated/api/app-logs';
import appointmentsServices from './_generated/api/appointments';
import appSystemServices from './_generated/api/app-system';
import authServices from './_generated/api/auth';
import callLogsServices from './_generated/api/call-logs';
import classesServices from './_generated/api/classes';
import cockpitNotificationsServices from './_generated/api/cockpit-notifications';
import contactIdMappingsServices from './_generated/api/contact-id-mappings';
import documentsServices from './_generated/api/documents';
import dynamicFormServices from './_generated/api/dynamic-form';
import eventTypeServices from './_generated/api/event-type';
import fieldMappingServices from './_generated/api/field-mapping';
import integrationCallsServices from './_generated/api/integration-calls';
import integrationTasksServices from './_generated/api/integration-tasks';
import lectureLessonStatusServices from './_generated/api/lecture-lesson-status';
import lecturerLessonAssignmentsServices from './_generated/api/lecturer-lesson-assignments';
import lessonsServices from './_generated/api/lessons';
import locationsServices from './_generated/api/locations';
import meServices from './Me';
import modulesServices from './_generated/api/modules';
import onlinePaymentTransactionServices from './_generated/api/online-payment-transaction';
import processesServices from './_generated/api/processes';
import publicHolidaysServices from './_generated/api/public-holidays';
import referentsServices from './_generated/api/referents';
import roomsServices from './_generated/api/rooms';
import schedulerResourcesServices from './_generated/api/scheduler-resources';
import sequencenumbersServices from './_generated/api/sequencenumbers';
import tenantsServices from './_generated/api/tenants';
import usersServices from './_generated/api/users';
import widgetServices from './_generated/api/widget';
import widgetClassesServices from './_generated/api/widget-classes';
import widgetReservationsServices from './_generated/api/widget-reservations';

export default () => ({
  agogisCockpit: { ...agogisCockpitServices(Vue.$service) },
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appointments: { ...appointmentsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  callLogs: { ...callLogsServices(Vue.$service) },
  classes: { ...classesServices(Vue.$service) },
  cockpitNotifications: { ...cockpitNotificationsServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  eventType: { ...eventTypeServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  lectureLessonStatus: { ...lectureLessonStatusServices(Vue.$service) },
  lecturerLessonAssignments: { ...lecturerLessonAssignmentsServices(Vue.$service) },
  lessons: { ...lessonsServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  modules: { ...modulesServices(Vue.$service) },
  onlinePaymentTransaction: { ...onlinePaymentTransactionServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  publicHolidays: { ...publicHolidaysServices(Vue.$service) },
  referents: { ...referentsServices(Vue.$service) },
  rooms: { ...roomsServices(Vue.$service) },
  schedulerResources: { ...schedulerResourcesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
  widget: { ...widgetServices(Vue.$service) },
  widgetClasses: { ...widgetClassesServices(Vue.$service) },
  widgetReservations: { ...widgetReservationsServices(Vue.$service) },
});
