// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetWidgetClassInfoRequestModel from '../../model/get-widget-class-info-request-model';
import GetWidgetClassInfoRequestDTO from '../dto/get-widget-class-info-request-dto';
import dtoSchema from '../schemas/get-widget-class-info-request-schema';

export default abstract class GetWidgetClassInfoRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetWidgetClassInfoRequestDTO>) {
    super();
    if (data) {
      this.classTypeKey = data.classTypeKey ?? undefined;
    }
  }
  /**
  */
  classTypeKey?: string;

  static toModel(dto: DeepPartial<GetWidgetClassInfoRequestDTO>): GetWidgetClassInfoRequestModel;
  static toModel(dto: DeepPartial<GetWidgetClassInfoRequestDTO> | undefined | null): GetWidgetClassInfoRequestModel | undefined;
  static toModel(dto: DeepPartial<GetWidgetClassInfoRequestDTO> | undefined | null): GetWidgetClassInfoRequestModel | undefined {
    return dto ? new GetWidgetClassInfoRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetWidgetClassInfoRequestModel> | GetWidgetClassInfoRequestModel): GetWidgetClassInfoRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      classTypeKey: unwrapped.classTypeKey,
    } as GetWidgetClassInfoRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetWidgetClassInfoRequestModel, can not map to GetWidgetClassInfoRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
