import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import EventTypeApi from '../generated/api/EventType';

export default (service: Servicelayer) => ({
  ...EventTypeApi(service),

  // Add custom calls here

});
