<template>
  <div v-resize="onResize" fluid class="scheduler-referent fill-height">
    <p-row v-if="responseError">
      <p-col>
        <p-request-alert v-model="responseError"></p-request-alert>
      </p-col>
    </p-row>
    <p-scheduler
      v-model="filter.endpoint.startTime"
      :loading="isLoading"
      :row-headers="dayPilotRowHeaders"
      :row-header-columns="rowHeaderColumns"
      :events="dayPilotEvents"
      :time-scale="filter.local.timeScale"
      @time-range="onTimeRangeChange"
    ></p-scheduler>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { DayPilot } from 'daypilot-pro-vue';
  import SchedulerFilterViewModel from '../../../../services/v1/viewModel/resource/SchedulerFilterViewModel';
  import SchedulerResourceViewModel from '../../../../services/v1/viewModel/resource/SchedulerResourceViewModel';
  import ReferentListResourcePagingResultViewModel from '../../../../services/v1/viewModel/resource/ReferentListResourcePagingResultViewModel';
  import GetReferentsRequestViewModel from '../../../../services/v1/viewModel/request/Referents/GetReferentsRequestViewModel';
  import ReferentListResourceViewModel from '../../../../services/v1/viewModel/resource/ReferentListResourceViewModel';
  import GetSchedulerByReferentsRequestViewModel from '../../../../services/v1/viewModel/request/SchedulerResources/GetSchedulerByReferentsRequestViewModel';

  export default Vue.extend({
    auth: true,
    props: {
      value: { type: String, default: undefined },
      timeScale: { type: String, default: undefined },
      search: { type: String, default: undefined },
      groupId: { type: Number, default: undefined },
      hideEmptyRows: { type: Boolean, default: false },
      showLessons: { type: Boolean, default: false },
      showRooms: { type: Boolean, default: false },
      showReferents: { type: Boolean, default: false },
    },
    data: () => ({
      filterReferentTypeId: undefined as number | undefined,
      extendedFilterVisible: false,
      denseFilters: false,
      filter: new SchedulerFilterViewModel(),
      events: [] as SchedulerResourceViewModel[],
      dayPilotEvents: [] as DayPilot.QueueData[],
      responseError: undefined as any,
      isLoading: false,
      groups: new ReferentListResourcePagingResultViewModel(),
      groupFilterItems: [] as any[],
      rowHeaderColumns: [] as any[],
      dayPilotRowHeaders: [] as any[],
    }),
    watch: {
      groupId: {
        immediate: true,
        handler() {
          this.filter.local.groupId = this.groupId;
        },
      },
      hideEmptyRows: {
        immediate: true,
        handler() {
          this.filter.local.hideEmptyRows = this.hideEmptyRows;
        },
      },
      showLessons: {
        immediate: true,
        handler() {
          this.filter.endpoint.showLessons = this.showLessons;
        },
      },
      showRooms: {
        immediate: true,
        handler() {
          this.filter.endpoint.showRooms = this.showRooms;
        },
      },
      showReferents: {
        immediate: true,
        handler() {
          this.filter.endpoint.showReferents = this.showReferents;
        },
      },
      search: {
        immediate: true,
        handler() {
          this.filter.local.search = this.search;
        },
      },
      timeScale: {
        immediate: true,
        handler() {
          this.filter.local.timeScale = this.timeScale;
        },
      },
      value: {
        immediate: true,
        handler() {
          this.filter.endpoint.startTime = this.value;
        },
      },
      'filter.endpoint': {
        deep: true,
        handler() {
          this.refresh();
        },
      },
      'filter.local': {
        deep: true,
        handler() { this.filterY(); },
      },
    },
    beforeMount() {
      this.$withoutWatchers(() => {
        this.filter.fromQuery(this.$routerUtils.getQueryParams());
        this.$emit('time-range', this.filter.endpoint.startTime, this.filter.endpoint.endTime, this.filter.local.timeScale);
      });
    },
    async mounted() {
      this.rowHeaderColumns = [
        { value: '', field: 'caption', width: 250 },
        { value: '', field: 'details', width: 150 },
      ];
    },
    methods: {
      onResize(rect: DOMRect) {
        if (rect.width > 1220) {
          this.denseFilters = false;
        } else {
          this.denseFilters = true;
        }
      },
      async refresh() {
        this.$debounce(async () => {
          this.isLoading = true;
          this.$routerUtils.updateQueryParams(this.filter.toQuery());
          await this.populateY();
          await this.populateX(this.filter.endpoint.startTime!, this.filter.endpoint.endTime!);
          this.isLoading = false;
        }, 200)();
      },
      onTimeRangeChange(start: string, end: string, yearsVisible: number[]) {
        this.filter.endpoint.startTime = start;
        this.filter.endpoint.endTime = end;
        this.$emit('time-range', start, end, this.filter.local.timeScale);
        this.refresh();
      },
      filterY() {
        this.filter.events = this.events;
        const filtered = new ReferentListResourcePagingResultViewModel();
        filtered.items = this.filterReferentTypeId === undefined ? this.groups.items : this.groups.items.filter((i: ReferentListResourceViewModel) => {
          return i.referentTypes.find((t) => t.id === this.filterReferentTypeId);
        });
        const filteredDayPilotRowHeaders = filtered.getGroupsForDayPilot(this.filter);
        this.$set(this, 'dayPilotRowHeaders', filteredDayPilotRowHeaders);
      },
      async populateY() {
        this.isLoading = true;
        try {
          const options = new GetReferentsRequestViewModel();
          const response = await this.$service.api.referents.getReferents(options);
          this.groups = response;
          const set = new Set();
          const referentTypesNested = response.items.map((i: ReferentListResourceViewModel) => i.referentTypes);
          referentTypesNested.forEach((referentTypes) => {
            referentTypes.forEach((type) => {
              if (!set.has(type?.id) && set.add(type?.id)) {
                this.groupFilterItems.push(type);
              }
            });
          });
          this.$emit('update:group-filter-items', this.groupFilterItems);
          this.filterY();
        } catch (error: any) {
          this.responseError = error;
        }
        this.isLoading = false;
      },
      async populateX(start: string, end: string) {
        this.isLoading = true;
        try {
          const options = new GetSchedulerByReferentsRequestViewModel();
          options.selectedResourceTypes = this.filter.selectedResourceTypes;
          options.startTime = start;
          options.endTime = end;
          const response = await this.$service.api.schedulerResources.getSchedulerByReferents(options);
          this.events = response.items;
          this.dayPilotEvents = response.itemsForDayPilot;
          this.filterY();
        } catch (error: any) {
          this.responseError = error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
