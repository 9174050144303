// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LookupLecturerModulesRequestModel from '../../model/lookup-lecturer-modules-request-model';
import LookupLecturerModulesRequestDTO from '../dto/lookup-lecturer-modules-request-dto';
import dtoSchema from '../schemas/lookup-lecturer-modules-request-schema';

export default abstract class LookupLecturerModulesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LookupLecturerModulesRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.classYear = data.classYear;
      this.classId = data.classId;
      this.classIds = data.classIds;
      this.moduleId = data.moduleId;
      this.lectureLessonStatusId = data.lectureLessonStatusId;
      this.userId = data.userId;
      this.lecturerId = data.lecturerId;
      this.primaryLeadLecturerId = data.primaryLeadLecturerId;
      this.isPrototype = data.isPrototype;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {int32}
  */
  classYear?: number;
  /**
  * @type {int64}
  */
  classId?: number;
  /**
  */
  classIds?: number[];
  /**
  * @type {int64}
  */
  moduleId?: number;
  /**
  * @type {int64}
  */
  lectureLessonStatusId?: number;
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @type {int64}
  */
  lecturerId?: number;
  /**
  * @type {int64}
  */
  primaryLeadLecturerId?: number;
  /**
  */
  isPrototype?: boolean;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<LookupLecturerModulesRequestDTO>): LookupLecturerModulesRequestModel;
  static toModel(dto: DeepPartial<LookupLecturerModulesRequestDTO> | undefined | null): LookupLecturerModulesRequestModel | undefined;
  static toModel(dto: DeepPartial<LookupLecturerModulesRequestDTO> | undefined | null): LookupLecturerModulesRequestModel | undefined {
    return dto ? new LookupLecturerModulesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LookupLecturerModulesRequestModel> | LookupLecturerModulesRequestModel): LookupLecturerModulesRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      classYear: unwrapped.classYear,
      classId: unwrapped.classId,
      classIds: unwrapped.classIds,
      moduleId: unwrapped.moduleId,
      lectureLessonStatusId: unwrapped.lectureLessonStatusId,
      userId: unwrapped.userId,
      lecturerId: unwrapped.lecturerId,
      primaryLeadLecturerId: unwrapped.primaryLeadLecturerId,
      isPrototype: unwrapped.isPrototype,
      searchCriteria: unwrapped.searchCriteria,
    } as LookupLecturerModulesRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LookupLecturerModulesRequestModel, can not map to LookupLecturerModulesRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
