<template>
  <div class="agogis-lecturer-lookup p-form-element">
    <p-lookup
      :value="value"
      :label="$t('page.lesson.table.teacherName')"
      :loading="computedLoading"
      :disabled="disabled"
      :items="items"
      :search.sync="search"
      :errors="innerErrors"
      v-bind="$props"
      :required="required"
      clearable
      @input="$emit('input', $event)"
    >
    </p-lookup>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import GetReferentLookupRequestModel from '../../services/v2/model/get-referent-lookup-request-model';
  import Int64LookupModel from '../../services/v2/model/int64-lookup-model';

  export default Vue.extend({
    name: 'AgogisLecturerLookup',
    props: {
      value: { type: [Number, String], default: undefined },
      readonly: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      label: { type: String, default: undefined },
      required: { default: false, type: Boolean },
      errors: { type: String, default: undefined },
    },
    data: () => ({
      isLoading: false,
      search: undefined as string | undefined,
      items: [] as Int64LookupModel[],
      innerErrors: undefined as any,
    }),
    computed: {
      computedLoading(): boolean {
        return this.isLoading ?? this.loading;
      },
      computedLabel(): string {
        return this.label ?? 'Dozent / Referent';
      },
    },
    watch: {
      errors: {
        immediate: true,
        handler() {
          this.innerErrors = this.errors;
        },
      },
      value: {
        immediate: true,
        handler() {
          if (typeof this.value === 'string') {
            this.$emit('input', Number.parseInt(this.value, 10));
          } else {
            this.populateData();
          }
        },
      },
      search: {
        handler() {
          this.populateData();
        },
      },
    },
    methods: {
      populateData() {
        this.$debounce(async () => {
          this.isLoading = true;
          try {
            if ((this.search?.length ?? 0) > 1 || typeof this.value === 'number') {
              const req = new GetReferentLookupRequestModel({
                searchCriteria: this.search,
                'paging.CurrentPage': 1,
                'paging.PageSize': 25,
                id: !this.search ? this.value as any : undefined,
              });
              const response = await this.$service.v2.api.referents.getReferentLookup(req);
              this.items = response.items.map((x) => x.data).sort((a, b) => (a.caption ?? '').localeCompare(b.caption ?? ''));
            } else {
              this.items = [];
            }
          } catch (error: any) {
            this.innerErrors = error?.raw?.statusText ?? error?.message ?? error;
          } finally {
            this.isLoading = false;
          }
        }, 300, this)();
      },
    },
  });
</script>
