// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/module-planning-lecturer-schema',
  type: 'object',
  required: ['id', 'lessonAssignmentId', 'assignedLessonId', 'assignedClassId', 'lessonAssignmentStatusId'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    fullName: {
      type: 'string',
      nullable: true,
    },
    lessonAssignmentId: {
      type: 'integer',
    },
    assignedLessonId: {
      type: 'integer',
    },
    assignedClassId: {
      type: 'integer',
    },
    lessonAssignmentStatusId: {
      type: 'integer',
    },
    lessonAssignmentStatusName: {
      type: 'string',
      nullable: true,
    },
    lecturerAssignmentComment: {
      type: 'string',
      nullable: true,
    },
    lecturerAssignmentAdminComment: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
