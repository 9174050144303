<template>
  <!-- Begin Agogis.Widget.eventsByDate -->
  <div class="liste pruefungen">
    <div v-for="eventsByDate in eventsGroupedByDate" :key="eventsByDate.date" class="day_wrapper">
      <div class="date_wrapper">
        <div class="date">
          {{ $format.localDate(eventsByDate.date) }}
        </div>
      </div>
      <div class="eventList_wrapper">
        <div v-for="event in eventsByDate.events" :key="event.id" class="event pruefung" :class="{ compensation: event.isCompensationAvailable }">
          <div class="title_wrapper">
            <div class="title">
              {{ event.name }}
            </div>
          </div>
          <div class="city_wrapper">
            <div class="city">
              {{ event.city }}
            </div>
          </div>
          <div class="time_wrapper">
            <div class="time">
              {{ event.timeString }}
            </div>
          </div>
          <div class="status_wrapper">
            <div class="status">
              <div :class="`circle ${event.registrationIndicator} ${event.registrationStatus}`"></div>
              <div class="availability">
                {{ event.availabilityString }}
              </div>
            </div>
          </div>
          <div class="actions_wrapper">
            <div class="actions">
              <div class="registerButton_wrapper">
                <a v-if="event.isBookingActionAvailable" class="registerButton" :href="event.registrationUrl" target="_blank">
                  Anmelden
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-language: {{ $config.values.language }}
    </div>
  </div>
<!-- End Agogis.Widget.eventsByDate-->
</template>

<script lang="ts">
  import WidgetClassListResourceViewModel from '@/src/services/v1/viewModel/resource/WidgetClassListResourceViewModel';
  import WidgetSearchClassRequestViewModel from '@/src/services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';
  import ApiPagingRequestViewModel from '@/src/services/v1/viewModel/resource/ApiPagingRequestViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WidgetEventsByDate',
    layout: 'layout-widget',
    data: () => ({
      events: [] as WidgetClassListResourceViewModel[],
      eventsGroupedByDate: [] as { date: string, events: WidgetClassListResourceViewModel[] }[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize(): number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
    },
    watch: {},
    async mounted() {
      await this.loadEvents();
      this.eventsGroupedByDate = this.groupEventsByDate(this.events);
    },
    methods: {
      groupEventsByDate(events: WidgetClassListResourceViewModel[]) {
        const groupedEvents = events.reduce((eventGrouping, event) => {
          const date = event.startDate;
          if (!eventGrouping.some((e) => e.date === date)) {
            eventGrouping.push({ date: date!, events: [event] });
          } else {
            eventGrouping.find((e) => e.date === date)?.events.push(event);
          }
          return eventGrouping;
        }, [] as { date: string, events: WidgetClassListResourceViewModel[] }[]);

        groupedEvents.forEach((eventDate) => {
          eventDate.events.sort((a, b) => {
            if (!a.startDate) {
              return 0;
            }
            return a.startDate.localeCompare(b.startDate!);
          });
        });
        return groupedEvents;
      },
      async loadEvents() {
        try {
          const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
          widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
          const page = new ApiPagingRequestViewModel();
          page.pageSize = this.pageSize;
          widgetSearchClassRequestVM.paging = page;
          const response = await this.$service.v1.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
          this.events = response.items;
        } catch (e) {
          this.$log.error(e);
        }
      },
    },
  });
</script>
