// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AgogisUserModel from '../../model/agogis-user-model';
import AgogisUserDTO from '../dto/agogis-user-dto';
import dtoSchema from '../schemas/agogis-user-schema';

export default abstract class AgogisUserModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AgogisUserDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.userId = data.userId ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.fullName = data.fullName ?? undefined;
      this.email = data.email ?? undefined;
      this.function = data.function ?? undefined;
      this.functionValidTill = data.functionValidTill ?? undefined;
      this.userStatus = data.userStatus ?? undefined;
      this.isActive = data.isActive;
      this.hasDozentenCode = data.hasDozentenCode;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  */
  fullName?: string;
  /**
  */
  email?: string;
  /**
  */
  function?: string;
  /**
  * @type {date-time}
  */
  functionValidTill?: string;
  /**
  */
  userStatus?: string;
  /**
  */
  isActive?: boolean;
  /**
  */
  hasDozentenCode?: boolean;

  static toModel(dto: DeepPartial<AgogisUserDTO>): AgogisUserModel;
  static toModel(dto: DeepPartial<AgogisUserDTO> | undefined | null): AgogisUserModel | undefined;
  static toModel(dto: DeepPartial<AgogisUserDTO> | undefined | null): AgogisUserModel | undefined {
    return dto ? new AgogisUserModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AgogisUserModel> | AgogisUserModel): AgogisUserDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      userId: unwrapped.userId,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      fullName: unwrapped.fullName,
      email: unwrapped.email,
      function: unwrapped.function,
      functionValidTill: unwrapped.functionValidTill,
      userStatus: unwrapped.userStatus,
      isActive: unwrapped.isActive,
      hasDozentenCode: unwrapped.hasDozentenCode,
    } as AgogisUserDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AgogisUserModel, can not map to AgogisUserDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
