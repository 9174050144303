<template>
  <div>
    <p-textarea
      v-model="model.comment"
      class="agogis-booking-comment"
      :label="model.label"
      :disabled="!model.canEdit || isReadOnly"
      @input="model.canEdit?$emit('input', $event):undefined;"
    ></p-textarea>
    <p-alert v-if="!model.canEdit" :dismissable="false" :value="true" type="info">
      {{ $t('page.lesson.table.remarkReadonly') }}
    </p-alert>
  </div>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import BookingCommentComponentModel from './agogis-booking-comment.vue.model';

  export default Vue.extend({
    name: 'AgogisBookingCommentComponent',
    props: {
      comments: { type: Array as PropType<string[]>, default: () => [] as string[] },
      isReadOnly: { default: true, type: Boolean },
      label: { default: '', type: String },
    },
    data: () => ({
      model: new BookingCommentComponentModel(),
    }),
    watch: {
      comments: {
        immediate: true,
        handler() {
          this.model.updateComments(this.comments);
        },
      },
    },
    async mounted() {
      this.model.initialize(this.label, this.isReadOnly, this.comments);
    },
  });
</script>
