<template>
  <p-container class="kanban-container">
    <div fluid class="scheduler-class fill-height">
      <p-row align-center>
        <p-button tertiary @click="returnToPreviousPage()">
          <p-icon>
            arrow_back
          </p-icon>
        </p-button>
        <h2>{{ `${$t('page.modulePlanning.label.module')}: ${page.moduleName}` }}</h2>
      </p-row>
      <p-row>
        <p-col xs12>
          <div class="kanban-module-information">
            <p-card>
              <p>
                {{ `${$t('page.modulePlanning.label.class')}: ${page.className}` }}<br>
                {{ $t('page.modulePlanning.label.daysAmount') }}: {{ page.totalWorkloadDays }}<br>
                {{ $t('page.scheduler.classes.title') }}: {{ page.totalClasses }}
              </p>
            </p-card>
          </div>
        </p-col>
      </p-row>
      <p-request-alert :dismissable="false" :value="page.moduleOverviewDataSource.error"></p-request-alert>
      <p-request-alert :dismissable="false" :value="page.error"></p-request-alert>
      <p-progress-linear v-if="page.moduleOverviewDataSource.isLoading" indeterminate></p-progress-linear>
      <p-row>
        <p-col left shrink>
          <p-button @click="refresh">
            <p-icon>
              refresh
            </p-icon>
          </p-button>
        </p-col>
        <p-col justify-end grow class="legend-row">
          <div
            v-for="state in page.lectureLessonStates.filter(state => state.visibility)"
            :key="state.id"
            class="legend"
          >
            <span class="dot" :style="`background-color: ${state.htmlColor};`"></span>{{ $t(`page.modulePlanning.label.state.${state.name}`) }}
          </div>
        </p-col>
        <p-col justify-end shrink>
          <p-menu v-model="page.visibleColumnsSettings" :disabled="page.moduleOverviewDataSource.isLoading">
            <template slot="activator">
              <p-button :dense="$media.isDesktop" tertiary>
                <p-icon>more_vert</p-icon>
              </p-button>
            </template>
            <p-card>
              <p-row>
                <p-col></p-col>
                <p-col shrink>
                  <p-button
                    right
                    class="my-0 py-0"
                    tertiary
                    @click="page.visibleColumnsSettings = false"
                  >
                    <p-icon>close</p-icon>
                  </p-button>
                </p-col>
              </p-row>
              <p-row>
                <p-col>
                  <h4>{{ $t('page.modulePlanning.label.states') }}</h4>
                  <p-divider></p-divider>
                  <p-button stretch tertiary class="agogis_course_menuItem" :disabled="page.lectureLessonStates.every(x => x.visibility)" @click="setVisibilityForAllStates(true)">
                    <p-row>
                      <p-col justify-start align-center>
                        <p-icon left class="color-interaction">
                          done_all
                        </p-icon>
                        {{ $t('page.modulePlanning.label.selectAllColumns') }}
                      </p-col>
                    </p-row>
                  </p-button>
                  <p-button stretch tertiary class="agogis_course_menuItem" :disabled="page.lectureLessonStates.every(x => !x.visibility)" @click="setVisibilityForAllStates(false)">
                    <p-row>
                      <p-col justify-start align-center>
                        <p-icon left class="color-interaction">
                          remove_done
                        </p-icon>{{ $t('page.modulePlanning.label.deselectAllColumns') }}
                      </p-col>
                    </p-row>
                  </p-button>
                  <p-divider></p-divider>
                  <p-toggle
                    v-for="state in page.lectureLessonStates"
                    :key="state.id"
                    v-model="state.visibility"
                    :color="state.htmlColor"
                    :disabled="page.moduleOverviewDataSource.isLoading"
                    :label="$t(`page.modulePlanning.label.state.${state.name}`)"
                    class="agogis_course_menuItem_toggle"
                  ></p-toggle>
                </p-col>
                <p-col>
                  <h4>{{ $t('page.modulePlanning.label.courses') }}</h4>
                  <p-divider></p-divider>
                  <p-button stretch tertiary class="agogis_course_menuItem" :disabled="page.visibleColumns.every(x => x.visibility)" @click="setVisibilityForAllColumns(true)">
                    <p-row>
                      <p-col justify-start align-center>
                        <p-icon left class="color-interaction">
                          done_all
                        </p-icon>
                        {{ $t('page.modulePlanning.label.selectAllColumns') }}
                      </p-col>
                    </p-row>
                  </p-button>
                  <p-button stretch tertiary class="agogis_course_menuItem" :disabled="page.visibleColumns.every(x => !x.visibility)" @click="setVisibilityForAllColumns(false)">
                    <p-row>
                      <p-col justify-start align-center>
                        <p-icon left class="color-interaction">
                          remove_done
                        </p-icon>{{ $t('page.modulePlanning.label.deselectAllColumns') }}
                      </p-col>
                    </p-row>
                  </p-button>
                  <p-divider></p-divider>
                  <p-toggle
                    v-for="column in page.visibleColumns"
                    :key="column.id"
                    v-model="column.visibility"
                    :disabled="page.moduleOverviewDataSource.isLoading"
                    :label="column.name"
                    class="agogis_course_menuItem_toggle"
                  ></p-toggle>
                </p-col>
              </p-row>
            </p-card>
          </p-menu>
        </p-col>
      </p-row>
      <table :style="{ cursor: page.moduleOverviewDataSource.isLoading ? 'wait' : '' }">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="item in page.columns"
              :key="item.id"
              :class="{ isPrototype: item.isPrototype }"
              :width="`${100 / ((page.columns.length))}%`"
            >
              <div>
                {{ item.name }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in page.rows" :key="row.id">
            <th class="room">
              <p v-if="row.lessonName">
                {{ row.lessonName }}
              </p>
              <p>{{ page.localDateAndTime(row.startTime || '') }}</p>
              <p>{{ `${$t('page.modulePlanning.label.daysAmount')} ${row.duration}` }}</p>
            </th>
            <td
              v-for="col in page.columns"
              :key="col.id"
              class="cell"
              :class="{
                dropZone: page.cellConfiguration(col.id || 0, row.id || 0).isBookable,
                isPrototype: col.isPrototype,
              }"
              :style="{ cursor: page.moduleOverviewDataSource.isLoading ? 'wait' : '' }"
              @drop="page.onDrop($event, col.id || 0, row.id || 0)"
              @dragover="page.onDragover($event, col.id || 0, row.id || 0)"
              @dragenter="page.onDragover($event, col.id || 0, row.id || 0)"
            >
              <div
                v-for="card in page.cardsOfCell(col.id || 0, row.id || 0)"
                :key="card.lessonAssignmentId"
                :style="{ 'border-color': page.cardColor(card), 'background-color': `${page.cardColor(card)}33`, cursor: page.moduleOverviewDataSource.isLoading ? 'wait' : '' }"
                class="card"
                draggable
                @click="gotoDetail(card.assignedLessonId || 0)"
                @dragstart="page.startDrag($event, card, row.id || 0)"
              >
                {{ card.fullName }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ModuleDetailPage from './module-detail-page';

  export default Vue.extend({
    auth: true,
    components: {
    },
    data: () => ({
      page: new ModuleDetailPage(),
    }),
    computed: {
    },
    mounted() {
      this.page.initialize();
    },
    methods: {
      setVisibilityForAllColumns(newState: boolean) {
        this.page.visibleColumns.forEach((x) => { x.visibility = newState; });
      },
      setVisibilityForAllStates(newState: boolean) {
        this.page.lectureLessonStates.forEach((x) => { x.visibility = newState; });
      },
      refresh() {
        this.page.moduleOverviewDataSource.select();
      },
      returnToPreviousPage() {
        this.$router.back();
      },
      gotoDetail(id: number) {
        this.$router.push(`/agogis/cockpit/schedule-planning/lessons/${id}`);
      },
    },
  });
</script>
<style scoped>
table {
  width: 100%;
  background-color:#f5f5f5;
  font-size:13px;
  line-height:1.25em;
}
table,
td,
th {
  border-collapse:separate;
  border-spacing: 1px;
}
thead {
  font-weight:normal;
}
tr:hover td.dropZone,
tr:hover th:first-of-type {
  background-color:ghostwhite;
  color:gray;
}
th{
  background-color: #bbb;
  color:#fff;

}
td {
  background-color:#fff;
}
table th.room {
  text-align:left;
  height:100px;
  min-width:130px;
  font-weight:normal;
}
table thead th {
  font-weight:normal;
  padding:0px 5px;

}
table thead th div {
  min-height:3em;
  justify-content:center;
  display:flex;
  flex-direction:column;
}

table p {
  display:block;
  padding:2px 5px;
  margin:0;
}
tr td.isPrototype,
th.isPrototype {
  background-color: rgba(128, 128, 128, 0.24);
  color:gray;
}
.card {
  min-height: 3em;
  margin:0;
  border-left: 2px solid #a8a5ee;
  border-right: 2px solid #a8a5ee;
  -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.025), inset 0px 10px 40px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.025), inset 0px 10px 40px rgba(255, 255, 255, 0.75);
  background-color: #e0dff9;
  display:flex;
  flex:1 0 100%;
  margin:0;
  flex-direction:column;
  justify-content:center;
  padding:0 5px;
  cursor:pointer;
}
.cell {
  background-color: lightgray;
  min-height:100px;
}
.dropZone {
  background-color: white;
}
.legend-row{
  display:flex;
  align-content: flex-end;
  align-items: center;
  align-self: center;
  gap: 5px;
}
.legend{
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dot {
  display: flex;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-flex;
}
</style>
