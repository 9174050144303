import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import SchedulerResourcesApi from '../generated/api/SchedulerResources';

export default (service: Servicelayer) => ({
  ...SchedulerResourcesApi(service),

  // Add custom calls here

});
