import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import ApiPagingRequestModel from '../../../services/v2/model/api-paging-request-model';

export default class WidgetPoiPage extends PageBase {
  private _cycleIntervalHandle?: number;
  pageNumber = 1;
  pageCount = 1;
  reservationsDataSource = new DataSource({
    noRefreshOnFilterChange: true,
    selectCommand: Vue.$service.v2.api.widgetReservations.getWidgetReservations,
  });

  async initialize(): Promise<void> {
    this.reservationsDataSource.filter.toccoFilter = '(1==1)';
    this.reservationsDataSource.filter.toccoFilter = this.toccoFilter;
    this.reservationsDataSource.paging = { currentPage: 1, pageSize: this.pageSize };
    this.reservationsDataSource.onSelected.addEventListener(() => {
      this.pageNumber = this.reservationsDataSource.paging.currentPage || 1;
      this.pageCount = this.reservationsDataSource.paging.totalPages || 1;
    });
    await this.reservationsDataSource.select();
    this.startPageCycling();
  }

  startPageCycling() {
    const showDuration = Vue.$config.values['widget-poi-autorefresh'];
    if (showDuration) {
      if (this._cycleIntervalHandle) { clearInterval(this._cycleIntervalHandle); }
      this._cycleIntervalHandle = +setInterval(() => {
        this.reservationsDataSource.paging = this.reservationsDataSource.paging ?? {} as ApiPagingRequestModel;
        const currentPage = this.reservationsDataSource.paging.currentPage ?? 1;
        const totalPages = Math.max(this.reservationsDataSource.paging.totalPages ?? 1, 1);
        let nextPage = (currentPage + 1) % totalPages; // Modulo wrap
        nextPage = nextPage === 0 ? totalPages : nextPage; // not 0 indexed (last number is always last page)
        this.reservationsDataSource.paging.currentPage = nextPage;
        this.reservationsDataSource.select();
      }, showDuration * 1000);
    }
  }

  get toccoFilter(): string {
    let f = Vue.$config.values['widget-toccofilter'];
    if (f === undefined) { f = '1==1'; }
    return f;
  }

  get pageSize():number {
    if (Vue.$config.values['widget-pagesize'] !== undefined) {
      return Vue.$config.values['widget-pagesize'];
    }
    return 100;
  }
}
