// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureLessonLecturerModel from '../../model/lecture-lesson-lecturer-model';
import LectureLessonLecturerDTO from '../dto/lecture-lesson-lecturer-dto';
import dtoSchema from '../schemas/lecture-lesson-lecturer-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import ITTObjectModel from '../../model/itt-object-model';

export default abstract class LectureLessonLecturerModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureLessonLecturerDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.lecturerId = data.lecturerId ?? undefined;
      this.lecturerRoleId = data.lecturerRoleId ?? undefined;
      this.lecturerRoleName = data.lecturerRoleName ?? undefined;
      this.lecturerFirstName = data.lecturerFirstName ?? undefined;
      this.lecturerLastName = data.lecturerLastName ?? undefined;
      this.lecturerFullName = data.lecturerFullName ?? undefined;
      this.lecturerStatusName = data.lecturerStatusName ?? undefined;
      this.lecturerComment = data.lecturerComment ?? undefined;
      this.ttComment = data.ttComment ? ITTObjectModel.toModel(data.ttComment) : undefined;
      this.lecturerAdminComment = data.lecturerAdminComment ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  lecturerId?: number;
  /**
  * @type {int64}
  */
  lecturerRoleId?: number;
  /**
  */
  lecturerRoleName?: string;
  /**
  */
  lecturerFirstName?: string;
  /**
  */
  lecturerLastName?: string;
  /**
  */
  lecturerFullName?: string;
  /**
  */
  lecturerStatusName?: string;
  /**
  */
  lecturerComment?: string;
  /**
  */
  ttComment?: ITTObjectModel;
  /**
  */
  lecturerAdminComment?: string;

  static toModel(dto: DeepPartial<LectureLessonLecturerDTO>): LectureLessonLecturerModel;
  static toModel(dto: DeepPartial<LectureLessonLecturerDTO> | undefined | null): LectureLessonLecturerModel | undefined;
  static toModel(dto: DeepPartial<LectureLessonLecturerDTO> | undefined | null): LectureLessonLecturerModel | undefined {
    return dto ? new LectureLessonLecturerModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureLessonLecturerModel> | LectureLessonLecturerModel): LectureLessonLecturerDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      lecturerId: unwrapped.lecturerId,
      lecturerRoleId: unwrapped.lecturerRoleId,
      lecturerRoleName: unwrapped.lecturerRoleName,
      lecturerFirstName: unwrapped.lecturerFirstName,
      lecturerLastName: unwrapped.lecturerLastName,
      lecturerFullName: unwrapped.lecturerFullName,
      lecturerStatusName: unwrapped.lecturerStatusName,
      lecturerComment: unwrapped.lecturerComment,
      ttComment: unwrapped.ttComment,
      lecturerAdminComment: unwrapped.lecturerAdminComment,
    } as LectureLessonLecturerDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureLessonLecturerModel, can not map to LectureLessonLecturerDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
