// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ModulePlanningLecturerResourceViewModel from '../../../../viewModel/resource/ModulePlanningLecturerResourceViewModel';
import ModulePlanningBookingSlotResourceViewModel from '../../../../viewModel/resource/ModulePlanningBookingSlotResourceViewModel';
import ModulePlanningBookingSlotResourceDTO from '../../dto/resource/ModulePlanningBookingSlotResourceDTO';

export default abstract class ModulePlanningBookingSlotResourceModel extends BaseModel<ModulePlanningBookingSlotResourceDTO> {
  /**
  * @type {int64}
  */
  get modulePlanningViewLessonId() { return this.dto.modulePlanningViewLessonId; }
  set modulePlanningViewLessonId(value) { this.dto.modulePlanningViewLessonId = value; }
  /**
  */
  get isBookable() { return this.dto.isBookable; }
  set isBookable(value) { this.dto.isBookable = value; }
  /**
  * @type {int64}
  */
  get classId() { return this.dto.classId; }
  set classId(value) { this.dto.classId = value; }
  /**
  * @type {int64}
  */
  get lessonId() { return this.dto.lessonId; }
  set lessonId(value) { this.dto.lessonId = value; }
  /**
  */
  get assignedLecturers() { return this.getArrayModels(() => this.dto.assignedLecturers, ModulePlanningLecturerResourceViewModel)!; }
  set assignedLecturers(value) { this.setArrayModels(() => this.dto.assignedLecturers, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.moduleplanningviewlessonid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modulePlanningViewLessonId = value;
    value = qs.isBookable === 'true' ? true : qs.isbookable;
    value = qs.isBookable === 'false' ? false : undefined;
    this.dto.isBookable = value;
    value = Number.parseFloat(qs.classid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classId = value;
    value = Number.parseFloat(qs.lessonid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.lessonId = value;
  }
}
