// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import SearchLessonsRequestViewModel from '../../viewModel/request/Lessons/SearchLessonsRequestViewModel';
import ExportLessonsRequestViewModel from '../../viewModel/request/Lessons/ExportLessonsRequestViewModel';
import SearchLessonByModuleRequestViewModel from '../../viewModel/request/Lessons/SearchLessonByModuleRequestViewModel';
import UpdateCommentTTRequestViewModel from '../../viewModel/request/Lessons/UpdateCommentTTRequestViewModel';
import LectureLessonListResourcePagingResultViewModel from '../../viewModel/resource/LectureLessonListResourcePagingResultViewModel';
import LectureLessonListResourceListResultViewModel from '../../viewModel/resource/LectureLessonListResourceListResultViewModel';
import LectureLessonResourceViewModel from '../../viewModel/resource/LectureLessonResourceViewModel';
import LectureLessonLecturerResourceListResultViewModel from '../../viewModel/resource/LectureLessonLecturerResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async searchLessons(classYear: number, request: SearchLessonsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-search-lessons'] ?? 'Lessons/ClassYear/{classYear}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${classYear}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        moduleIds: requestDTO.moduleIds,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async exportLessons(classYear: number, request: ExportLessonsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-export-lessons'] ?? 'Lessons/ClassYear/{classYear}/Export';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${classYear}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        moduleIds: requestDTO.moduleIds,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
  /**  */
  async searchLessonByModule(classYear: number, moduleId: number, request: SearchLessonByModuleRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-search-lesson-by-module'] ?? 'Lessons/ClassYear/{classYear}/Module/{moduleId}';
    endpointPath = endpointPath.replace(/\{classYear\}/g, `${classYear}`);
    endpointPath = endpointPath.replace(/\{moduleId\}/g, `${moduleId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        moduleIds: requestDTO.moduleIds,
        classIds: requestDTO.classIds,
        lectureLessonStatusIds: requestDTO.lectureLessonStatusIds,
        eventTypeIds: requestDTO.eventTypeIds,
        userId: requestDTO.userId,
        lecturerId: requestDTO.lecturerId,
        primaryLeadLecturerId: requestDTO.primaryLeadLecturerId,
        isPrototype: requestDTO.isPrototype,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getLessonById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-lesson-by-id'] ?? 'Lessons/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getReferentsByLessonId(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-referents-by-lesson-id'] ?? 'Lessons/{id}/Referents';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LectureLessonLecturerResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async updateCommentTT(request: UpdateCommentTTRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-comment-tt'] ?? 'Lessons/UpdateCommentTT';
    const response = await service.post<any>(endpointPath, {
      query: {
        ids: requestDTO.ids,
        commentTT: requestDTO.commentTT,
      },
      ...config,
    });
  },
});
