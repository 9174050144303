<template>
  <span class="language-selection">
    <p-button tertiary class="language-selection" :class="{'active-language': currentLanguage == 'en'}" @click="setLanguage('en')">EN</p-button> |
    <p-button tertiary class="language-selection" :class="{'active-language': currentLanguage == 'de'}" @click="setLanguage('de')">DE</p-button> |
    <p-button tertiary class="language-selection" :class="{'active-language': currentLanguage == 'it'}" @click="setLanguage('it')">IT</p-button> |
    <p-button tertiary class="language-selection" :class="{'active-language': currentLanguage == 'fr'}" @click="setLanguage('fr')">FR</p-button>
  </span>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    computed: {
      currentLanguage(): string {
        return this.$translation.get();
      },
    },
    methods: {
      setLanguage(languageCode: string) {
        if (languageCode !== this.$translation.get()) {
          this.$translation.set(languageCode);
          window.location.reload();
        }
      },
    },
  });
</script>

<style lang="scss">
  .language-selection{
    user-select: none;
    .active-language {
      &:hover{
        cursor: default !important;
      }
      font-weight: bold;
    }
  }
</style>
