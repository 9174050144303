// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import WidgetClassFaqSchema from './widget-class-faq-schema';

const schema = {
  $id: '/widget-class-information-schema',
  type: 'object',
  required: [],
  properties: {
    classTypeKey: {
      type: 'string',
      nullable: true,
    },
    title: {
      type: 'string',
      nullable: true,
    },
    subtitle: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    wordSubtitle: {
      type: 'string',
      nullable: true,
    },
    summary: {
      type: 'string',
      nullable: true,
    },
    faq: {
      type: 'array',
      nullable: true,
      items: { ...WidgetClassFaqSchema },
    },
  },
} as IJSONSchema;

export default schema;
