// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetWidgetClassNativeLanguageLookupRequestViewModel from '../../viewModel/request/WidgetClasses/GetWidgetClassNativeLanguageLookupRequestViewModel';
import WidgetUploadDocumentRequestViewModel from '../../viewModel/request/WidgetClasses/WidgetUploadDocumentRequestViewModel';
import WidgetSearchClassRequestViewModel from '../../viewModel/resource/WidgetSearchClassRequestViewModel';
import WidgetClassListResourcePagingResultViewModel from '../../viewModel/resource/WidgetClassListResourcePagingResultViewModel';
import DynamicFormDefinitionResourceViewModel from '../../viewModel/resource/DynamicFormDefinitionResourceViewModel';
import SubjectAreaResourceListResultViewModel from '../../viewModel/resource/SubjectAreaResourceListResultViewModel';
import RegisterClassRequestViewModel from '../../viewModel/resource/RegisterClassRequestViewModel';
import WidgetSearchClassInformationRequestViewModel from '../../viewModel/resource/WidgetSearchClassInformationRequestViewModel';
import WidgetClassInformationResourceViewModel from '../../viewModel/resource/WidgetClassInformationResourceViewModel';
import Int32LookupResourceListResultViewModel from '../../viewModel/resource/Int32LookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getWidgetClasses(model: WidgetSearchClassRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-classes'] ?? 'WidgetClasses';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetClassListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetRegistrationMarketingQuestionsDefinition(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-registration-marketing-questions-definition'] ?? 'WidgetClasses/{id}/RegistrationMarketingQuestions/Definition';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DynamicFormDefinitionResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetSubjectAreas(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-subject-areas'] ?? 'WidgetClasses/SubjectAreas';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SubjectAreaResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetClassRegistrationQuestionsDefinition(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-registration-questions-definition'] ?? 'WidgetClasses/{id}/RegistrationQuestions/Definition';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DynamicFormDefinitionResourceViewModel().fromDTO(dto);
  },
  /**  */
  async widgetClassRegisterRequest(id: number, model: RegisterClassRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-class-register-request'] ?? 'WidgetClasses/{id}/Register';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getWidgetClassInfo(model: WidgetSearchClassInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-info'] ?? 'WidgetClasses/ClassInformation';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new WidgetClassInformationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetClassNativeLanguageLookup(request: GetWidgetClassNativeLanguageLookupRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-class-native-language-lookup'] ?? 'WidgetClasses/NativeLanguageLookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int32LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async downloadPdf(model: WidgetSearchClassInformationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-pdf'] ?? 'WidgetClasses/DownloadPdf';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
  /**  */
  async widgetUploadDocument(id: number, request: WidgetUploadDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-upload-document'] ?? 'WidgetClasses/{id}/DocumentUpload';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
