// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/set-states-request-schema',
  type: 'object',
  required: [],
  properties: {
    state: {
      enum: [
        0,
        1,
        2,
        3,
        4,
      ],
    },
    ids: {
      type: 'array',
      nullable: true,
      items: {
        type: 'integer',
      },
    },
    information: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
