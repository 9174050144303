// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetReservationListPagingResultModel from '../../model/widget-reservation-list-paging-result-model';
import WidgetReservationListPagingResultDTO from '../dto/widget-reservation-list-paging-result-dto';
import dtoSchema from '../schemas/widget-reservation-list-paging-result-schema';
import WidgetReservationListModel from '../../model/widget-reservation-list-model';
import ResourceLinkModel from '../../model/resource-link-model';
import PaginationDataModel from '../../model/pagination-data-model';

export default abstract class WidgetReservationListPagingResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetReservationListPagingResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => WidgetReservationListModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.paginationData = data.paginationData ? PaginationDataModel.toModel(data.paginationData) : undefined;
    }
  }
  /**
  */
  items?: WidgetReservationListModel[];
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  paginationData?: PaginationDataModel;

  static toModel(dto: DeepPartial<WidgetReservationListPagingResultDTO>): WidgetReservationListPagingResultModel;
  static toModel(dto: DeepPartial<WidgetReservationListPagingResultDTO> | undefined | null): WidgetReservationListPagingResultModel | undefined;
  static toModel(dto: DeepPartial<WidgetReservationListPagingResultDTO> | undefined | null): WidgetReservationListPagingResultModel | undefined {
    return dto ? new WidgetReservationListPagingResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetReservationListPagingResultModel> | WidgetReservationListPagingResultModel): WidgetReservationListPagingResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
      paginationData: unwrapped.paginationData,
    } as WidgetReservationListPagingResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetReservationListPagingResultModel, can not map to WidgetReservationListPagingResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
