import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import PublicHolidaysApi from '../generated/api/PublicHolidays';

export default (service: Servicelayer) => ({
  ...PublicHolidaysApi(service),

  // Add custom calls here

});
