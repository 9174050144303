import Vue from 'vue';
import WidgetReservationListModelBase from '../_generated/modelBases/widget-reservation-list-model-base';

export default class WidgetReservationListModel extends WidgetReservationListModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  getRoomString() {
    return this.rooms?.join(', ');
  }
  getLecturersString() {
    return this.lecturers?.join(', ');
  }
  getDateTimeString() {
    let formattedDateString = '';
    if (this.startDate) {
      formattedDateString = `${Vue.$format.date(this.startDate, 'dd')}. ${Vue.$format.localDateAndTime(this.startDate)}`;
    }
    if (this.endDate) {
      formattedDateString += ` - ${Vue.$format.localTime(this.endDate)}`;
    }
    return formattedDateString;
  }
}
