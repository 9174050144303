// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureLessonModel from '../../model/lecture-lesson-model';
import LectureLessonDTO from '../dto/lecture-lesson-dto';
import dtoSchema from '../schemas/lecture-lesson-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import ITTObjectModel from '../../model/itt-object-model';

export default abstract class LectureLessonModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureLessonDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.lectureClassId = data.lectureClassId ?? undefined;
      this.lectureClassName = data.lectureClassName ?? undefined;
      this.lectureModuleId = data.lectureModuleId ?? undefined;
      this.lectureModuleName = data.lectureModuleName ?? undefined;
      this.lectureModuleDescription = data.lectureModuleDescription ?? undefined;
      this.lessonName = data.lessonName ?? undefined;
      this.startTime = data.startTime ?? undefined;
      this.endTime = data.endTime ?? undefined;
      this.duration = data.duration;
      this.toccoLessonId = data.toccoLessonId;
      this.toccoClassId = data.toccoClassId ?? undefined;
      this.teamTeaching = data.teamTeaching;
      this.comment_TT = data.comment_TT ?? undefined;
      this.ttComment = data.ttComment ? ITTObjectModel.toModel(data.ttComment) : undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  lectureClassId?: number;
  /**
  */
  lectureClassName?: string;
  /**
  * @type {int64}
  */
  lectureModuleId?: number;
  /**
  */
  lectureModuleName?: string;
  /**
  */
  lectureModuleDescription?: string;
  /**
  */
  lessonName?: string;
  /**
  * @type {date-time}
  */
  startTime?: string;
  /**
  * @type {date-time}
  */
  endTime?: string;
  /**
  * @type {double}
  */
  duration?: number;
  /**
  * @type {int64}
  */
  toccoLessonId?: number;
  /**
  * @type {int64}
  */
  toccoClassId?: number;
  /**
  */
  teamTeaching?: boolean;
  /**
  */
  comment_TT?: string;
  /**
  */
  ttComment?: ITTObjectModel;

  static toModel(dto: DeepPartial<LectureLessonDTO>): LectureLessonModel;
  static toModel(dto: DeepPartial<LectureLessonDTO> | undefined | null): LectureLessonModel | undefined;
  static toModel(dto: DeepPartial<LectureLessonDTO> | undefined | null): LectureLessonModel | undefined {
    return dto ? new LectureLessonModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureLessonModel> | LectureLessonModel): LectureLessonDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      lectureClassId: unwrapped.lectureClassId,
      lectureClassName: unwrapped.lectureClassName,
      lectureModuleId: unwrapped.lectureModuleId,
      lectureModuleName: unwrapped.lectureModuleName,
      lectureModuleDescription: unwrapped.lectureModuleDescription,
      lessonName: unwrapped.lessonName,
      startTime: unwrapped.startTime,
      endTime: unwrapped.endTime,
      duration: unwrapped.duration,
      toccoLessonId: unwrapped.toccoLessonId,
      toccoClassId: unwrapped.toccoClassId,
      teamTeaching: unwrapped.teamTeaching,
      comment_TT: unwrapped.comment_TT,
      ttComment: unwrapped.ttComment,
    } as LectureLessonDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureLessonModel, can not map to LectureLessonDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
