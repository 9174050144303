import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetClassesApi from '../generated/api/WidgetClasses';

export default (service: Servicelayer) => ({
  ...WidgetClassesApi(service),

  // Add custom calls here

});
