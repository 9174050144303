// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LectureWorkloadSummaryStatusItemModel from '../../model/lecture-workload-summary-status-item-model';
import LectureWorkloadSummaryStatusItemDTO from '../dto/lecture-workload-summary-status-item-dto';
import dtoSchema from '../schemas/lecture-workload-summary-status-item-schema';

export default abstract class LectureWorkloadSummaryStatusItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LectureWorkloadSummaryStatusItemDTO>) {
    super();
    if (data) {
      this.name = data.name ?? undefined;
      this.totalDays = data.totalDays;
      this.totalDaysTT = data.totalDaysTT;
      this.statusId = data.statusId;
      this.htmlColor = data.htmlColor ?? undefined;
      this.isIncludedInWorkload = data.isIncludedInWorkload;
      this.isInOwnClass = data.isInOwnClass ?? undefined;
    }
  }
  /**
  */
  name?: string;
  /**
  * @type {double}
  */
  totalDays?: number;
  /**
  * @type {double}
  */
  totalDaysTT?: number;
  /**
  * @type {int64}
  */
  statusId?: number;
  /**
  */
  htmlColor?: string;
  /**
  */
  isIncludedInWorkload?: boolean;
  /**
  */
  isInOwnClass?: boolean;

  static toModel(dto: DeepPartial<LectureWorkloadSummaryStatusItemDTO>): LectureWorkloadSummaryStatusItemModel;
  static toModel(dto: DeepPartial<LectureWorkloadSummaryStatusItemDTO> | undefined | null): LectureWorkloadSummaryStatusItemModel | undefined;
  static toModel(dto: DeepPartial<LectureWorkloadSummaryStatusItemDTO> | undefined | null): LectureWorkloadSummaryStatusItemModel | undefined {
    return dto ? new LectureWorkloadSummaryStatusItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LectureWorkloadSummaryStatusItemModel> | LectureWorkloadSummaryStatusItemModel): LectureWorkloadSummaryStatusItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      name: unwrapped.name,
      totalDays: unwrapped.totalDays,
      totalDaysTT: unwrapped.totalDaysTT,
      statusId: unwrapped.statusId,
      htmlColor: unwrapped.htmlColor,
      isIncludedInWorkload: unwrapped.isIncludedInWorkload,
      isInOwnClass: unwrapped.isInOwnClass,
    } as LectureWorkloadSummaryStatusItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LectureWorkloadSummaryStatusItemModel, can not map to LectureWorkloadSummaryStatusItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
