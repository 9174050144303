// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AssignLessonsRequestModel from '../../model/assign-lessons-request-model';
import AssignLessonsRequestDTO from '../dto/assign-lessons-request-dto';
import dtoSchema from '../schemas/assign-lessons-request-schema';
import TTObjectModel from '../../model/tt-object-model';

export default abstract class AssignLessonsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AssignLessonsRequestDTO>) {
    super();
    if (data) {
      this.updateComment = data.updateComment;
      this.comment = data.comment ? TTObjectModel.toModel(data.comment) : undefined;
      this.updateAdminComment = data.updateAdminComment;
      this.adminComment = data.adminComment ?? undefined;
      this.selectedLessonIds = data.selectedLessonIds;
      this.agogisUserId = data.agogisUserId;
    }
  }
  /**
  */
  updateComment?: boolean;
  /**
  */
  comment?: TTObjectModel;
  /**
  */
  updateAdminComment?: boolean;
  /**
  */
  adminComment?: string;
  /**
  */
  selectedLessonIds?: number[];
  /**
  * @type {int64}
  */
  agogisUserId?: number;

  static toModel(dto: DeepPartial<AssignLessonsRequestDTO>): AssignLessonsRequestModel;
  static toModel(dto: DeepPartial<AssignLessonsRequestDTO> | undefined | null): AssignLessonsRequestModel | undefined;
  static toModel(dto: DeepPartial<AssignLessonsRequestDTO> | undefined | null): AssignLessonsRequestModel | undefined {
    return dto ? new AssignLessonsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AssignLessonsRequestModel> | AssignLessonsRequestModel): AssignLessonsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      updateComment: unwrapped.updateComment,
      comment: unwrapped.comment,
      updateAdminComment: unwrapped.updateAdminComment,
      adminComment: unwrapped.adminComment,
      selectedLessonIds: unwrapped.selectedLessonIds,
      agogisUserId: unwrapped.agogisUserId,
    } as AssignLessonsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AssignLessonsRequestModel, can not map to AssignLessonsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
