// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DynamicFormContactSalutationResourceViewModel from '../../../../viewModel/resource/DynamicFormContactSalutationResourceViewModel';
import DynamicFormContactResourceViewModel from '../../../../viewModel/resource/DynamicFormContactResourceViewModel';
import DynamicFormContactResourceDTO from '../../dto/resource/DynamicFormContactResourceDTO';

export default abstract class DynamicFormContactResourceModel extends BaseModel<DynamicFormContactResourceDTO> {
  /**
  * @type {int32}
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get companyName() { return this.dto.companyName; }
  set companyName(value) { this.dto.companyName = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get houseNumber() { return this.dto.houseNumber; }
  set houseNumber(value) { this.dto.houseNumber = value; }
  /**
  */
  get addressLine1() { return this.dto.addressLine1; }
  set addressLine1(value) { this.dto.addressLine1 = value; }
  /**
  */
  get poBox() { return this.dto.poBox; }
  set poBox(value) { this.dto.poBox = value; }
  /**
  * @type {int64}
  */
  get countryId() { return this.dto.countryId; }
  set countryId(value) { this.dto.countryId = value; }
  /**
  */
  get countryIso2() { return this.dto.countryIso2; }
  set countryIso2(value) { this.dto.countryIso2 = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get phoneNumber() { return this.dto.phoneNumber; }
  set phoneNumber(value) { this.dto.phoneNumber = value; }
  /**
  */
  get mobilePhoneNumber() { return this.dto.mobilePhoneNumber; }
  set mobilePhoneNumber(value) { this.dto.mobilePhoneNumber = value; }
  /**
  */
  get eMail() { return this.dto.eMail; }
  set eMail(value) { this.dto.eMail = value; }
  /**
  * @type {date-time}
  */
  get dateOfBirth() { return this.dto.dateOfBirth; }
  set dateOfBirth(value) { this.dto.dateOfBirth = value; }
  /**
  * @type {int32}
  */
  get contactFormType() { return this.dto.contactFormType; }
  set contactFormType(value) { this.dto.contactFormType = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.salutation = value;
    this.dto.companyName = qs.companyname;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.street = qs.street;
    this.dto.houseNumber = qs.housenumber;
    this.dto.addressLine1 = qs.addressline1;
    this.dto.poBox = qs.pobox;
    value = Number.parseFloat(qs.countryid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countryId = value;
    this.dto.countryIso2 = qs.countryiso2;
    this.dto.postalCode = qs.postalcode;
    this.dto.city = qs.city;
    this.dto.phoneNumber = qs.phonenumber;
    this.dto.mobilePhoneNumber = qs.mobilephonenumber;
    this.dto.eMail = qs.email;
    this.dto.dateOfBirth = qs.dateofbirth;
    value = Number.parseFloat(qs.contactformtype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contactFormType = value;
  }
}
