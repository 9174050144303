// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/cockpit-notification-schema',
  type: 'object',
  required: ['id', 'creatorId', 'recipientId'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    creatorId: {
      type: 'integer',
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    recipient: {
      type: 'string',
      nullable: true,
    },
    recipientId: {
      type: 'integer',
    },
    mailTemplateId: {
      type: 'string',
      nullable: true,
    },
    subject: {
      type: 'string',
      nullable: true,
    },
    state: {
      enum: [
        0,
        1,
        2,
        3,
        4,
      ],
    },
    information: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
