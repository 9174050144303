<template>
  <div class="agogis-lecturer-assignment-status-lookup">
    <p-select
      :value="value"
      :label="$t('page.modulePlanning.label.bookingStatus')"
      :items="items"
      :errors="innerErrors"
      v-bind="$props"
      :disabled="disabled"
      :required="required"
      :loading="isLoading"
      @input="$emit('input', $event)"
    ></p-select>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import GetLessonLecturerAssignmentStatusLookupRequestModel from '../../services/v2/model/get-lesson-lecturer-assignment-status-lookup-request-model';
  import Int64LookupModel from '../../services/v2/model/int64-lookup-model';

  export default Vue.extend({
    name: 'AgogisLecturerAssignmentStatusLookup',
    props: {
      value: { default: undefined, type: [String, Number] },
      disabled: { default: false, type: Boolean },
      clearable: { default: false, type: Boolean },
      required: { default: false, type: Boolean },
      errors: { type: String, default: undefined },
    },
    data: () => ({
      items: [] as Int64LookupModel[],
      innerErrors: undefined as any,
      isLoading: false,
    }),
    watch: {
      errors: {
        immediate: true,
        handler() {
          this.innerErrors = this.errors;
        },
      },
      value: {
        immediate: true,
        handler() {
          if (typeof this.value === 'string') {
            this.$emit('input', Number.parseInt(this.value, 10));
          }
        },
      },
    },
    async mounted() {
      this.$emit('input', this.value);
    },
    async beforeMount() {
      try {
        this.isLoading = true;
        const request = new GetLessonLecturerAssignmentStatusLookupRequestModel();
        const response = await this.$service.v2.api.lecturerLessonAssignments.getLessonLecturerAssignmentStatusLookup(request);
        this.items = response.items.map((x) => x.data);
        this.$emit('input', this.value);
      } catch (error: any) {
        this.innerErrors = error?.raw?.statusText ?? error?.message ?? error;
      } finally {
        this.isLoading = false;
      }
    },
  });
</script>
