// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int64LookupResourceViewModel from '../../../../viewModel/resource/Int64LookupResourceViewModel';
import BuildingListResourceViewModel from '../../../../viewModel/resource/BuildingListResourceViewModel';
import BuildingListResourceDTO from '../../dto/resource/BuildingListResourceDTO';

export default abstract class BuildingListResourceModel extends BaseModel<BuildingListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get details() { return this.dto.details; }
  set details(value) { this.dto.details = value; }
  /**
  */
  get group() { return this.getObjectModel(() => this.dto.group, Int64LookupResourceViewModel)!; }
  set group(value) { this.setObjectModel(() => this.dto.group, value); }
  /**
  * @type {int32}
  */
  get sortOrder() { return this.dto.sortOrder; }
  set sortOrder(value) { this.dto.sortOrder = value; }
  /**
  */
  get shortName() { return this.dto.shortName; }
  set shortName(value) { this.dto.shortName = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.sortorder);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sortOrder = value;
    this.dto.shortName = qs.shortname;
    this.dto.city = qs.city;
  }
}
