<template>
  <p-container>
    <p-row>
      <p-request-alert :dismissable="false" :value="page.errorMessage"></p-request-alert>
    </p-row>
    <p-row align-center>
      <p-button tertiary @click="back">
        <p-icon>keyboard_backspace</p-icon>
      </p-button>
      <h3 v-if="!$media.isMobile">
        {{ page.pageTitle }}
      </h3>
    </p-row>
    <p-row>
      <p-row>
        <p-col xs12 md4 lg4>
          <p-card>
            <h4 class="mb-5">
              Lektion
            </h4>
            <p class="mb-1">
              Datum: {{ lessonStartEndTimeString }}
            </p>
            <p class="mb-1">
              Bezeichnung: {{ page.lesson.lessonName }}
            </p>
            <p class="mb-1">
              Klasse: {{ page.lesson.lectureClassName }}
            </p>
            <p class="mb-1">
              Modul: {{ page.lesson.lectureModuleName }}
            </p>
          </p-card>
        </p-col>
        <p-col xs12 md8 lg8>
          <p-card>
            <h4 class="mb-3">
              Referenten
            </h4>
            <div class="p-table">
              <div v-if="page.lessonReferentsDataSource.isLoading" class="px-3">
                <p-progress-linear indeterminate></p-progress-linear>
              </div>
              <table v-if="page.lessonReferentsDataSource.data" class="p-table zebra dense">
                <th
                  v-for="column in page.lessonReferentsTableColumns"
                  :key="column.field"
                  class="caption-1 py-1"
                  :class="{['table-header-' + column.field]: true}"
                  style="text-align: left"
                >
                  {{ column.value }}
                </th>
                <tr v-for="row in page.lessonReferentsDataSource.data.items" :key="row.data.lecturerId">
                  <td v-for="column in page.lessonReferentsTableColumns" :key="column.field" :style="{'text-align': column.textAlign ? column.textAlign : 'left'} + ';height:10px;'">
                    {{ row.data[column.field] }}
                  </td>
                </tr>
              </table>
              <p-alert v-else type="info" value="Keine Referenten"></p-alert>
            </div>
          </p-card>
        </p-col>
      </p-row>
    </p-row>
    <p-row>
      <p-col xs12 md12 lg12>
        <p-card>
          <h4>
            Historie
          </h4>
          <div v-if="page.lessonHistoryDataSource.isLoading" class="px-3">
            <p-progress-linear indeterminate></p-progress-linear>
          </div>
          <div v-if="page.lessonHistoryDataSource.data" class="p-table">
            <!-- Filter -->
            <p-row class="filter">
              <p-col shrink>
                <p-button class="mt-5" @click="onRefreshDataSourcesClick">
                  <p-icon>refresh</p-icon>
                </p-button>
              </p-col>
              <p-col xs12 md4 lg2>
                <p-text-field
                  v-model="page.filter.searchCriteria"
                  clearable
                  :label="$t('core.app.search')"
                  context-icon="search"
                  @input="onFilterChangeClick"
                ></p-text-field>
              </p-col>
              <p-col xs12 md4 lg2>
                <p-select
                  v-if="page.lessonReferentsDataSource.data"
                  v-model="page.filter.lecturerId"
                  :label="$t('page.lesson.table.teacherName')"
                  context-icon="search"
                  clearable
                  :items="page.lessonReferentsDataSource.data.items"
                  item-value="lecturerId"
                  item-display="lecturerFullName"
                  @input="onFilterChangeClick"
                ></p-select>
              </p-col>
            </p-row>
            <p-row>
              <table class="p-table zebra dense">
                <th
                  v-for="column in page.lessonHistoryTableColumns"
                  :key="column.field"
                  class="caption-1 py-1"
                  :class="{['table-header-' + column.field]: true}"
                  style="text-align: left"
                >
                  {{ column.value }}
                </th>
                <tr v-for="row in page.lessonHistoryDataSource.data.items" :key="row.data.id">
                  <td v-for="column in page.lessonHistoryTableColumns" :key="column.field" :style="{'text-align': column.textAlign ? column.textAlign : 'left'} + ';height:10px;'">
                    {{ getFormattedDisplay(row.data, column) }}
                  </td>
                </tr>
              </table>
              <p-pagination v-model="page.lessonHistoryDataSource.paging"></p-pagination>
            </p-row>
          </div>
          <p-alert v-else type="info" value="Keine History">
            Keine History
          </p-alert>
        </p-card>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import LessonDetailPage from './lesson-detail.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      isLoading: false,
      page: new LessonDetailPage(),
      errorMessage: undefined as string | undefined,
      successMessage: undefined as string | undefined,
    }),
    computed: {
      lessonStartEndTimeString() {
        return `${this.$format.localDateAndTime(this.page.lesson.startTime!)}-${this.$format.localTime(this.page.lesson.endTime!)} (${this.page.lesson.duration}d)`;
      },
    },
    async mounted() {
      this.page.initialize();
    },
    methods: {
      onFilterChangeClick() {
        this.page.onFilterChange();
      },
      onRefreshDataSourcesClick() {
        this.page.lessonHistoryDataSource.select();
      },
      getFormattedDisplay(row: any, column: any) {
        const value = row[column.field];
        if (typeof column.format === 'function') {
          return column.format(value);
        }
        return value;
      },
      back() {
        this.$router.back();
      },
    },
  });
</script>
