// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int64LookupResourceViewModel from '../../../../viewModel/resource/Int64LookupResourceViewModel';
import PublicHolidayListResourceViewModel from '../../../../viewModel/resource/PublicHolidayListResourceViewModel';
import PublicHolidayListResourceDTO from '../../dto/resource/PublicHolidayListResourceDTO';

export default abstract class PublicHolidayListResourceModel extends BaseModel<PublicHolidayListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get details() { return this.dto.details; }
  set details(value) { this.dto.details = value; }
  /**
  */
  get group() { return this.getObjectModel(() => this.dto.group, Int64LookupResourceViewModel)!; }
  set group(value) { this.setObjectModel(() => this.dto.group, value); }
  /**
  * @type {int32}
  */
  get sortOrder() { return this.dto.sortOrder; }
  set sortOrder(value) { this.dto.sortOrder = value; }
  /**
  */
  get isAllDay() { return this.dto.isAllDay; }
  set isAllDay(value) { this.dto.isAllDay = value; }
  /**
  * @type {date-time}
  */
  get startTime() { return this.dto.startTime; }
  set startTime(value) { this.dto.startTime = value; }
  /**
  * @type {date-time}
  */
  get endTime() { return this.dto.endTime; }
  set endTime(value) { this.dto.endTime = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.sortorder);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sortOrder = value;
    value = qs.isAllDay === 'true' ? true : qs.isallday;
    value = qs.isAllDay === 'false' ? false : undefined;
    this.dto.isAllDay = value;
    this.dto.startTime = qs.starttime;
    this.dto.endTime = qs.endtime;
  }
}
