import Vue from 'vue';
import WidgetClassListResourceModel from '../../generated/types/model/resource/WidgetClassListResourceModel';

export default class WidgetClassListResourceViewModel extends WidgetClassListResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  public get priceString():string {
    if (this.price === undefined) { return 'kostenlos'; }
    return `CHF ${this.price}.-`;
  }
  public get timeString():string | undefined {
    if (this.occurences.length > 0) {
      return this.occurences[0].timeString;
    }
    return '';
  }
  public get availabilityString():string {
    switch (this.registrationStatus) {
      case 'bookable':
        if (this.registrationIndicator === 'orange') {
          return 'Wenige Plätze verfügbar';
        }
        return 'Freie Plätze verfügbar';
      case 'waitinglist':
        return 'Warteliste';
      case 'bookedUp':
        return 'Ausgebucht';
      default:
        return 'Wenige Plätze verfügbar';
    }
  }
  public get registrationUrl():string {
    if (this.registrationFormPDFUrl) return this.registrationFormPDFUrl;
    return `/angebote/anmeldung?id=${this.id}`;
  }
  public get isBookingActionAvailable():boolean {
    return this.registrationStatus !== 'bookedUp';
  }
}
