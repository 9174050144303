<template>
  <!-- Begin Agogis.Widget.courseInfoEvents -->
  <div class="infoveranstaltungen liste">
    <div v-for="e in events" :key="e.id" class="item" :data-id="e.id">
      <div class="datum_wrapper">
        <div class="datum">
          {{ $format.localDate(e.startDate) }}
        </div>
        <div class="zeit">
          {{ e.timeString }}
        </div>
      </div>
      <div class="text_wrapper">
        <div class="ort">
          {{ e.city }}
        </div>
        <div class="anmeldung">
          <a v-if="e.isBookingActionAvailable" :href="e.registrationUrl" target="_blank">anmelden</a>
        </div>
      </div>
    </div>

    <p><a href="../termine">alle Termine</a></p>

    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-language: {{ $config.values['language'] }}
    </div>
  </div>
  <!-- End Agogis.Widget.courseInfoEvents -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
  import WidgetClassListResourceViewModel from '../../../services/v1/viewModel/resource/WidgetClassListResourceViewModel';
  import WidgetSearchClassRequestViewModel from '../../../services/v1/viewModel/resource/WidgetSearchClassRequestViewModel';
  import ApiPagingRequestViewModel from '../../../services/v1/viewModel/resource/ApiPagingRequestViewModel';

  export default Vue.extend({
    layout: 'layout-widget',
    data: () => ({
      events: [] as WidgetClassListResourceViewModel[],

    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
    },
    async mounted() {
      try {
        const widgetSearchClassRequestVM = new WidgetSearchClassRequestViewModel();
        widgetSearchClassRequestVM.toccoFilter = this.toccoFilter;
        const page = new ApiPagingRequestViewModel();
        page.pageSize = this.pageSize;
        widgetSearchClassRequestVM.paging = page;
        const response = await this.$service.api.widgetClasses.getWidgetClasses(widgetSearchClassRequestVM);
        this.events = response.items;
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
          // this.events = errorResponse.data;
        }
        this.$log.error(e);
      }
    },
  });
</script>
